import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom'
import Helper from './../../config/helper.js'
import PerfectScrollbar from 'react-perfect-scrollbar';
import Paginacao from '../../components/paginacao/Paginacao.jsx';
import { getList, getStructList, deleteObj, changeOrder, getBusca } from './../../store/api.js'

import Lottie from "lottie-react"
import lottiejson from "../../assets/error-grub.json"

class Lista extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			filter: null,
			tabs:null,
			lista:null,
			struct:null,
			model: null,
			modalAlert:false,
			OBJID:null
		}

		this.handlerTabs = this.handlerTabs.bind(this)
		this.handlerPagination = this.handlerPagination.bind(this)
		this.handlerActions = this.handlerActions.bind(this)
		this.fetchData = this.fetchData.bind(this)
		this.paginacao = this.paginacao.bind(this)
		this.deleteItem = this.deleteItem.bind(this)
		this.handleBusca = this.handleBusca.bind(this)
	}

	tableHead(){
		const row = this.state.struct
		
		
		if (row) {
			return (
				<tr>
					{row.map( (item) => <td>{item.label}</td> )}
				</tr>
			)
		} else {
			return null
		}
		
	}

	columns(row, i){
		const items = this.state.struct

		if (items) {
			return (
				<tr key={"key_"+i}>
					{	items.map( (item) => Helper.listContent(row[item.nome], item, row.id, this.state.model, this.handlerActions, row) ) }
				</tr>
			)
		} else {
			return null
		}
	}

	fetchData() {
		if (this.state.model !== this.props.model) {
			if (this.props.model === 'Categoria') {
				getList('Categoria').then(result => {
					this.setState({ tabs: result.data.filter(item => {return item.meta.categorias_count > 0}), filter: null })
				})
			} else if (this.props.model === 'Produto') {
				getList('Categoria').then(result => {
					this.setState({ tabs: result.data, filter: null })
				})
			} else {
				this.setState({ tabs: [], filter: null })
			}

			getStructList(this.props.model).then(result => {
				this.setState({ struct: result })
			})

			getList(this.props.model).then(result => {
				this.setState({ lista: result })
			})
			this.setState({model: this.props.model})
		}
	}

	handlerTabs(e){
		getList(this.state.model, {filtro:e.target.id}).then(result => {
			this.setState({ lista: result })
		})
	}

	handleBusca(e){
		getBusca(this.state.model, {filtro:e.target.value}).then(result => {
			this.setState({ lista: result })
		})
	}

	handlerPagination(e){
		getList(this.state.model, {page:e.target.dataset.page}).then(result =>
			this.setState({ lista: result })
		)
	}

	async handlerActions(e){
		let action = e.currentTarget.dataset.action
		let ref = e.currentTarget.dataset.ref

		if (action === 'editar') {
			this.props.history.push("/cardapio/edit/"+ref)
		} else if (action === 'order') {
			let direcao = e.currentTarget.dataset.direcao
			await changeOrder(ref, direcao)
			getList(this.props.model).then(result => {
				this.setState({ lista: result })
			})
		} else if (action === 'excluir') {
			this.setState({ modalAlert: true, OBJID: ref })
		}
	}

	async deleteItem(){
		await deleteObj(this.state.OBJID)
		this.setState({ modalAlert: false, OBJID: null })

		getList(this.props.model).then(result => {
			this.setState({ lista: result })
		})
	}

	componentDidMount() {
		this.fetchData()
	}

	componentDidUpdate(){
		this.fetchData()
	}

	paginacao(){
		return <Paginacao 
					model={this.state.model} 
					lista={this.state.lista}
					hadlerPagination={this.handlerPagination} />
	}

	filter(event) {
		event.preventDefault()

		this.handlerTabs(event)
		this.setState({ filter: event.target.id })
	}

	busca(event) {
		event.preventDefault()

		this.handleBusca(event)
	}

	render() {
		return (
			<>
				<div className="tab-overflow" style={{width: "90%"}}>
					<PerfectScrollbar>
						<ul className="nav nav-tabs" role="tablist">
							<li key="principal" className="nav-item" role="presentation">
								<a href="#default-tab-1" data-bs-toggle="tab" className={"nav-link " + (!this.state.filter ? "active" : "")} onClick={(event) => this.filter(event, null)}>
									TODOS
								</a>
							</li>
							{this.state.tabs && this.state.tabs.length > 0 && (
								this.state.tabs.map((e,i) => 
									<li key={"item_"+i} className="nav-item" role="presentation">
										<a href={"#item_"+i } onClick={(event) => this.filter(event)} id={e.id} data-bs-toggle={"tab_"+e.id} className={e.id == this.state.filter ? "nav-link active" : "nav-link"}>
											{e.nome}
										</a>
									</li>
								)
							)}
						</ul>
					</PerfectScrollbar>
				</div>
				
				<div className="tab-content panel p-5 rounded-0 rounded-bottom">
					
					<div className="tab-pane fade active show" id="default-tab-1">
						{this.state.lista && this.state.lista.data && this.state.lista.data.length > 0 ? (
							<>
								<table className="table table-striped mb-0 align-middle">
									<thead>
										{this.tableHead()}
									</thead>
									<tbody>
										{this.state.lista.data.map((item, i) =>
											this.columns(item, i)
										)}
									</tbody>
								</table>
								{this.props.paginacao && (this.paginacao())}
							</>
						) : (
							<strong>
								<Lottie animationData={lottiejson} loop={true} style={{width: "500px", margin:"auto"}} />
								<h1 style={{textAlign:"center"}}>Não tem nenhum item cadastrado por aqui. </h1>
								<p  style={{textAlign:"center"}}>
									Cadastrar um(a) novo(a)&nbsp;
									<Link to={"/cardapio/edit/"+this.props.model} className="alert-link">
										 {this.props.model}
									</Link>
									&nbsp;agora.
								</p>
							</strong>
						)}
					</div>
				</div>

				<Modal isOpen={this.state.modalAlert} toggle={() => this.setState({ modalAlert: false })}>
					<ModalBody>
						<div className='text-center'>
							<div className="alert mb-15">
								<h3>Tem certeza que deseja eliminar este item?</h3>
								<p>Esta ação é irreversível e não será possível desfazê-la.</p>
							</div>
							
							<button className="btn btn-white" style={{marginRight: '15px'}} onClick={() => this.setState({ modalAlert: false })}>Cancelar</button>
							<button className="btn btn-danger" onClick={() => this.deleteItem()}>Sim, desejo eliminar este item</button>
						</div>
					</ModalBody>
				</Modal>
			</>
		)
	}
}

export default Lista
