import React from 'react'
import { Link } from 'react-router-dom'

import { Modal, ModalBody } from 'reactstrap'

import { getList, deleteObj, saveObj } from '../../store/api.js'

import Paginacao from '../../components/paginacao/Paginacao.jsx'
import Helper    from '../../config/helper'

import Lottie from "lottie-react"
import lottiejson from "../../assets/error-grub.json"
import lottieAlerta from "../../assets/cuidado-alerta.json"

class ListaVoucher extends React.Component {

	constructor(props) {
		super(props)

		const user = JSON.parse(localStorage.getItem('user')).data
		this.state = {
			brand: user.brand ? user.brand : "",
			loja: user.loja,
			model:null,
			lista:[],
			modalAlert: false,
			OBJID: null
		}

		this.fetchData = this.fetchData.bind(this)
		this.handlerPagination = this.handlerPagination.bind(this)
		this.deleteItem = this.deleteItem.bind(this)
	}

	fetchData(){
		if (this.state.model !== this.props.model) {
			getList(this.props.model).then(result => {
				this.setState({ lista: result })
			})

			this.setState({model: this.props.model})
		}
	}

	componentDidMount() {
		this.fetchData()
	}

	handlerPagination(e){
		getList(this.state.model, {page:e.target.dataset.page}).then(result =>
			this.setState({ lista: result })
		)
	}

	async deleteItem(e){
		e.preventDefault()

		await deleteObj('Voucher/'+this.state.OBJID)
		this.setState({ modalAlert: false, OBJID: null })
		getList(this.props.model).then(result => {
			this.setState({ lista: result })
		})
	}

	async handleChangeProduct(data){
		try {
			await saveObj({items:data}, this.state.model)
			Helper.addNotification('success', 'Tudo certo por aqui :)', 'Mudanças feitas no produto salvas com sucesso.', 'top-right')

			getList(this.state.model).then(result => {
				this.setState({ lista: result })
			})

		  } catch (e) {
			Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'Não foi possivel salvar as informações enviadas.', 'top-right')
		  }
	}

	showDate(valor){
		const date = new Date(valor)

		return date.getDate() + "/" + ( date.getMonth() + 1 ) + "/" + date.getFullYear()
	}

	paginacao(){
		return <Paginacao 
					model={this.state.model} 
					lista={this.state.lista}
					hadlerPagination={this.handlerPagination} />
	}

	render() {
		const lista = this.state.lista
		return (
			<div>
				{lista && lista.data && lista.data.length > 0 ? (
					<div className="card border-0">
						
						<div className="tab-content p-3">
							
							<div className="tab-pane fade show active" id="allTab">
								
								<div className="table-responsive mb-3">
									<table className="table table-hover table-panel text-nowrap align-middle mb-0">
										<thead>
											<tr>
												<th>Nome do voucher</th>
												<th>Código</th>
												<th>Tipo</th>
												<th>Desconto</th>
												<th>Quantidade</th>
												<th>Vigência</th>
												<th>Usos</th>
                                                <th>Status</th>
												<th>Ação</th>
											</tr>
										</thead>
										<tbody>
											{lista.data.map((item) => 
												<tr key={"item_"+item.id}>
													<td> 
														<Link to={"/cardapio/edit/Voucher/"+item.id} style={{ textDecoration: 'none' }} >
															<i className="fa-solid fa-tag" style={{background: "#0043ff", color: "white", padding: "10px", borderRadius: "7px"}}></i> 
															<strong style={{color: "#0043ff", textTransform: "uppercase", marginLeft: "10px"}}>{item.nome}</strong>
														</Link>
													</td>
													<td> <strong style={{color: "green"}}>{item.codigo}</strong>  </td>
													<td>{item.tipo && item.tipo === "percent" ? "%" : "Absoluto"}</td>
													<td>{item.tipo && item.tipo === "percent" ? item.valor + " %" : item.valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
													<td>{item.limite} </td>
													<td>{item.expira ? this.showDate(item.expira) : "-"}</td>
													<td>{item.comandas ? item.comandas.length : 0}</td>
													<td> 
														<div className="form-check form-switch col-6">
															<input className="form-check-input" name="b_ativo" type="checkbox" checked={item.b_ativo} onChange={() => {this.handleChangeProduct({b_ativo: !item['b_ativo'], id: item.id})}}  id="vaoucher_ativo" />
															<label className="form-check-label" htmlFor="vaoucher_ativo">Habilitado</label>
														</div>
													</td>
													<td>
														<button className="btn btn-white" data-voucher={item.id} onClick={(e) => {e.preventDefault(); this.setState({ modalAlert: true, OBJID: e.target.dataset.voucher })}}>
															<i className="fa fa-trash fs-9px fa-fw me-5px"></i> Excluir
														</button>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>

								{this.props.paginacao && (this.paginacao())}
							</div>
						</div>
					</div>
				)
				:
				( 
					<div className="card border-0" style={{padding:"15px"}}>

						<Lottie animationData={lottiejson} loop={true} style={{width: "500px", margin:"auto"}} />
						<h1 style={{textAlign:"center"}}>Nenhum Voucher cadastrado por aqui! Cadastre um agora. </h1>
						<p  style={{textAlign:"center"}}></p>
					</div>
				)
				}

				<Modal isOpen={this.state.modalAlert} toggle={() => this.setState({ modalAlert: false })}>
					<ModalBody>
						<div className='text-center'>
							<Lottie animationData={lottieAlerta} loop={true} style={{width: "250px", margin:"auto"}} />
							<div className="alert mb-15">
								<h3>Atenção: esta ação é crítica e irreversível.</h3>
								<p>O voucher será excluido.</p>
							</div>
							
							<button className="btn btn-white" style={{marginRight: '15px'}} onClick={() => this.setState({ modalAlert: false })}>Cancelar</button>
							<button className="btn btn-danger" onClick={(e) => this.deleteItem(e)}>Sim, desejo eliminar este item</button>
						</div>
					</ModalBody>
				</Modal>	
			</div>
		)
	}
}

export default ListaVoucher;