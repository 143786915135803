import React from 'react'

import Select, { components } from 'react-select'

export default class DropDown extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            valor: null
        }

        this.handleChangeData = this.handleChangeData.bind(this)
        this.iconOptions = this.iconOptions.bind(this)
    }

    handleChangeData (selectedOption) {
        this.setState({valor: selectedOption})
        const valor = selectedOption.id ?  selectedOption.id: selectedOption.label
        this.props.setFomrData(this.props.nome, valor)
    }

    iconOptions(props){
		const { Option } = components

		return (
			<Option {...props}>
                {this.props.nome === "icon" && (
                    <img
                        src={'https://grubicons.s3.us-east-1.amazonaws.com/' + props.data.label}
                        style={{ width: 15, marginRight: 10 }}
                        alt={props.data.label}
                    />
                )}
				{props.data.label}
			</Option>
		)
	}

    render() {
        const getInitial = this.props.dadosIniciais && this.props.options.length > 0? this.props.options.filter(e => e.id === this.props.dadosIniciais || e.label === this.props.dadosIniciais) : null
        let atual = getInitial ? getInitial[0] : null
        const list = []

        if (this.props.options) {
            this.props.options.forEach((item) => {
                item.value = item.id ? item.id : item.label
                list.push(item)
            })
        }

        if (atual) atual.value = atual.id ? atual.id : atual.label

        return (
            <div>
                <Select
                    options={list} 
                    components={{ Option: this.iconOptions }}
                    className="form-control fs-15px"
                    defaultValue={atual}
                    value={this.state.valor ? this.state.valor : atual} 
                    placeholder={this.props.label}
                    onChange={this.handleChangeData}
                    styles={{
                        control: (baseStyles, state) => ({
                        ...baseStyles,
                        border: 'none',
                        boxShadow: 'none'
                        }),
                    }}
                />
            </div>
        )
    }
}