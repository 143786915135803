import React from 'react'

import { saveObj, getItem } from '../../store/api.js'

import InputText    from '../_atoms/InputText'
import InputTextMask    from '../_atoms/InputTextMask'
import Label        from '../_atoms/Label'
import Helper       from '../../config/helper'

export default class FormMotoboy extends React.Component {
    constructor(props) {
        super(props)

        const user = JSON.parse(localStorage.getItem('user')).data
        this.state = {
            model:this.props.model,
            brand:user ? user.brand : "",
            id:this.props.id,
            data:{
              items:{},
              relations:{}
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this)
        this.setModelData = this.setModelData.bind(this)
    }

    async componentDidMount() {
        const item = await getItem(this.state.model, this.state.id)

        if (item.data && item.data != '') {
            this.setState({ data: {items: item.data, relations: {Variacao: item.data.variacao} }})
        }
    }

    async handleSubmit(event) {
        event.preventDefault()
    
        try {
          await saveObj(this.state.data, this.state.model)
          Helper.addNotification('success', 'Sucesso', 'Registro excluido com sucesso', 'top-right')
          window.history.back()
        } catch (e) {
          Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'Não foi possivel salvar as informações enviadas.', 'top-right')
        }
    }

    setModelData = (name, value) => {
        if (value !== undefined) {

            let data = this.state.data
            data.items[name] = value

            if (name === "telefone") data.items["senha"] = value.replaceAll("(","").replaceAll(")","").replaceAll("-","")

            this.setState({ data })
        }
    }
    

    render() {
        const atual = this.state.data.items
        return (
            <form onSubmit={this.handleSubmit}>
                <div className='row'>
                    <div className="col-md-6">
                    
                        <div className="form-floating mb-15px">
                            <InputText
                                nome="nome"
                                setFomrData={this.setModelData}
                                dadosIniciais={atual ? atual.nome : ""}
                            />
                            <Label nome="nome" label="Nome"/>
                        </div>

                        <div className="form-floating mb-15px">
                            <InputTextMask
                                nome="telefone"
                                mask={"(99)99999-9999"}
                                setFomrData={this.setModelData}
                                dadosIniciais={atual ? atual.telefone : ""}
                            />
                            <Label nome="telefone" label="Telefone"/>
                        </div>
                        
                    </div>
                    <div className="col-md-6">
                    
                        <div className="form-floating mb-15px">
                            <InputText
                                nome="email"
                                setFomrData={this.setModelData}
                                dadosIniciais={atual ? atual.email : ""}
                            />
                            <Label nome="email" label="Email"/>
                        </div>

                        <div className="form-floating mb-15px">
                            <InputText
                                nome="senha"
                                dadosIniciais={atual ? atual.senha : ""}
                                readOnly={true}
                            />
                            <Label nome="senha" label="Senha"/>
                        </div>
                        
                    </div>
                </div>

                <div className='row'>
                    <div className="col-md-2 offset-md-10">
                        <button type="submit" style={{float: 'right'}} className="btn btn-success btn-block btn-lg">Salvar</button>
                    </div>
                </div>
            </form>
        )
    }
}