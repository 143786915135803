import React from 'react'

import { saveObj, getItem } from '../../store/api.js'

import InputText    from '../_atoms/InputText'
import InputTextMask    from '../_atoms/InputTextMask'
import Label        from '../_atoms/Label'
import DropDown        from '../_atoms/DropDown.jsx'
import Helper       from '../../config/helper'

export default class FormVoucher extends React.Component {
    constructor(props) {
        super(props)

        const user = JSON.parse(localStorage.getItem('user')).data
        this.state = {
            model:this.props.model,
            brand:user ? user.brand : "",
            id:this.props.id,
            data:{
              items:{},
              relations:{}
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this)
        this.setModelData = this.setModelData.bind(this)
        this.setAtivo = this.setAtivo.bind(this)
    }

    async componentDidMount() {
        const item = await getItem(this.state.model, this.state.id)

        if (item.data && item.data != '') {
            this.setState({ data: {items: item.data, relations: {Variacao: item.data.variacao} }})
        }
    }

    async handleSubmit(event) {
        event.preventDefault()
    
        try {
          const data = this.state.data

          if (data.items.valor && data.items.codigo) {

            //ajusta formato de hora
            if (data.items.expira) {
                const date = data.items.expira.split("/")
                data.items.expira = new Date(date[1]+"-"+date[0]+"-"+date[2])
            }

            await saveObj(this.state.data, this.state.model)
            Helper.addNotification('success', 'Sucesso', 'Registro salvo com sucesso', 'top-right')
            window.history.back()   
          } else {
            Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'codigo ou valor não preenchidos.', 'top-right')
          }
        } catch (e) {
            console.log(e)
            Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'Não foi possivel salvar as informações enviadas.', 'top-right')
        }
    }

    setModelData = (name, value) => {
        if (value !== undefined) {

            let data = this.state.data
            data.items[name] = value

            this.setState({ data })
        }
    }

    setAtivo(e){
        let data = this.state.data
        data.items['b_ativo'] = e.target.checked

        this.setState({ data })
    }
    

    render() {
        const atual = this.state.data.items
        return (
            <form onSubmit={this.handleSubmit}>
                <div className='row'>
                    <div className="col-md-6">
                    
                        <div className="form-floating mb-15px">
                            <InputText
                                nome="nome"
                                setFomrData={this.setModelData}
                                dadosIniciais={atual ? atual.nome : ""}
                            />
                            <Label nome="nome" label="Nome"/>
                        </div>

                        <div className="form-floating mb-15px">
                            <InputText
                                nome="codigo"
                                setFomrData={this.setModelData}
                                dadosIniciais={atual ? atual.codigo : ""}
                            />
                            <Label nome="codigo" label="Código"/>
                        </div>
                        
                        <div className="form-floating mb-15px">
                            <DropDown 
                                nome="tipo"
                                setFomrData={this.setModelData}
                                options={[
                                    {
                                        "id": "percent",
                                        "label": "Percentual"
                                    },
                                    {
                                        "id": "integral",
                                        "label": "Valor Inteiro"
                                    }]}
                                dadosIniciais={atual ? atual.tipo : false}
                                label="Selecione um tipo de desconto"
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-floating mb-15px">
                            <InputText
                                nome="valor"
                                setFomrData={this.setModelData}
                                dadosIniciais={atual ? atual.valor : ""}
                            />
                            <Label nome="valor" label="Valor"/>
                        </div>

                        <div className="form-floating mb-15px">
                            <InputText
                                nome="limite"
                                setFomrData={this.setModelData}
                                dadosIniciais={atual ? atual.limite : ""}
                            />
                            <Label nome="limite" label="Quantidade"/>
                        </div>

                        <div className="form-floating mb-15px">
                            <InputTextMask
                                nome="expira"
                                setFomrData={this.setModelData}
                                mask={"99/99/9999"}
                                dadosIniciais={atual ? atual.expira : ""}
                            />
                            <Label nome="expira" label="Vigência"/>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className="col-md-2 offset-md-10">
                        <button type="submit" style={{float: 'right'}} className="btn btn-success btn-block btn-lg">Salvar</button>
                    </div>
                </div>
            </form>
        )
    }
}