import React from 'react';
import { AppSettings } from './config/app-settings.js';

import Header from './components/header/header.jsx';
import Sidebar from './components/sidebar/sidebar.jsx';
import Content from './components/content/content.jsx';
import ReactNotifications from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import {getList, me} from './store/api.js'
import * as qz from 'qz-tray'
import {stob64, hextorstr, KEYUTIL, KJUR} from "jsrsasign"

class App extends React.Component {
	constructor(props) {
		super(props);
		
		this.handleSetAppContentClass = (value) => {
			this.setState(state => ({
				appContentClass: value
			}));
		}
		
		this.handleSetAppHeaderNone = (value) => {
			this.setState(state => ({
				appHeaderNone: value
			}));
		}

		this.handleSetAppHeaderFixed = (value) => {
			if (value === false && this.state.appSidebarFixed) {
				alert('Default Header with Fixed Sidebar option is not supported. Proceed with Default Header with Default Sidebar.');
				this.setState(state => ({
					appSidebarFixed: false
				}));
			}
			this.setState(state => ({
				appHeaderFixed: value
			}));
		}

		this.handleSetAppSidebarNone = (value) => {
			this.setState(state => ({
				appSidebarNone: value
			}));
		}
	
		this.handleSetFont = () => {
			this.setState(state => ({
				font: {
					family: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-family').trim(),
					size: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-size').trim(),
					weight: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-family').trim()
				}
			}));
		}

		this.handleUserData = (valor) => {
			this.setState(state => ({
				stateUserdata: valor
			}))
		}

		this.handleImpressoras = (valor) => {
			this.setState(state => ({
				stateImpressoras: valor
			}))
		}

		this.handleFilas = (valor) => {
			this.setState(state => ({
				stateFilas: valor
			}))
		}

		this.handleSetAppContentFullHeight = (value) => {
			this.setState(state => ({
				appContentFullHeight: value
			}));
		}

		var appSidebarFloatSubMenuRemove;
		var appSidebarFloatSubMenuCalculate;
		var appSidebarFloatSubMenuRemoveTime = 250;
		this.handleAppSidebarFloatSubMenuOnMouseOver = (e) => {
			clearTimeout(appSidebarFloatSubMenuRemove);
			clearTimeout(appSidebarFloatSubMenuCalculate);
		}
		this.handleAppSidebarFloatSubMenuOnMouseOut = (e) => {
			appSidebarFloatSubMenuRemove = setTimeout(() => {
				this.setState(state => ({
					appSidebarFloatSubMenuActive: false
				}));
			}, appSidebarFloatSubMenuRemoveTime);
		}
		this.handleAppSidebarOnMouseOver = (e, menu) => {
			if (this.state.appSidebarMinify) {
				if (menu.children) {
					var left = (document.getElementById('sidebar').offsetWidth + document.getElementById('sidebar').offsetLeft) + 'px';
					
					clearTimeout(appSidebarFloatSubMenuRemove);
					clearTimeout(appSidebarFloatSubMenuCalculate);
			
					this.setState(state => ({
						appSidebarFloatSubMenu: menu,
						appSidebarFloatSubMenuActive: true,
						appSidebarFloatSubMenuLeft: left
					}));
					
					var offset = e.currentTarget.offsetParent.getBoundingClientRect();
					
					appSidebarFloatSubMenuCalculate = setTimeout(() => {
						var targetTop = offset.top;
						var windowHeight = window.innerHeight;
						var targetHeight = document.querySelector('.app-sidebar-float-submenu-container').offsetHeight;
						var top, bottom, arrowTop, arrowBottom, lineTop, lineBottom;
						
						if ((windowHeight - targetTop) > targetHeight) {
							top = offset.top + 'px';
							bottom = 'auto';
							arrowTop = '20px';
							arrowBottom = 'auto';
							lineTop = '20px';
							lineBottom = 'auto';
						} else {
							var aBottom = (windowHeight - targetTop) - 21;
							top = 'auto';
							bottom = '0';
							arrowTop = 'auto';
							arrowBottom = aBottom + 'px';
							lineTop = '20px';
							lineBottom = aBottom + 'px';
						}
						
						this.setState(state => ({
							appSidebarFloatSubMenuTop: top,
							appSidebarFloatSubMenuBottom: bottom,
							appSidebarFloatSubMenuLineTop: lineTop,
							appSidebarFloatSubMenuLineBottom: lineBottom,
							appSidebarFloatSubMenuArrowTop: arrowTop,
							appSidebarFloatSubMenuArrowBottom: arrowBottom,
							appSidebarFloatSubMenuOffset: offset
						}));
					}, 0);
					
				} else {
					appSidebarFloatSubMenuRemove = setTimeout(() => {
						this.setState(state => ({
							appSidebarFloatSubMenu: '',
							appSidebarFloatSubMenuActive: false
						}));
					}, appSidebarFloatSubMenuRemoveTime);
				}
			}
		}
		this.handleAppSidebarOnMouseOut = (e) => {
			if (this.state.appSidebarMinify) {
				appSidebarFloatSubMenuRemove = setTimeout(() => {
					this.setState(state => ({
						appSidebarFloatSubMenuActive: false
					}));
				}, appSidebarFloatSubMenuRemoveTime);
			}
		}
		this.handleAppSidebarFloatSubMenuClick = () => {
			if (this.state.appSidebarMinify) {
				const windowHeight = window.innerHeight;
				const targetHeight = document.getElementById('app-sidebar-float-submenu').offsetHeight;
				const targetTop = this.state.appSidebarFloatSubMenuOffset.top;
				const top = ((windowHeight - targetTop) > targetHeight) ? targetTop : 'auto';
				const left = (this.state.appSidebarFloatSubMenuOffset.left + document.getElementById('sidebar').offsetWidth) + 'px';
				const bottom = ((windowHeight - targetTop) > targetHeight) ? 'auto' : '0';
				const arrowTop = ((windowHeight - targetTop) > targetHeight) ? '20px' : 'auto';
				const arrowBottom = ((windowHeight - targetTop) > targetHeight) ? 'auto' : ((windowHeight - targetTop) - 21) + 'px';
				const lineTop = ((windowHeight - targetTop) > targetHeight) ? '20px' : 'auto';
				const lineBottom = ((windowHeight - targetTop) > targetHeight) ? 'auto' : ((windowHeight - targetTop) - 21) + 'px';
			
				this.setState(state => ({
					appSidebarFloatSubMenuTop: top,
					appSidebarFloatSubMenuLeft: left,
					appSidebarFloatSubMenuBottom: bottom,
					appSidebarFloatSubMenuLineTop: lineTop,
					appSidebarFloatSubMenuLineBottom: lineBottom,
					appSidebarFloatSubMenuArrowTop: arrowTop,
					appSidebarFloatSubMenuArrowBottom: arrowBottom
				}));
			}
		}
		
		this.state = {
			
			appSidebarNone: false,
			appHeaderNone: false,
			appHeaderFixed: true,
			hasScroll: false,
			appSidebarFixed: true,
			appContentClass: '',

			stateImpressoras: [],
			stateFilas: [],
			stateUserdata: null,

			appSidebarFloatSubMenuActive: false,
			appSidebarFloatSubMenu: '',
			appSidebarFloatSubMenuTop: 'auto',
			appSidebarFloatSubMenuLeft: 'auto',
			appSidebarFloatSubMenuBottom: 'auto',
			appSidebarFloatSubMenuLineTop: 'auto',
			appSidebarFloatSubMenuLineBottom: 'auto',
			appSidebarFloatSubMenuArrowTop: 'auto',
			appSidebarFloatSubMenuArrowBottom: 'auto',
			appSidebarFloatSubMenuOffset: '',
			appContentFullHeight: false,

			handleSetAppHeaderNone: this.handleSetAppHeaderNone,
			handleSetAppHeaderFixed: this.handleSetAppHeaderFixed,
			handleSetAppContentClass: this.handleSetAppContentClass,
			handleSetAppSidebarNone: this.handleSetAppSidebarNone,
			handleAppSidebarOnMouseOut: this.handleAppSidebarOnMouseOut,
			handleAppSidebarOnMouseOver: this.handleAppSidebarOnMouseOver,
			handleAppSidebarFloatSubMenuOnMouseOver: this.handleAppSidebarFloatSubMenuOnMouseOver,
			handleAppSidebarFloatSubMenuOnMouseOut: this.handleAppSidebarFloatSubMenuOnMouseOut,
			handleAppSidebarFloatSubMenuClick: this.handleAppSidebarFloatSubMenuClick,
			handleSetAppContentFullHeight: this.handleSetAppContentFullHeight,
			handleImpressoras: this.handleImpressoras,
			handleUserData: this.handleUserData,
			handleFilas: this.handleFilas,
			atualizaImpressoras: this.atualizaImpressoras,
			configQz:this.configQz,
			atualizaFilas: this.atualizaFilas,
			saveUserData: this.saveUserData,
			
			font: {
				family: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-family').trim(),
				size: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-size').trim(),
				weight: window.getComputedStyle(document.body).getPropertyValue('--bs-body-font-family').trim()
			},
			
		};
	}

	async saveUserData(){
		const request = await me()
		this.handleUserData(request.data)
	}

	async configQz(){
		await qz.security.setCertificatePromise(function(resolve, reject) {
			resolve("-----BEGIN CERTIFICATE-----\n" +
			"MIIE8TCCAtmgAwIBAgIQNzkyMDI0MDQxNzEyNTcyMzANBgkqhkiG9w0BAQsFADCB\n" +
			"mDELMAkGA1UEBhMCVVMxCzAJBgNVBAgMAk5ZMRswGQYDVQQKDBJRWiBJbmR1c3Ry\n" +
			"aWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMsIExMQzEZMBcGA1UEAwwQ\n" +
			"cXppbmR1c3RyaWVzLmNvbTEnMCUGCSqGSIb3DQEJARYYc3VwcG9ydEBxemluZHVz\n" +
			"dHJpZXMuY29tMB4XDTI0MDQxNzEyNTcyM1oXDTI1MDQxNzA1MDc0NFowgaoxCzAJ\n" +
			"BgNVBAYMAkJSMRowGAYDVQQIDBFSaW8gR3JhbmRlIGRvIFN1bDEWMBQGA1UEBwwN\n" +
			"Tm92byBIYW1idXJnbzEVMBMGA1UECgwMR1JVQiBESUdJVEFMMRUwEwYDVQQLDAxH\n" +
			"UlVCIERJR0lUQUwxFTATBgNVBAMMDEdSVUIgRElHSVRBTDEiMCAGCSqGSIb3DQEJ\n" +
			"AQwTZmVkZWRhY29zQGdtYWlsLmNvbTCCASIwDQYJKoZIhvcNAQEBBQADggEPADCC\n" +
			"AQoCggEBAKItsqHPaYsibr3ADvRZKv6Xrxfbdjx5857ZnlRFGmc2HD8bkhnyo12j\n" +
			"sAWNsY834Ev0r0OVHL9id4QPH83Zu1Wd2B+R4C60hWlC5tM3jdpF+RnN+B6ggwru\n" +
			"fsiVrqGmjKBkVbWbvuzHiONtkeoAG9l+9SC38/YKVpIfnH5lZ4vpVczYUPVG9oxB\n" +
			"o7CLDGBasuawoR/456hGOYLhwJO9J0lOAAJdevGmrRf5GDlw557fflGBUehwMb0q\n" +
			"pNBq7cLJGyGztOUrk7M8QIQahCuvRdRhkta+myPXrpm/VDCxLDLc4j4LY/Uihy+s\n" +
			"o0rKG/KFPfpN1HaQNFIrqjbsQkuVaBsCAwEAAaMjMCEwHwYDVR0jBBgwFoAUkKZQ\n" +
			"t4TUuepf8gWEE3hF6Kl1VFwwDQYJKoZIhvcNAQELBQADggIBACRvw/zc8B32lV3O\n" +
			"/Dfw2ffV7sJ0eufrhY3dc5WPpwl/SqxLIa2CVa8sKZ0yvcjoJp3GYr2IloPpQSQw\n" +
			"zlhdGGjG9WNAYfQOBmUgUfEk016NPRBCYK25i8bl1fCVBACkABAXYkWB5KP7emiS\n" +
			"A5cR4AmXzsctS2Fn81xZRPpWzTdC5AJZDgdNWkyNJ7Lnwe3+F+rlukGHR7bE0GSj\n" +
			"9C/Tgc7l/fHH6m4lzWEAT5o0UDS9SfXyW/R4g1FxrdPV/IfjWbOGIi1HXBSxOv9G\n" +
			"LRhW/6EYoEacK6v0tm2AYULZE46HtWLbGmHVjmY6GVylaJlfO5TLrY+H2FUjUWnY\n" +
			"jEChg7u4ObBysaINbuYKm5f91sNNtFfmZJxScQjf80oDLjMmAG7d+xW1Qc4XOgks\n" +
			"XmhrZPblmnlq2QjiCK53qBIv+eXuRlhi22T5Yp39Hi1ol1vlcCrTUU+7Pp73V5rG\n" +
			"uSoGlyfOWrqJlRyjMTpvW5WUHSYPY3XFGewp8n0Tdjb8C2kTfAQyWy1x3izLinmh\n" +
			"RYgHCyL1a1hiNjSNL7RyNxMgIqotJSEEePyG+D37Z1QLkRwCEapDXelu2taQHvHZ\n" +
			"HePsKeAhDJDFLB1fPFa8DpjfpipwIfQBEKsxP/MNqZNCms5BPiNZswlnWrRLXEa1\n" +
			"+trhoTq0pLMHed35yGMmYSED5DPR\n" +
			"-----END CERTIFICATE-----\n" +
			"--START INTERMEDIATE CERT--\n" +
			"-----BEGIN CERTIFICATE-----\n" +
			"MIIFEjCCA/qgAwIBAgICEAAwDQYJKoZIhvcNAQELBQAwgawxCzAJBgNVBAYTAlVT\n" +
			"MQswCQYDVQQIDAJOWTESMBAGA1UEBwwJQ2FuYXN0b3RhMRswGQYDVQQKDBJRWiBJ\n" +
			"bmR1c3RyaWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMsIExMQzEZMBcG\n" +
			"A1UEAwwQcXppbmR1c3RyaWVzLmNvbTEnMCUGCSqGSIb3DQEJARYYc3VwcG9ydEBx\n" +
			"emluZHVzdHJpZXMuY29tMB4XDTE1MDMwMjAwNTAxOFoXDTM1MDMwMjAwNTAxOFow\n" +
			"gZgxCzAJBgNVBAYTAlVTMQswCQYDVQQIDAJOWTEbMBkGA1UECgwSUVogSW5kdXN0\n" +
			"cmllcywgTExDMRswGQYDVQQLDBJRWiBJbmR1c3RyaWVzLCBMTEMxGTAXBgNVBAMM\n" +
			"EHF6aW5kdXN0cmllcy5jb20xJzAlBgkqhkiG9w0BCQEWGHN1cHBvcnRAcXppbmR1\n" +
			"c3RyaWVzLmNvbTCCAiIwDQYJKoZIhvcNAQEBBQADggIPADCCAgoCggIBANTDgNLU\n" +
			"iohl/rQoZ2bTMHVEk1mA020LYhgfWjO0+GsLlbg5SvWVFWkv4ZgffuVRXLHrwz1H\n" +
			"YpMyo+Zh8ksJF9ssJWCwQGO5ciM6dmoryyB0VZHGY1blewdMuxieXP7Kr6XD3GRM\n" +
			"GAhEwTxjUzI3ksuRunX4IcnRXKYkg5pjs4nLEhXtIZWDLiXPUsyUAEq1U1qdL1AH\n" +
			"EtdK/L3zLATnhPB6ZiM+HzNG4aAPynSA38fpeeZ4R0tINMpFThwNgGUsxYKsP9kh\n" +
			"0gxGl8YHL6ZzC7BC8FXIB/0Wteng0+XLAVto56Pyxt7BdxtNVuVNNXgkCi9tMqVX\n" +
			"xOk3oIvODDt0UoQUZ/umUuoMuOLekYUpZVk4utCqXXlB4mVfS5/zWB6nVxFX8Io1\n" +
			"9FOiDLTwZVtBmzmeikzb6o1QLp9F2TAvlf8+DIGDOo0DpPQUtOUyLPCh5hBaDGFE\n" +
			"ZhE56qPCBiQIc4T2klWX/80C5NZnd/tJNxjyUyk7bjdDzhzT10CGRAsqxAnsjvMD\n" +
			"2KcMf3oXN4PNgyfpbfq2ipxJ1u777Gpbzyf0xoKwH9FYigmqfRH2N2pEdiYawKrX\n" +
			"6pyXzGM4cvQ5X1Yxf2x/+xdTLdVaLnZgwrdqwFYmDejGAldXlYDl3jbBHVM1v+uY\n" +
			"5ItGTjk+3vLrxmvGy5XFVG+8fF/xaVfo5TW5AgMBAAGjUDBOMB0GA1UdDgQWBBSQ\n" +
			"plC3hNS56l/yBYQTeEXoqXVUXDAfBgNVHSMEGDAWgBQDRcZNwPqOqQvagw9BpW0S\n" +
			"BkOpXjAMBgNVHRMEBTADAQH/MA0GCSqGSIb3DQEBCwUAA4IBAQAJIO8SiNr9jpLQ\n" +
			"eUsFUmbueoxyI5L+P5eV92ceVOJ2tAlBA13vzF1NWlpSlrMmQcVUE/K4D01qtr0k\n" +
			"gDs6LUHvj2XXLpyEogitbBgipkQpwCTJVfC9bWYBwEotC7Y8mVjjEV7uXAT71GKT\n" +
			"x8XlB9maf+BTZGgyoulA5pTYJ++7s/xX9gzSWCa+eXGcjguBtYYXaAjjAqFGRAvu\n" +
			"pz1yrDWcA6H94HeErJKUXBakS0Jm/V33JDuVXY+aZ8EQi2kV82aZbNdXll/R6iGw\n" +
			"2ur4rDErnHsiphBgZB71C5FD4cdfSONTsYxmPmyUb5T+KLUouxZ9B0Wh28ucc1Lp\n" +
			"rbO7BnjW\n" +
			"-----END CERTIFICATE-----\n")
		})

		const privateKey = "-----BEGIN PRIVATE KEY-----\n" +
						"MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCiLbKhz2mLIm69\n" +
						"wA70WSr+l68X23Y8efOe2Z5URRpnNhw/G5IZ8qNdo7AFjbGPN+BL9K9DlRy/YneE\n" +
						"Dx/N2btVndgfkeAutIVpQubTN43aRfkZzfgeoIMK7n7Ila6hpoygZFW1m77sx4jj\n" +
						"bZHqABvZfvUgt/P2ClaSH5x+ZWeL6VXM2FD1RvaMQaOwiwxgWrLmsKEf+OeoRjmC\n" +
						"4cCTvSdJTgACXXrxpq0X+Rg5cOee335RgVHocDG9KqTQau3CyRshs7TlK5OzPECE\n" +
						"GoQrr0XUYZLWvpsj166Zv1QwsSwy3OI+C2P1IocvrKNKyhvyhT36TdR2kDRSK6o2\n" +
						"7EJLlWgbAgMBAAECggEAN0P1VJi2sbgpHrZdIj1knl+YRjv+PbudHmuwPa1Swws4\n" +
						"cvT1Q6xgqV9Hc40GjfSPlbKrKw+cPUfTzIfO4bop0ZI5fQCb8a6IrAclW6LZzyD7\n" +
						"MyTyQZWXMll1kOsZGHMGBv5KCtopJM0WQ9Ip4CDrXKGb7Oti0lxRhfgJvzTVJWLy\n" +
						"PYydbQAjGbgtRopr9REty4HK3wvexHa5TORwJiDc5szrw4L2hhhfSixwa1BRctWH\n" +
						"edkVnXTcjKxTxe5+2WEygt6a27gOQ5tfpiGJVH3oUBys2k8EmFqxvmHMMDCHM4Yw\n" +
						"eueWhFbQQlG6cMXyv0qNXZV/JR0DRKSa2wml+KP9GQKBgQDYmXwQL59IUuyj61Rt\n" +
						"zam27+CzUb2V9yXJzc+vTT+e+Yjs94OGVV/SrLWKEOHFtDLV7XD08Div0v2PCDdB\n" +
						"DB8fjJRctzFK5JYuCd32LifPkqgxShAS/lcMHymuuC3E8PZejSmKH94tEnyiEM1Q\n" +
						"XzVZMAGSPww9bqNfBFebzWIMRQKBgQC/rfUQjWCcFHPtCxU730jbcpTYU3esVhsh\n" +
						"utnRmql4IUFtr4qZBYS5rMEZ56u/GukQQGKUt/8WVGdhS99kClqRvsQC8xUegG4q\n" +
						"NhZ1UmucA+HVeT4DdaueiOU86B5yCxp/mP4ruX0b9fwUr0hOeoDGeCsH69X8/bEt\n" +
						"ENnluvFY3wKBgB/t+FD2hDeIKeQ8I7UQFU2nbOtiSF1cEpD+Kvh/nbpUtK+iCRx7\n" +
						"Z2XKGfThajbXyoqotvcUlnas1r4ICwUmwUvIUHKeesC8k7rzr2nEAhBiYTLq5RW3\n" +
						"sqNR0LY15mFa2Ca2DoKTEhB44jCN44ZOkrG0Fvc1HhvMPH8cR8Hq8+I1AoGANFUl\n" +
						"VIiU+Ogoom5lNLyIosmM0+cuBFJaBAKE19JXNKI3v9+ugorcKul8qzryTDHnsfUy\n" +
						"NRJrRchzlT/m1ORyx9oCoB+IA5Ti/wlXZl7DXIw+y07X3I/y9EzB8bgaH97iNDYs\n" +
						"Jvn0q36kfW1B25Nct1M1xGriOLl29yvjofJBVJcCgYAw5wYQUTphCJPZ58JQeMGb\n" +
						"vIDUltmNBz74tmBi+tbSyX/WlOSXX33P7xrBgd42bxpKzp9daYAn+kZ8Bwu2cSGM\n" +
						"kB0KA98etPSxSzU17e9S3phBfXlz0jw5zPSsfvyigagkE+EGi5pYvupLj7Fz55jx\n" +
						"2BVaz2pj/7NbnAGID+GyVA==\n" +
						"-----END PRIVATE KEY-----\n"	

    	await qz.security.setSignatureAlgorithm("SHA512")
		await qz.security.setSignaturePromise(function(toSign) {
			return function(resolve, reject) {
				try {
					var pk = KEYUTIL.getKey(privateKey)
					var sig = new KJUR.crypto.Signature({"alg": "SHA512withRSA"})  // Use "SHA1withRSA" for QZ Tray 2.0 and older
					sig.init(pk)
					sig.updateString(toSign)
					var hex = sig.sign()
					resolve(stob64(hextorstr(hex)))
				} catch (err) {
					console.error(err)
					reject(err)
				}
			}
		})
	}
	
	async atualizaImpressoras(){
		const request = await getList("Impressoras")
		const devicesFromServer = request.data
		const devices = []

		devicesFromServer.forEach(item => {
			devices.push({
				id:item.id,
				name: item.nome,
				vendorId: item.vendor,
				serialNumber: item.serial_number,
				host: item.host,
				ports: item.ports,
				ativo:false,
				obj: null
			})
		})

		this.handleImpressoras(devices)
	}

	async atualizaFilas(){
		const filas = await getList("FilaImpressao")
		this.handleFilas(filas.data)   
	}


	async componentDidMount() {
		this.handleSetFont()
		window.addEventListener('scroll', this.handleScroll)

		await this.atualizaFilas()
		await this.atualizaImpressoras()
		await this.configQz()
		await this.saveUserData()
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll)
	}
  
	handleScroll = () => {
		if (window.scrollY > 0) {
			this.setState(state => ({
					hasScroll: true
				}));
		} else {
			this.setState(state => ({
					hasScroll: false
				}));
		}
		var elm = document.getElementsByClassName('nvtooltip');
		for (var i = 0; i < elm.length; i++) {
			elm[i].classList.add('d-none');
		}
	}
	
	render() {
		return (
			<AppSettings.Provider value={this.state}>
				<div className={
					'app has-scroll ' +
					(this.state.appSidebarFixed ? 'app-sidebar-fixed ' : '') +
					(this.state.appHeaderFixed && !this.state.appHeaderNone ? 'app-header-fixed ' : '') + 
					(this.state.appSidebarNone ? 'app-without-sidebar ' : '') + 
					(this.state.appContentFullHeight ? 'app-content-full-height ' : '')
				}>

					{!this.state.appHeaderNone && (<Header />)}
					{!this.state.appSidebarNone && (<Sidebar />)} 
					<Content />
					
				</div>
				<ReactNotifications />
			</AppSettings.Provider>
		)
	}
}

export default App;