import React from 'react'
import { Marker, GoogleMap, withScriptjs, withGoogleMap, DirectionsRenderer } from "react-google-maps"
import { Link } from 'react-router-dom'

import Lottie from "lottie-react"
import lottiejson from "./../assets/pedidos-vazios-cozinha.json"
import { getList, getItem } from '../store/api.js'
 
import TopPage      from '../components/_organisms/TopPage'

import { compose, withProps, lifecycle } from "recompose"

const MapWithADirectionsRenderer = compose(
    withProps({
        googleMapURL:"https://maps.googleapis.com/maps/api/js?key=AIzaSyDT4DEK1lYFBmdqBZZhZeU6Wo1bcE2D4k4",
        loadingElement:<div style={{ height: `100%` }} />,
        containerElement:<div style={{ height: `550px` }} />,
        mapElement:<div style={{ height: `100%` }} />,
        
    }),
    withScriptjs,
    withGoogleMap,
    lifecycle({
        componentDidMount() {
            const DirectionsService = new window.google.maps.DirectionsService()
            DirectionsService.route({
                origin: new window.google.maps.LatLng(this.props.lat, this.props.lng),
                destination: new window.google.maps.LatLng(this.props.dlat, this.props.dlng),
                travelMode: window.google.maps.TravelMode.DRIVING,
            }, (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    this.setState({
                        directions: result,
                    });
                } else {
                    console.error(result)
                }
            })
        },
        componentDidUpdate(prevProps, prevState){
            if (prevProps.dlat !== this.props.dlat || prevProps.dlng !== this.props.dlng) {
                const DirectionsService = new window.google.maps.DirectionsService()
                DirectionsService.route({
                    origin: new window.google.maps.LatLng(this.props.lat, this.props.lng),
                    destination: new window.google.maps.LatLng(this.props.dlat, this.props.dlng),
                    travelMode: window.google.maps.TravelMode.DRIVING,
                }, (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        this.setState({
                            directions: result,
                        });
                    } else {
                        console.error(result)
                    }
                })
            }
        }
    })
  )(props =>
    <GoogleMap
        defaultZoom={13}
        defaultCenter={new window.google.maps.LatLng(props.lat, props.lng)}
        options={{
            streetViewControl: false,
            scaleControl: false,
            mapTypeControl: false,
            zoomControl: false,
            fullScreen: false,
        }}
    >
        <Marker
            icon={{url: props.icon, scaledSize: new window.google.maps.Size(25, 25)}}
            position={{ lat: props.lat, lng: props.lng }}
            defaultImageSizes={5}
        >
        </Marker>

        <Marker
            position={{ lat: props.dlat, lng: props.dlng }}
            defaultImageSizes={5}
        >
        </Marker>
        {props.directions && <DirectionsRenderer options={{suppressMarkers: true}} directions={props.directions} lat={props.lat} lng={props.lng} dlat={props.dlat} dlng={props.dlng} />}
    </GoogleMap>
  )


const Map = withScriptjs(
    withGoogleMap(props =>
        <GoogleMap
            mapContainerStyle={{width: "100%"}}
            center={{ lat: props.lat, lng: props.lng }}
            zoom={13}
            options={{
                streetViewControl: false,
                scaleControl: false,
                mapTypeControl: false,
                zoomControl: false,
                fullScreen: false
            }}
            >
                <Marker
                    icon={{url: props.icon,  scaledSize: new window.google.maps.Size(25, 25)}}
                    position={{ lat: props.lat, lng: props.lng }}
                    defaultImageSizes={5}
                    >
                        
                </Marker>

                {props.directions && 
                    <Marker position={{ lat: props.dlat, lng: props.dlng }} > </Marker>
                }
            
        </GoogleMap>
    )
)

export default class DeliveryPedidos extends React.Component {

    constructor(props) {
		super(props)

		const user = JSON.parse(localStorage.getItem('user')).data
		this.state = {
			user: user,
			brand:user.brand ? user.brand : "",
            filtro: "andamento",
            pedidos: [],
            lista: [],
            pedido: null,
            directions: false,
		}

        this.fetchData = this.fetchData.bind(this)
        this.aplicaFiltro = this.aplicaFiltro.bind(this)
	}

	fetchData(){
        getList("Comanda",{filtro:'delivery'}).then(result => {
            this.setState({ pedidos: result.data, lista: result.data.filter( i => i.status != 'cancelado' && i.status != 'fechada' && i.status != 'entregue') })
        })
	}

    componentDidMount() {
		this.fetchData()
	}

    aplicaFiltro(e){
		e.preventDefault()
		const t = e.target
        const filtro = t.dataset.filter

        const result = filtro === 'andamento' ? this.state.pedidos.filter( i => i.status != 'cancelado' && i.status != 'fechada' && i.status != 'entregue') : this.state.pedidos.filter( i => i.status === 'cancelado' || i.status === 'fechada' || i.status === 'entregue') 

		this.setState({filtro: filtro, lista: result})
	}

    validaPedidos(pedidos){
		let result = true

		pedidos.forEach(element => {
			if (element.status == "aberto") {
				result = false
			}
		})

		return result
	}

    aplicaMapa(e, id){
        e.preventDefault()

        getItem("Comanda",id).then(result => {
            this.setState({ pedido: result.data, directions:true })
        })
    }

    render(){
        const user = this.state.user
        const loja = user ? this.state.user.loja : null
        const lista = this.state.lista
        const comanda = this.state.pedido

        return (
            <div>
                <TopPage titulo={"Delivery"}>
                    <p>Gerencie suas entregas com eficiência. Controle total sobre o processo de delivery, garantindo rapidez e qualidade no serviço.</p>
                </TopPage>
                <div className="tab-content panel rounded-0 rounded-bottom">
                    <div className="row">
                        <div className="col-md-8" style={{position: "relative"}}>
                            {user && this.state.directions && (
                                <MapWithADirectionsRenderer 
                                    lat={user && user.endereco && user.endereco.lat ? parseFloat(user.endereco.lat) : 0}
                                    lng={user && user.endereco && user.endereco.lng ? parseFloat(user.endereco.lng) : 0}
                                    directions={this.state.directions}
                                    dlat={this.state.pedido && this.state.pedido.entrega ? parseFloat(this.state.pedido.entrega.lat) : 0}
                                    dlng={this.state.pedido && this.state.pedido.entrega ? parseFloat(this.state.pedido.entrega.lng) : 0}
                                    icon={loja && loja.imagem ? "https://grubupload.s3.amazonaws.com/"+loja.imagem.imagem : ""}
                                />
                            )}

                            {user && !this.state.directions && (
                                 <Map
                                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDT4DEK1lYFBmdqBZZhZeU6Wo1bcE2D4k4"
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div style={{ height: `550px` }} />}
                                    mapElement={<div style={{ height: `100%` }} />}
                                    lat={user && user.endereco && user.endereco.lat ? parseFloat(user.endereco.lat) : 0}
                                    lng={user && user.endereco && user.endereco.lng ? parseFloat(user.endereco.lng) : 0}
                                    icon={loja && loja.imagem ? "https://grubupload.s3.amazonaws.com/"+loja.imagem.imagem : ""}
                                />
                            )}

                            {comanda && (
                                <div style={{ padding: '10px', background: 'white', position: 'absolute', width: '250px', height: '250px', top: '10px', right: '20px', borderRadius: '10px', boxShadow: '0px 0px 5px 0px'}}>
                                    <div className="col-6" style={{fontSize:'14px'}}><b>#{comanda.id}</b></div>
                                    <div className="col-6" >
                                        {comanda.status === 'aberta' && (
                                            <span className="badge border border-warning text-warning px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                                    <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                                    Aberta
                                            </span>
                                        )}
                                        {comanda.status === 'analise' && (
                                            <span className="badge border border-warning text-warning px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                                    <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                                    Em Analise
                                            </span>
                                        )}
                                        {comanda.status === 'cancelada' && (
                                            <span className="badge border border-danger text-danger px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                                    <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                                    Cancelada
                                            </span>
                                        )}
                                        {comanda.status === 'producao' && (
                                            <span className="badge border border-primary text-primary px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                                    <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                                    Em produção
                                            </span>
                                        )}
                                        {comanda.status === 'caminho' && (
                                            <span className="badge border border-primary text-primary px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                                    <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                                    Saiu para Entrega
                                            </span>
                                        )}
                                        {comanda.status === 'entregue' && (
                                            <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                                    <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                                    Entregue
                                            </span>
                                        )}
                                        {comanda.status === 'fechada' && (
                                            <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                                    <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                                    Fechada
                                            </span>
                                        )}
                                    </div>
                                    <div>{comanda.created_at}</div>
                                    <div className='mt-3 mb-3'>
                                        {comanda.pedidos.map((p) => (
                                            <>
                                                {p.produtos.map((i) => 
                                                    <div>{i.quantidade}x{i.nome}</div>
                                                )}
                                            </>
                                        ))}
                                    </div>
                                    <div style={{color:'green', fontSize:"20px", fontWeight:'bold'}}>{comanda.pagamentos[0].valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</div>
                                    <div><b>Tipo:</b> {comanda.pagamentos && comanda.pagamentos.length > 0 ? comanda.pagamentos[0].tipo : ""}</div>
                                </div>
                            )}
                        </div>
                        <div className="col-md-4">
                            {lista && lista.length > 0 ? (
                                <>
                                    <ul className="nav nav-tabs nav-tabs-v2 mb-3" style={{border:"none"}}>
                                        <li key="nova" className="nav-item me-2" >
                                            <Link to="nova" data-filter="andamento" onClick={this.aplicaFiltro} className={this.state.filtro === "andamento" ? "nav-link px-2 active" : "nav-link px-2"}>
                                                EM ANDAMENTO
                                            </Link>
                                        </li>
                                        <li key="lista" className="nav-item me-2" >
                                            <Link to="lista" data-filter="concluidos" onClick={this.aplicaFiltro} className={this.state.filtro === "concluidos" ? "nav-link px-2 active" : "nav-link px-2"}>
                                                CONCLUIDOS
                                            </Link>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane fade show active" id="allTab">
                                            
                                            <div className="table-responsive mb-3" style={{width:"95%"}}>
                                                <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Pedido</th>
                                                            <th>Tipo</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {lista.length > 0 && lista.map((item) => 
                                                            <tr key={"item_"+item.id}>
                                                                <td><Link to="#" onClick={(e) => this.aplicaMapa(e, item.id)} className="fw-bold">#{item.id}</Link></td>
                                                                <td>
                                                                    <span className="badge border border-indigo text-indigo px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                                                        <i className="fa fa-truck-fast fs-9px fa-fw me-5px"></i> Delivery 
                                                                    </span>
                                                                </td>
                                                            
                                                                <td> 
                                                                    {item.status === 'analise' && (
                                                                        <span className="badge border border-warning text-warning px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                                                                <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                                                                Em Analise
                                                                        </span>
                                                                    )}
                                                                    {item.status === 'cancelada' && (
                                                                        <span className="badge border border-danger text-danger px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                                                                <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                                                                Cancelada
                                                                        </span>
                                                                    )}
                                                                    {item.status === 'producao' && (
                                                                        <span className="badge border border-primary text-primary px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                                                                <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                                                                Em produção
                                                                        </span>
                                                                    )}
                                                                    {item.status === 'caminho' && (
                                                                        <span className="badge border border-primary text-primary px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                                                                <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                                                                Saiu para Entrega
                                                                        </span>
                                                                    )}
                                                                    {item.status === 'entregue' && (
                                                                        <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                                                                <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                                                                Entregue
                                                                        </span>
                                                                    )}
                                                                    {item.status === 'fechada' && item.b_delivery && (
                                                                        <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                                                                <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                                                                Entregue
                                                                        </span>
                                                                    )}
                                                                </td>
                                                               
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>

                                            {this.props.paginacao && (this.paginacao())}
                                        </div>
                                    </div>
                                </>
                            )
                            :
                            ( 
                                <div className="card border-0" style={{padding:"15px"}}>
                                    <Lottie animationData={lottiejson} loop={true} style={{width: "450px", margin:"auto"}} />
                                    <h1 style={{textAlign:"center"}}>Nenhum pedido por aqui! Aproveite seu dia... </h1>
                                    <p  style={{textAlign:"center"}}>De momento não tem nenhum pedido no seu estabelecimento.</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
}