import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';

export default class DropdownProfile extends React.Component {
	constructor(props) {
		super(props)
		const ls = localStorage.getItem('user')

		this.toggle = this.toggle.bind(this)
		this.state = {
			login:ls ? JSON.parse(localStorage.getItem('user')).data : null,
			dropdownOpen: false
		};
	}

	toggle() {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	}
	
	logout(){
		localStorage.clear()

		window.location.href = "/"
	}

	render() {
		const login = this.state.login
		const img = login && login.loja.imagem && login.loja.imagem.imagem ? login.loja.imagem.imagem : login && login.imagem_perfil ? login.imagem_perfil.imagem : "" 
		return (
			<Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="navbar-item navbar-user dropdown" tag="div">
				<DropdownToggle tag="a" className="navbar-link dropdown-toggle d-flex align-items-center">
					<div className="image image-icon bg-gray-800 text-gray-600">
						<img alt="" src={"https://grubupload.s3.amazonaws.com/"+img}/>
					</div>
					<span>
						<span className="d-none d-md-inline">{login && login.nome_fantasia && (login.nome_fantasia)}</span>
						<b className="caret"></b>
					</span>
				</DropdownToggle>
				<DropdownMenu className="dropdown-menu dropdown-menu-end" end tag="div">
					<Link to="/negocio/edit/Loja" className="menu-link" style={{textDecoration:"none"}}>
						<DropdownItem>
							<i className="fa fa-gear"></i> Configurações
						</DropdownItem>
					</Link>
					<Link to="/negocio/edit/Loja" className="menu-link" style={{textDecoration:"none"}}>
						<DropdownItem>
							<i className="fa fa-briefcase"></i> Meu plano
						</DropdownItem>
					</Link>
					<hr />
					<Link to="/" className="menu-link" style={{textDecoration:"none"}} onClick={() => this.logout()}>
						<DropdownItem>
							<i className="fa fa-right-from-bracket"></i> Fechar Sessão
						</DropdownItem>
					</Link>
				</DropdownMenu>
			</Dropdown>
		);
	}
}