import React from 'react'

import { saveObj, getList, getItem, removeOpcional } from '../../store/api.js'

export default class FormAdicionalSeletor extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            model:this.props.model,
            id:this.props.id,
            adicionaisOriginal: [],
            adicionaisVinculadosOriginal:[],
            adicionais: [],
            adicionaisVinculados:[],
            adicionaisBusca: [],
            adicionaisVinculadosBusca:[]
        }

        this.setModelData = this.setModelData.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.filtro = this.filtro.bind(this)
        this.clearFiltro = this.clearFiltro.bind(this)
    }

    fetchData(force = false){
		if (this.state.adicionais.length == 0 || force) {
            getItem('produto-adicional', this.state.id).then(resultvinculados => {
                this.setState({ adicionaisVinculados: resultvinculados.data })

                getList('Opcional').then(result => {
                    let filtrado = []

                    result.data.map((el) => {
                       const exist = resultvinculados.data.filter((r) => {return r.id == el.id})

                       if (exist.length == 0) filtrado.push(el)
                    })

                    this.setState({ adicionais: filtrado })
                })
            })
		}
	}

    filtro(e, vinculado = false){
        e.preventDefault()

        if (e.target && e.target.value.length > 0) {
            if (vinculado){
                this.setState({ adicionaisVinculadosBusca: this.state.adicionaisVinculados.filter(i => {return !i.nome.toLowerCase().search(e.target.value.toLowerCase())}) })
            } else {
                this.setState({ adicionaisBusca: this.state.adicionais.filter(i => {return !i.nome.toLowerCase().search(e.target.value.toLowerCase())}) })
            }
        } else {
            this.setState({ adicionaisBusca: [], adicionaisVinculadosBusca: []})
        }

    }

    clearFiltro(vinculado = false){
        if (vinculado){
            this.setState({ adicionaisVinculadosBusca: [] })
        } else {
            this.setState({ adicionaisBusca: [] })
        }
       
    }

    async handleDelete(event){
        event.preventDefault()

        await removeOpcional(event.target.dataset.obj, this.state.id, "adicional")

        this.fetchData(true)
    }

    async handleAdd(event){
        event.preventDefault()

        const data = {items:{id_produto:this.props.id, id_opcional: [event.target.dataset.obj], tipo: "adicional"}, relations:{}}

        await saveObj(data, 'produto/opcional')

        this.fetchData(true)
    }

    async componentDidMount() {
        this.fetchData()
    }

    setModelData = (name, value) => {
        if (value !== undefined) {
            let data = this.state.data
            data.items[name] = value

            this.setState({ data })
        }
    }

    render() {
        const adicionais = this.state.adicionaisBusca.length > 0 ? this.state.adicionaisBusca : this.state.adicionais
        const adicionaisVinculados = this.state.adicionaisVinculadosBusca.length > 0 ? this.state.adicionaisVinculadosBusca : this.state.adicionaisVinculados

        return (
           
            <div className='row' style={{minHeight:'400px', position:'relative'}}>
                <div className="col-md-6"  style={{position:'relative'}}>
                    <div className='col-md-12 m-15px'>
                        <p>No campo abaixo, pode procurar por adicionais pre-cadastrados e asocia-los a este produto, quando selecionar os desejados basta em clicar em "Incluir adicionais selecionados" para guardar as alterações.:</p>
                    </div>
                    <div style={{height:'300px', position: 'absolute', bottom: '0', width: '90%'}}>
                        <div className="input-group mb-3">
                            <div className="flex-fill position-relative">
                                <div className="input-group">
                                    <div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0" style={{zIndex: 10}}>
                                        <i className="fa fa-search opacity-5"></i>
                                    </div>
                                    <input type="text" style={{margin:'0'}} className="form-control px-35px bg-light" placeholder=""  onChange={(e) => this.filtro(e, false)}/>
                                    <div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 end-0" style={{zIndex: 10, cursor:'pointer'}} onClick={(e) => this.clearFiltro(false)}>
                                        <i className="fa fa-close opacity-5"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="widget-list rounded" style={{height:'250px',overflowY:'scroll'}}>
                            {
                                adicionais.length > 0 && adicionais.map(i =>  (
                                    <div className="widget-list-item">
                                        <div className="widget-list-media icon">
                                            <i className="fa fa-circle-check bg-gray text-white"></i>
                                        </div>
                                        <div className="widget-list-content">
                                            <h4 className="widget-list-title">{i.nome}</h4>
                                        </div>
                                        <div className="widget-list-content">
                                            <h4 className="widget-list-title">{i.valor ? "R$ "+i.valor : "-"}</h4>
                                        </div>
                                        <div className="widget-list-action text-nowrap text-body text-opacity-50 fw-bold text-decoration-none">
                                            <a href="#" data-obj={i.id} onClick={(event) => this.handleAdd(event)} className="border border-primary text-primary px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center" style={{cursor:'pointer'}}>Incluir</a>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className="col-md-6" style={{position:'relative'}}>
                    <div className='col-md-12 m-15px'>
                        <h4>Adicionais disponiveis para este produto:</h4>
                    </div>
                    <div style={{height:'300px', position: 'absolute', bottom: '0', width: '90%'}}>
                        <div className="input-group mb-3">
                            <div className="flex-fill position-relative">
                                <div className="input-group">
                                    <div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0" style={{zIndex: 10}}>
                                        <i className="fa fa-search opacity-5"></i>
                                    </div>
                                    <input type="text" style={{margin:'0'}} className="form-control px-35px bg-light" placeholder=""  onChange={(e) => this.filtro(e, true)}/>
                                    <div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 end-0" style={{zIndex: 10, cursor:'pointer'}} onClick={(e) => this.clearFiltro(true)}>
                                        <i className="fa fa-close opacity-5"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="widget-list rounded" style={{height:'250px',overflowY:'scroll'}}>
                            {
                                adicionaisVinculados.length > 0 && adicionaisVinculados.map(i =>  (
                                    <div className="widget-list-item">
                                        <div className="widget-list-media icon">
                                            <i className="fa fa-circle-check bg-primary text-white"></i>
                                        </div>
                                        <div className="widget-list-content">
                                            <h4 className="widget-list-title">{i.nome}</h4>
                                        </div>
                                        <div className="widget-list-content">
                                            <h4 className="widget-list-title">{i.valor ? "R$ "+i.valor : "-"}</h4>
                                        </div>
                                        <div className="widget-list-action text-nowrap text-body text-opacity-50 fw-bold text-decoration-none">
                                            <i data-obj={i.id} onClick={(event) => this.handleDelete(event)} className="fa fa-trash text-body text-opacity-30 fa-lg" style={{cursor:'pointer'}}></i>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}