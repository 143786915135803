import * as qz from 'qz-tray'

export class Impressoras {

    static async imprime(context, impressoraID, data){
        const stateImpressoras = context.stateImpressoras
        const idx = impressoraID ? stateImpressoras.findIndex( (item) => item.id == impressoraID ) : 0

        const decoder = new TextDecoder()
        const str = decoder.decode(data)

        try {
            const is = await qz.websocket.isActive()

            if (!is) await qz.websocket.connect()

            if(stateImpressoras[idx].host) {

                await qz.socket.open(stateImpressoras[idx].host, 9100)
                await qz.socket.sendData(stateImpressoras[idx].host, 9100, {data:str})
                await qz.socket.close(stateImpressoras[idx].host, 9100)
            
            } else {

                await qz.serial.openPort(stateImpressoras[idx].ports,{ baudRate: 9600 })
                await qz.serial.sendData(stateImpressoras[idx].ports, {data:str})
                await qz.serial.closePort(stateImpressoras[idx].ports)
           
            }

            await qz.websocket.disconnect()
            return true
        } catch (e) {
            console.log(e)
            return false
        }
    }

    static async conecta(context, impressoraID){
        const stateImpressoras = context.stateImpressoras
        const idx = stateImpressoras.findIndex( (item) => item.id == impressoraID )
        let device = stateImpressoras[idx].obj

        try {
            if (stateImpressoras[idx].ativo) {
                await device.close()
                stateImpressoras[idx].ativo = false
                stateImpressoras[idx].obj = null
            } else {
                if (!device) device = await window.navigator.serial.requestPort()

                await device.open({ baudRate: 9600 })
                stateImpressoras[idx].ativo = true
                stateImpressoras[idx].obj = device
            }

            context.handleImpressoras(stateImpressoras)
            return true
        } catch (e) {
            console.log(e)
            return false
        }
    }

    static hasInativa(context){
        const devicesInativos = context.stateImpressoras.filter( (i) => i.ativo == false && i.host === null )

        return {
            total: context.stateImpressoras.length,
            inativo: devicesInativos
        }
    }

    static hasAtivas(context){
        const devicesAtivos = context.stateImpressoras.filter( (i) => i.ativo == true || i.host !== null )

        return {
            total: context.stateImpressoras.length,
            ativo: devicesAtivos
        }
    }

    static async isQzAtivo(){
        let conexao = false

        try {
            const valida = await qz.websocket.isActive()

            if (valida) conexao = true
    
        } catch (e) {
            console.log('erro: ', e)
        }

        return conexao
    }

    static async QzStartConnection(){
        try {
            const r = await qz.websocket.connect()

            console.log("---->", r)
            return true
    
        } catch (e) {
            console.log('erro: ', e)
            return false
        }
    }

    static async QzCloseConnection(){
        try {
            qz.websocket.disconnect()
            return true
        } catch (e) {
            console.log('erro: ', e)
            return false
        }
    }


    static async QztestConnection(){
        let conexao = false

        try {
            await qz.websocket.connect()
            const valida = await qz.websocket.isActive()

            if (valida) conexao = true
    
            await qz.websocket.disconnect()
        } catch (e) {
            console.log('erro: ', e)
        }

        return conexao
    }

}