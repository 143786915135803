import React from 'react'

import { getStruct, saveObj, getItem } from '../../store/api.js'

import InputText    from '../_atoms/InputText'
import Label        from '../_atoms/Label'
import Helper       from '../../config/helper'

export default class FormMesa extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            model:this.props.model,
            id:this.props.id,
            arrcampos:[],
            data:{
              items:{},
              relations:{}
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this)
        this.setModelData = this.setModelData.bind(this)
        this.setTipoComanda = this.setTipoComanda.bind(this)
    }

    async componentDidMount() {
        const struct = await getStruct(this.state.model)
        this.setState({ arrcampos: struct.data })

        const item = await getItem(this.state.model, this.state.id)

        if (item.data && item.data != '') {
            this.setState({ data: {items: item.data, relations: {Variacao: item.data.variacao} }})
        }
    }

    async handleSubmit(event) {
        event.preventDefault()
    
        try {
          await saveObj(this.state.data, this.state.model)
          Helper.addNotification('success', 'Sucesso', 'Registro excluido com sucesso', 'top-right')
          window.history.back()
        } catch (e) {
          Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'Não foi possivel salvar as informações enviadas.', 'top-right')
        }
    }

    setModelData = (name, value) => {
        if (value !== undefined) {
            let data = this.state.data
            data.items[name] = value
            data.items['status'] = 'aberta'
            data.items['token'] = Math.random().toString(16).slice(2)

            this.setState({ data })
        }
    }

    setTipoComanda(e){
        let data = this.state.data
        data.items['b_comanda'] = e.target.checked

        this.setState({ data })
    }
    

    render() {
        const atual = this.state.data.items
        return (
            <form onSubmit={this.handleSubmit}>
                <div className='row'>
                    <h3>Criar nova mesa ou comanda</h3>
                    <p>Aqui você pode criar as mesas ou comanda (cartão) se criará, um QR Code para que o cliente possa realizar seus pedidos ou apenas visualizar o cardápio.</p>
                </div>
                <div className='row'>
                    <div className="col-md-6">
                    
                        <div className="form-floating mb-15px">
                            <InputText
                                nome="identificador"
                                setFomrData={this.setModelData}
                                dadosIniciais={atual ? atual.identificador : ""}
                            />
                            <Label nome="Identificador" label="Identificador da Mesa"/>
                        </div>
                        
                    </div>

                    <div className="col-md-6">
                    
                        <div className="form-floating mb-15px">
                            <div className="form-check form-switch mb-15px">
                                <input className="form-check-input form-check-input-pos" checked={atual ? atual.b_comanda : ""} name="b_comanda" type="checkbox" onChange={this.setTipoComanda} />
                                <label className="form-check-label" htmlFor="ativo_cardapio">Tipo comanda</label>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div className='row'>
                    <div className="col-md-2 offset-md-10">
                        <button type="submit" style={{float: 'right'}} className="btn btn-success btn-block btn-lg">Salvar</button>
                    </div>
                </div>
            </form>
        )
    }
}