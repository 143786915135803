import React from 'react'

import { getList, saveObj } from './../store/api.js'
import { Link } from 'react-router-dom'
import TopPage from '../components/_organisms/TopPage'
import InputText    from '../components/_atoms/InputText'
import Label        from '../components/_atoms/Label'
import Multiseletor from '../components/_organisms/Multiseletor.jsx'

export default class CardapioCampeao extends React.Component {
    constructor(props) {
		super(props)
        this.state = {
            filtro: 'aba1',
            destaque: null,
            data:{
                items:{ nome: '' },
                relations:{}
              }
		}
		
        this.fetchData = this.fetchData.bind(this)
        this.aplicaFiltro = this.aplicaFiltro.bind(this)
        this.setModelData = this.setModelData.bind(this)
        this.saveName = this.saveName.bind(this)
	}

	async fetchData(){
        const destaques = await getList('Destaque')
        const data = this.state.data
        data.items['nome'] = ''
        let destaque = null

        if (this.state.filtro === 'aba1' && destaques.length > 0) {
            destaque = destaques.filter( d => d.prioridade == 1)

            if (destaque.length > 0) {
                data.items['id'] = destaque[0].id
                data.items['nome'] = destaque[0].nome
                this.setState({destaque: destaque[0], data: data})
            } else {
                this.setState({destaque: null, data: data})
            }
        } else if (this.state.filtro === 'aba2' && destaques.length > 0) {
            destaque = destaques.filter( d => d.prioridade == 2)

            if (destaque.length > 0) {
                data.items['id'] = destaque[0].id
                data.items['nome'] = destaque[0].nome
                this.setState({destaque: destaque[0], data: data})
            } else {
                this.setState({destaque: null, data: data})
            }
        } else if (this.state.filtro === 'aba3' && destaques.length > 0) {
            destaque = destaques.filter( d => d.prioridade == 3)
            
            if (destaque.length > 0) {
                data.items['id'] = destaque[0].id
                data.items['nome'] = destaque[0].nome
                this.setState({destaque: destaque[0], data: data})
            } else {
                this.setState({destaque: null, data: data})
            }
        }
	}

    async aplicaFiltro(e){
		e.preventDefault()

		const t = e.target
		const id = t.dataset.filter 

        this.setState({filtro: id})
        this.fetchData()
	}

    setModelData = (name, value) => {
        if (value !== undefined) {

            let data = this.state.data
            data.items[name] = value

            this.setState({ data })
        }
    }

    async saveName(){
        await saveObj(this.state.data, 'Destaque')
        this.fetchData()
    }

    componentDidMount() {
		this.fetchData()
	}

    render(){
        return (
			<div>
                <TopPage titulo="Cardápio Campeão">
                    <p className="lead">
                        Destaque os produtos e afunile os produtos que deseja que seu cliente veja primeiro no seu cardápio para aumentar as vendas. Com este módulo, seus produtos são vistos por mais pessoas, aumentando as chances de conversão em até 80%
                    </p>
                </TopPage>

                <div className="card border-0 p-4">
                    <div className="row" >
                        <div className='col-md-8'>

                            <ul className="nav nav-tabs nav-tabs-v2 px-3">
                                <li className="nav-item me-2">
                                    <Link to="aba1" data-filter="aba1" onClick={this.aplicaFiltro} className={this.state.filtro === "aba1" ? "nav-link px-2 active" : "nav-link px-2"}>
                                        Destaque principal
                                    </Link>
                                </li>
                                <li className="nav-item me-2">
                                    <Link to="aba2" data-filter="aba2" onClick={this.aplicaFiltro} className={this.state.filtro === "aba2" ? "nav-link px-2 active" : "nav-link px-2"}>
                                        Destaque secundário
                                    </Link>
                                </li>
                                <li className="nav-item me-2">
                                    <Link to="aba3" data-filter="aba3" onClick={this.aplicaFiltro} className={this.state.filtro === "aba3" ? "nav-link px-2 active" : "nav-link px-2"}>
                                        Destaque terciário
                                    </Link>
                                </li>
                            </ul>

                            <div className='row mb-3 mt-5 '>
                                <div className='col-md-8'>
                                    <div className="form-floating">
                                        <InputText
                                            nome="nome"
                                            required={true}
                                            setFomrData={this.setModelData}
                                            dadosIniciais={this.state.data.items.nome}
                                        />
                                        {this.state.filtro === 'aba1' && (
                                            <Label nome="nome" label="Destaque principal"/>
                                        )}

                                        {this.state.filtro === 'aba2' && (
                                            <Label nome="nome" label="Destaque secundário"/>
                                        )}

                                        {this.state.filtro === 'aba3' && (
                                            <Label nome="nome" label="Destaque terciário"/>
                                        )}
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <button type="submit" onClick={this.saveName} className="btn btn-primary btn-block btn-lg">Salvar</button>
                                </div>
                            </div>

                            {this.state.filtro === 'aba1' && (
                                <Multiseletor prioridade="1"/>
                            )}

                            {this.state.filtro === 'aba2' && (
                                <Multiseletor prioridade="2"/>
                            )}

                            {this.state.filtro === 'aba3' && (
                                <Multiseletor prioridade="3"/>
                            )}

                        </div>
                        <div className='col-md-4'>
                            <img alt="" width="360px" src="https://grubicons.s3.us-east-1.amazonaws.com/cardapio-img-campeao.png" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}