import React from 'react';
import { Link } from 'react-router-dom';

import DropdownProfile from './dropdown/profile.jsx';

import { AppSettings } from './../../config/app-settings.js';
import Helper from './../../config/helper.js';

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.toggleMegaMenu = this.toggleMegaMenu.bind(this);
		this.state = { collapseMegaMenu: false };

		this.domain = Helper.getDominio()
	}

	toggleMegaMenu() {
		this.setState({ collapseMegaMenu: !this.state.collapseMegaMenu });
	}
	render() {
		return (
			<AppSettings.Consumer>
				{({toggleMobileSidebar, toggleRightSidebar, toggleMobileRightSidebar,toggleMobileTopMenu, pageHeaderLanguageBar, pageHeaderMegaMenu, pageTwoSidebar, pageTopMenu, pageSidebar}) => (
					<div id="header" className="app-header app-header-inverse">
						<div className="navbar-header">
							{pageTwoSidebar && (
								<button type="button" className="navbar-toggle pull-left" onClick={toggleMobileRightSidebar}>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
									<span className="icon-bar"></span>
								</button>
							)}
							<Link to="/" className="navbar-brand"><img alt="" src={`https://grubicons.s3.amazonaws.com/logo-admin.svg`} width="70px" style={{maxWidth: 'none', maxHeight: 'none'}} /></Link>
						</div>

						<div className="navbar-nav">
							<DropdownProfile />
						</div>
					</div>
				)}
			</AppSettings.Consumer>
		)
	}
}

export default Header;
