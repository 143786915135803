import { store } from 'react-notifications-component'
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Br, Cut, Line, Printer, Text, Row, QRCode } from 'react-thermal-printer'


export default class Helper {
    static getDominio(){
        return "https://xerxes.grub.digital"
    }

	static getS3URL(){
		return "https://grubupload.s3.amazonaws.com/"
	}
	
	static getToken(){
		const ls = localStorage.getItem('user')
		const user = ls ? JSON.parse(ls) : null
		return user && user.data.token ? user.data.token.token : null
	}

	static toUnicode(str) {
		return str.split('').map(function (value) {
			var temp = value.charCodeAt(0).toString(16).toUpperCase()
			console.log(value, temp)
			if (temp.length > 2) {
				return '\\u+00' + temp
			}
			return value
		}).join('')
	}

	static removeEspeciais(str){
        const chars = ['ç','à','á','â','ã','é','ê','ó','ô','õ','í','ú','Ç','ç','À','Á','Â','Ã','É','Ê','Ó','Ô','Õ','Í','Ú','ñ','Ñ']
        const correta = ['c','a','a','a','a','e','e','o','o','o','i','u','C','c','A','A','A','A','E','E','O','O','O','I','U','n','N']

        let result = str

        chars.forEach((item, idx) => {
            result = result.replaceAll(item, correta[idx])
        })

        return result
    }
	

    static listContent(info, item, objId, model, handlerActions, obj){
		if (item.nome === "hexa") {
			const hexaStyle = {width:'16px', height: '16px', display: 'block', backgroundColor:info}

			return (
				<td>
					<i style={hexaStyle}></i>
				</td>
			)
		}  else if (item.nome === "imagem") {
			if(info) return <td className="with-img"><div style={{height: "60px", width: "60px", backgroundImage: 'url('+this.getS3URL()+(info.imagem ? info.imagem : info)+')', backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center", borderRadius:"10px"}} ></div></td>
			else return <td></td>
		} else if (item.nome === "icon") {
			return <td className="with-img"><img alt="" className="img-rounded height-30" style={{height: "30px", borderRadius: "10px"}} src={"https://grubicons.s3.us-east-1.amazonaws.com/"+info}/></td>
		} else if (item.nome === "order") {
			return (
				<td>
					<span data-direcao="cima" data-action="order" data-ref={model+"/"+objId} data-objid={objId} onClick={handlerActions} className='btn btn-white'><i className="fa-solid fa-arrow-up"></i></span>
					<span className='btn btn-white'>{info}</span>
					<span data-direcao="baixo" data-action="order" data-ref={model+"/"+objId} data-objid={objId} onClick={handlerActions} className='btn btn-white'><i className="fa-solid fa-arrow-down"></i></span>
				</td>
			)
		} else if (item.nome === 'acoes') {
			return (
				<td>
					<UncontrolledButtonDropdown>
						<DropdownToggle caret color="primary">AÇÕES</DropdownToggle>
						<DropdownMenu>
							{item.values.map( value => <DropdownItem data-action={value.acao} data-ref={model+"/"+objId} data-objid={objId} onClick={handlerActions} className="btn btn-default">{value.label}</DropdownItem>)}
						</DropdownMenu>
					</UncontrolledButtonDropdown>
				</td>
			)
		} else {
			
			if(info && typeof info === 'object') {
				return <td>{info[item.value]}</td>
			} else if (item.nome === "nome") {
				return <td><strong>{info}</strong></td>
			} else if (item.nome === "count_childs") {
				return <td>{obj && obj.meta && obj.meta[item.obj]}</td>
			} else {
				return <td>{info}</td>
			}

		}
    }

	static Rows(produtos){
		const rows = Array(<Line character="=" />)
		
		produtos.forEach(item => {
			rows.push(<Text size={{ width: 2, height: 2 }} invert={true}>{Helper.removeEspeciais(item.nome.toUpperCase())}</Text>)
			rows.push(<Br />)
			rows.push(<Text size={{ width: 2, height: 1 }}>{Helper.removeEspeciais("Quantidade: "+item.quantidade)}</Text>)

			if (item.variacao){
				rows.push(<Br />)
				rows.push(<Text align="left" size={{ width: 2, height: 1 }} bold={true}>{Helper.removeEspeciais("VARIAÇÃO: " + item.variacao)}</Text>)
			}

			if (item.opcionais.length > 0){
				rows.push(<Br />)
				rows.push(<Text align="left" bold={true}>{Helper.removeEspeciais("RETIRAR")}</Text>)
				{item.opcionais.forEach( op => {
					rows.push(<Text align="left">{Helper.removeEspeciais(op.nome)}</Text>)
				})}
			}
			
			if (item.adicionais.length > 0){
				rows.push(<Br />)
				rows.push(<Text align="left" bold={true}>{Helper.removeEspeciais("ADICIONAR")}</Text>)
				{item.adicionais.forEach( op => {
					rows.push(<Text align="left">{Helper.removeEspeciais(op.nome)}</Text>)
				})}
			}

			if (item.complemento && item.complemento != "null"){
				rows.push(<Br />)
				rows.push(<Text align="left" size={{ width: 2, height: 1 }} >{Helper.removeEspeciais("Comentário: "+item.complemento)}</Text>)
			}
			
			rows.push(<Line character="-" />)
		})

		return <>{rows}</>
	}

	static Row(item, rows){
		rows.push(<Line character="-" />)
		
		rows.push(<Text size={{ width: 2, height: 2 }} invert={true}>{Helper.removeEspeciais(item.nome.toUpperCase())}</Text>)
		rows.push(<Br />)
		rows.push(<Text size={{ width: 2, height: 1 }}>{Helper.removeEspeciais("Quantidade: "+item.quantidade)}</Text>)

		if (item.variacao){
			rows.push(<Br />)
			rows.push(<Text align="left" size={{ width: 2, height: 1 }} bold={true}>{Helper.removeEspeciais("VARIAÇÃO: " + item.variacao)}</Text>)
		}

		if (item.opcionais.length > 0){
			rows.push(<Br />)
			rows.push(<Text align="left" bold={true}>{Helper.removeEspeciais("RETIRAR")}</Text>)
			{item.opcionais.forEach( op => {
				rows.push(<Text align="left">{Helper.removeEspeciais(op.nome)}</Text>)
			})}
		}
		
		if (item.adicionais.length > 0){
			rows.push(<Br />)
			rows.push(<Text align="left" bold={true}>{Helper.removeEspeciais("ADICIONAR")}</Text>)
			{item.adicionais.forEach( op => {
				rows.push(<Text align="left">{Helper.removeEspeciais(op.nome)}</Text>)
			})}
		}

		if (item.complemento && item.complemento != "null"){
			rows.push(<Br />)
			rows.push(<Text align="left" size={{ width: 2, height: 1 }} >{Helper.removeEspeciais("Comentário: "+item.complemento)}</Text>)
		}
		
		rows.push(<Line character="-" />)

		return <>{rows}</>
	}

	static RowsDemonstrativo(produtos){
		const rows = Array(<Row left="Produto             Valor" right="Quant          Total" />)
		rows.push(<Line />)

		produtos.forEach((item, index) => {
			let space = " "
			let space2 = " "
			const need = 22 - (item.nome.length + item.valor.length)
			const need2 = 12 - (item.valor.length)
			
			for (let c = 0; c < need; c++){
				space += " "
			}

			for (let c = 0; c < need2; c++){
				space2 += " "
			}
			
			rows.push(<Row left={Helper.removeEspeciais(item.nome.substring(0, 17))+space+"R$ "+item.valor} right={item.quantidade+space2+"R$ "+( parseInt(item.quantidade) * parseFloat(item.valor) ).toFixed(2) }  />)
			
			if (index < produtos.length-1) rows.push(<Line character="-" />)
		})

		return <>{rows}</>
	}

	static FilasDeImpressoes(comanda, mesa, produtos){

		let identificadoEntrega = ""
		
		if (comanda.b_takeaway === 1) {
			identificadoEntrega = "Comanda #A"+comanda.id
		}

		if (comanda.mesa && comanda.mesa.b_comanda) {
			identificadoEntrega = "Comanda #"+mesa
		}

		if (comanda.b_takeaway === 0 && mesa && mesa != 0 && comanda.mesa.b_comanda === 0) {
			identificadoEntrega = "Pedidos da Mesa: "+mesa
		}

		if (comanda.b_delivery === 1 ) {
			identificadoEntrega = "Delivery #"+comanda.id
		}

		const rows = []
		
		produtos.forEach((item, index, arr) => {
			
			if (index > 0 && item.fila_identificador != arr[index-1].fila_identificador){
				rows.push(<Cut partial={true} lineFeeds={6} />)
				rows.push(<Text align="left" size={{ width: 2, height: 2 }}>{Helper.removeEspeciais(identificadoEntrega)}</Text>)
				rows.push(<Line />)
				rows.push(<Text align="left" size={{ width: 2, height: 1 }}>Setor: {Helper.removeEspeciais(item.fila_identificador)}</Text>)
			} else if (index === 0 ) {
				rows.push(<Text align="left" size={{ width: 2, height: 2 }}>{Helper.removeEspeciais(identificadoEntrega)}</Text>)
				rows.push(<Line />)
				rows.push(<Text align="left" size={{ width: 2, height: 1 }}>Setor: {Helper.removeEspeciais(item.fila_identificador)}</Text>)
			}

			rows.push(<Br />)
		
			{this.Row(item, rows)}
			
			if (index === produtos.length-1){
				rows.push(<Cut />)
			}
		})

		return <>{rows}</>
		
	}

	static async montaTempleteImpressao(comanda, mesa = null, produtoId = null, fila = null, reimprimegeral = false, produtosSelecionados = null, imprimeSelecionados = false){

		const pedidos = comanda.pedidos
		let produtos = []
		let isSelected = true

		if (pedidos.length > 0 ) {
			pedidos.map( (pedido, idx) => {
				pedido.produtos.map( (produto, pidx) => {
					if (idx+"_"+produto.id+"_"+pidx === produtoId) {
						produtos.push(produto)
					} else if (!produtoId) {
						if (produtosSelecionados.length > 0) {
							isSelected = produtosSelecionados.includes( pedido.id+"_"+produto.id )
						}

						if(!produto.impresso) {
							if (fila && fila.id === produto.fila && isSelected){
								produtos.push(produto)
							} else if (!fila && isSelected) {
								produtos.push(produto)
							}
						}

						if (reimprimegeral){
							if (fila && fila.id === produto.fila  && isSelected){
								produtos.push(produto)
							} else if (!fila && isSelected) {
								produtos.push(produto)
							}
						}

						isSelected = true
					}
				})
			})
		}

		const ordem = produtos.sort((a,b) => a.fila - b.fila)
		const encoder = text => new TextEncoder().encode(text)

		const data = (
            <Printer type="epson" width={48} encoder={encoder} characterSet="pc860_portuguese">
				{this.FilasDeImpressoes( comanda, mesa, ordem )}
            </Printer>
        )

		return data

	}

	static async montaTempleteImpressaoAutomatica(comanda, mesa = null, fila = null,  produtosSelecionados = null){
		let produtos = []

		if (produtosSelecionados.length > 0 ) {
			produtosSelecionados.map( (produto) => {
				if (fila && fila.id === produto.fila ){
					produtos.push(produto)
				} 
			})
		}

		const ordem = produtos.sort((a,b) => a.fila - b.fila)
		const encoder = text => new TextEncoder().encode(text)

		const data = (
            <Printer type="epson" width={48} encoder={encoder} characterSet="pc860_portuguese">
				{this.FilasDeImpressoes( comanda, mesa, ordem )}
            </Printer>
        )

		return data
	}

	static async montaTempleteImpressaoCancelamento(comanda, produtoId, mesa = null){

		const pedidos = comanda.pedidos
		const produtos = []

		if (pedidos.length > 0 ) {
			pedidos.map( (pedido, idx) => {
				pedido.produtos.map( (produto,  pidx) => {
					if (idx+"_"+produto.id+"_"+pidx === produtoId) {
						produtos.push(produto)
					}
				})
			})
		}

		let identificadoEntrega = ""
		
		if (comanda.b_takeaway === 1) {
			identificadoEntrega = "Comanda #A"+comanda.id
		}

		if (comanda.mesa && comanda.mesa.b_comanda) {
			identificadoEntrega = "Comanda #"+mesa
		}

		if (comanda.b_takeaway === 0 && mesa && mesa != 0 && comanda.mesa.b_comanda === 0) {
			identificadoEntrega = "Pedidos da Mesa: "+mesa
		}

		if (comanda.b_delivery === 1 ) {
			identificadoEntrega = "Delivery #"+comanda.id
		}

		const data = (
            <Printer type="epson" width={48} >
				<Text align="center" bold={true} invert={true} size={{ width: 2, height: 2 }}>
				____PRODUTO CANCELADO____
				</Text>
				<Line character="=" />
                <Text align="center" size={{ width: 2, height: 2 }}>
					{identificadoEntrega}
				</Text>
                <Br />
                
				{this.Rows(produtos)}
                
                <Cut />
            </Printer>
        )

		return data

	}

	static impressaoTeste(texto){
		const data = (
            <Printer type="epson" width={48} >
				<Line character="=" />
				<Text align="center" size={{ width: 1, height: 1 }}>
					{this.removeEspeciais(texto)}
				</Text>
				<Line character="=" />
                <Cut />
            </Printer>
        )

		return data
	}

	static time() {
			const today = new Date()
			let h = today.getHours()
			let m = today.getMinutes()
			
			m = (m < 10) ? "0" + m : m
			const a = (h > 11) ? 'pm' : 'am'
			h = (h > 12) ? h - 12 : h
			
			return today.getDate()+"/"+(today.getMonth()+1)+"/"+today.getFullYear()+" "+h + ":" + m + a
	}

	static dadosEntrega(comanda){
		const rows = Array(<Line character="-" />)

		rows.push(<Text align="center" size={{ width: 2, height: 2 }} invert={true}>{Helper.removeEspeciais("INFO. DA ENTREGA")}</Text>)
		rows.push(<Br />)
		rows.push(<Text align="center" size={{ width: 1, height: 1 }} bold={true}>NOME: {Helper.removeEspeciais(comanda.entrega.nome)}  </Text>)
		rows.push(<Line character="-" />)
		rows.push(<Text align="center" size={{ width: 2, height: 2 }} invert={true}>{Helper.removeEspeciais("END. DE ENTREGA")}</Text>)
		rows.push(<Br />)
		rows.push(<Text align="center" size={{ width: 1, height: 1 }} bold={true}>{Helper.removeEspeciais(comanda.entrega.rua)}, {comanda.entrega.numero}, {Helper.removeEspeciais(comanda.entrega.bairro)}, {Helper.removeEspeciais(comanda.entrega.cidade)}</Text>)
		rows.push(<Text align="center" size={{ width: 1, height: 1 }} bold={true}>{Helper.removeEspeciais(comanda.entrega.cep)}</Text>)

		return <>{rows}</>
	}

	static impressaoDemostrativo(loja, produtos, subtotal, valor, taxa, comanda, mesa = null, totalProdutos, usetaxa = true, calculaDesconto = null){
		
		let identificadoEntrega = ""
		let valorTotal = valor
		
		if (comanda.b_takeaway === 1) {
			identificadoEntrega = "Comanda #A"+comanda.id
		}

		if (comanda.mesa && comanda.mesa.b_comanda) {
			identificadoEntrega = "Comanda #"+mesa
		}

		if (comanda.b_delivery === 0 && comanda.b_takeaway === 0 && mesa && mesa != 0 && comanda.mesa.b_comanda === 0) {
			identificadoEntrega = "Pedidos da Mesa: "+mesa
		}

		if (comanda.b_delivery === 1) {
			identificadoEntrega = "Delivery #"+comanda.id
			valorTotal = parseFloat(subtotal) + parseFloat(taxa) + parseFloat(calculaDesconto)
		}

		const data = (
            <Printer type="epson" width={48} >
                <Text align="center" size={{ width: 2, height: 2 }}>
					{Helper.removeEspeciais(loja.nome_fantasia)}
				</Text>
				<br></br>
				<Text align="center" size={{ width: 1, height: 1 }}>
					{loja.loja.cnpj}
				</Text>
				<Line character="=" />
				<Text align="center" size={{ width: 1, height: 1 }}>
					{Helper.removeEspeciais("CUPOM PARA SIMPLES CONFERÊNCIA")}
				</Text>
				<Line character="=" />
				<Text align="center" size={{ width: 1, height: 1 }}>
					{Helper.removeEspeciais(identificadoEntrega)}
				</Text>
				<Text align="center" size={{ width: 1, height: 1 }}>
					{this.time()}
				</Text>
				{comanda.entrega && (
					this.dadosEntrega(comanda)
				)}
                <Br />
				{comanda.b_delivery === 1 && comanda.entrega && (
					<QRCode align="center" model="model1" content={comanda.entrega.id+","+loja.loja.id} cellSize="8" correction="H"/>
				)}
				{comanda.b_delivery === 1 &&  (
					<Br />
				)}
				{comanda.b_delivery === 1 &&  (
					<Text align="center" size={{ width: 1, height: 1 }}>
						QR CODE DE COLETA DO ENTREGADOR
					</Text>
				)}
				<Br />

				{this.RowsDemonstrativo(produtos)}
				<Line />
				<Br />

				<Row left="Subtotal:" right={"R$ "+subtotal}  />
				{ usetaxa && (
					<Line />
				)}
				{ usetaxa && comanda.b_delivery === 1 && (
					<Row left="Taxa de entrega:" right={"R$ "+taxa}  />
				)}
				{ usetaxa && comanda.b_delivery === 1 && calculaDesconto && (
					<Row left="Desconto:" right={"R$ "+parseFloat(calculaDesconto).toFixed(2)}  />
				)}

				{ usetaxa && comanda.b_delivery === 0 && (
					<Row left="Taxa de servico:" right={"R$ "+taxa}  />
				)}
				<Line />
                <Row left={
					<Text size={{ width: 2, height: 2 }}>
						{totalProdutos > produtos.length ? "Parcial:" : "Total:" }
					</Text>
					} right={
						<Text size={{ width: 2, height: 2 }}>
							{"R$ "+parseFloat(valorTotal).toFixed(2)}
						</Text>
					}  />

				
				<Cut />
            </Printer>
        )

		return data

	}

    static addNotification(notificationType, notificationTitle, notificationMessage, notificationPosition, notificationContent) {
  		store.addNotification({
  			title: notificationTitle,
  			message: notificationMessage,
  			type: notificationType,
  			insert: "top",
  			container: notificationPosition,
  			animationIn: ["animated", "fadeIn"],
  			animationOut: ["animated", "fadeOut"],
  			dismiss: { duration: 2000 },
  			dismissable: { click: true },
  			content: notificationContent
  		})
  	}
}