import React from 'react'

import { saveObj, getList, deleteObj } from '../../store/api.js'
import { AppSettings } from '../../config/app-settings.js'
import { Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

import * as qz from 'qz-tray'

import { render } from 'react-thermal-printer'
import { Impressoras } from '../../servicos/Impressoras.js'

import InputText    from '../_atoms/InputText'
import Label        from '../_atoms/Label'
import Helper       from '../../config/helper'
import DropDown     from '../_atoms/DropDown'

export default class FormImpressao extends React.Component {
    static contextType = AppSettings

    constructor(props) {
        super(props)

        this.state = {
            OBJID: null,
            modalImpressao:false,
            modalImpressoraRede:false,
            modalImpressora: false,
            ports:[],
            data:{
                items:{}
            }
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleSubmitImpressoraRede = this.handleSubmitImpressoraRede.bind(this)
        this.handleSubmitImpressora = this.handleSubmitImpressora.bind(this)
        this.setModelData = this.setModelData.bind(this)
       
        this.modalImpressao = this.modalImpressao.bind(this)
        this.modalImpressoraRede = this.modalImpressoraRede.bind(this)
        this.modalImpressora = this.modalImpressora.bind(this)
        this.addImpressora = this.addImpressora.bind(this)
        this.conectaImpressora = this.conectaImpressora.bind(this)
        this.editaItem = this.editaItem.bind(this)
        this.deleteImpressora = this.deleteImpressora.bind(this)
        this.setFilaFiscal = this.setFilaFiscal.bind(this)
        this.getImpressorasUSB = this.getImpressorasUSB.bind(this)
    }

    componentDidMount() {
    }

    modalImpressao(e){
        e.preventDefault()

        this.setState({data:{ items: {} }})
		this.setState({modalImpressao: !this.state.modalImpressao})
    }

    modalImpressoraRede(e){
        e.preventDefault()

        this.setState({data:{ items: {} }})
		this.setState({modalImpressoraRede: !this.state.modalImpressoraRede})
    }

    async modalImpressora(e){
        e.preventDefault()

        await this.getImpressorasUSB()
        this.setState({data:{ items: {} }})
		this.setState({modalImpressora: !this.state.modalImpressora})
    }

    async setModelData(name, value) {
        if (value !== undefined) {

            let data = this.state.data
            data.items[name] = value

            this.setState({ data })
        }
    }

    setFilaFiscal(e){
        this.setModelData('b_financeiro', e.target.checked)
    }

    //Persistencia de filas
    async handleSubmit(event) {
        event.preventDefault()
    
        try {
            await saveObj(this.state.data, "FilaImpressao")
            const filas = await getList("FilaImpressao")
            await this.context.handleFilas(filas.data)
            this.setState({modalImpressao: !this.state.modalImpressao})

            Helper.addNotification('success', 'Tudo certo por aqui :)', 'Mudanças feitas foram salvas com sucesso.', 'top-right')
        } catch (e) {
          Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'Não foi possivel salvar as informações enviadas.', 'top-right')
        }
    }

    async deleteItem(e){
		e.preventDefault()

        const id = e.target.dataset.id
        const filas = this.context.stateFilas.filter(item => item.id == id )
        const categ = filas[0].categoria

        if (categ.length === 0) {
            await deleteObj('FilaImpressao/'+id)
            const filas = await getList("FilaImpressao")
            await this.context.handleFilas(filas.data)

            Helper.addNotification('success', 'Tudo certo por aqui :)', 'Mudanças feitas foram salvas com sucesso.', 'top-right')
        } else {
            Helper.addNotification('danger', 'Erro de validação :)', 'Não pode ser eliminado o setor de impressão porque tem uma categoria asociado a ele.', 'top-right')
        }
		
	}

    async editaItem(e){
        e.preventDefault()
        const id = e.target.dataset.id

        const stateImpressoras = this.context.stateFilas
        const fila = stateImpressoras.filter(el => el.id == id)

        this.setState({OBJID:id, data:{ items:{id:id, identificador:fila[0].identificador, b_financeiro:fila[0].b_financeiro} }})
        this.setState({modalImpressao: !this.state.modalImpressao})
    }

    //Persistencia de Impressoras
    async handleSubmitImpressoraRede(event) {
        event.preventDefault()
    
        try {
            await saveObj(this.state.data, "Impressoras")
            await this.context.atualizaImpressoras()
            this.setState({modalImpressoraRede: !this.state.modalImpressoraRede})

            Helper.addNotification('success', 'Tudo certo por aqui :)', 'Mudanças feitas foram salvas com sucesso.', 'top-right')
        } catch (e) {
          Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'Não foi possivel salvar as informações enviadas.', 'top-right')
          console.log(e)
        }
    }

    async handleSubmitImpressora(event){
        event.preventDefault()
    
        try {
            await saveObj(this.state.data, "Impressoras")
            await this.context.atualizaImpressoras()
            this.setState({modalImpressora: !this.state.modalImpressora})

            Helper.addNotification('success', 'Tudo certo por aqui :)', 'Mudanças feitas foram salvas com sucesso.', 'top-right')
        } catch (e) {
          Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'Não foi possivel salvar as informações enviadas.', 'top-right')
          console.log(e)
        }
    }

    async deleteImpressora(e){
        e.preventDefault()

        const id = e.target.dataset.id
        await deleteObj('Impressoras/'+id)
        await this.context.atualizaImpressoras()

        Helper.addNotification('success', 'Tudo certo por aqui :)', 'Mudanças feitas foram salvas com sucesso.', 'top-right')
    }

    async addImpressora(e){
        e.preventDefault()

        const device = await navigator.usb.requestDevice({ filters: []})
        const d = { items: {nome:device.productName, serialNumber: device.serialNumber, vendor: device.vendorId} }

        try {
            await saveObj(d, "Impressoras")
            await this.context.atualizaImpressoras()

            Helper.addNotification('success', 'Tudo certo por aqui :)', 'Mudanças feitas foram salvas com sucesso.', 'top-right')
        } catch (e) {
            Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'Não foi possivel salvar as informações enviadas.', 'top-right')
            console.log(e)
        }
    }

    async conectaImpressora(e){
        e.preventDefault()
        const id = e.target.dataset.id
        const stateImpressoras = this.context.stateImpressoras
        const idx = stateImpressoras.findIndex( (item) => item.id == id)
        let device = stateImpressoras[idx].obj

        try {
            if (stateImpressoras[idx].ativo) {
                await device.close()
                stateImpressoras[idx].ativo = false
                stateImpressoras[idx].obj = null
            } else {
                if (!device) device = await window.navigator.serial.requestPort()

                await device.open({ baudRate: 9600 })
                stateImpressoras[idx].ativo = true
                stateImpressoras[idx].obj = device
            }

            this.context.handleImpressoras(stateImpressoras)
            
            Helper.addNotification('success', 'Tudo certo por aqui :)', 'Impressora conectada / desconectada com sucesso.', 'top-right')
        } catch (e) {
            Helper.addNotification('danger', 'Erro de validação :)', 'Erro ao conectar / desconectar com impressora.', 'top-right')
        }
    }

    async testeImpressao(id){
        const valor = await Helper.impressaoTeste("Conheça a GRUB, a plataforma da Cozinha Inteligente. Com a GRUB, os restaurantes têm controle total sobre suas entregas, sem depender de terceiros. Oferecemos um sistema de delivery próprio, onde os estabelecimentos pagam apenas pela entrega, sem taxas sobre o valor do pedido. Cadastre regiões de entrega, utilize seus próprios entregadores e acompanhe tudo pela nossa plataforma. Simplifique suas operações com a GRUB, a Cozinha Inteligente.")
        const str = await render( valor )

        Impressoras.imprime(this.context, id, str)
    }

    //Utilitarios para render

    getImpressoraData(item){
        const impressoras = this.context.stateImpressoras
        const ip = impressoras.filter( i => i.id == item.id_impressora)

        if (ip.length > 0){
            return ip[0].name 
        } else {
            return "Nenhuma selecionada"
        }
    }

    async handlerTesteImpressao(e, tipo = null){
        e.preventDefault()
        const id = e.target.dataset.id

        this.testeImpressao(id, tipo)
    }

    async getImpressorasUSB(){
        await qz.websocket.connect().then(async () => {
            try {
                const ports = await qz.serial.findPorts()
                const arr = []
                ports.forEach( i => {
                    arr.push({label:i, id:i})
                })

                this.setState({ports:arr})
            } catch (e) {
                console.log(e)
            }

        }).then(() => {
            return qz.websocket.disconnect();
        })
    }

    render() {
        const impressoras = this.context.stateImpressoras ? this.context.stateImpressoras : []
        const impressorasDrop = impressoras.map((element) => ({label:element.name+" - "+element.serialNumber, id:element.id}) )
        const filas = this.context.stateFilas ? this.context.stateFilas : []
        const hasFinanceiro = filas.filter( i => i.b_financeiro )

        return (
            <div className='row'>
                <div className={this.props.showSetor === true ? "col-md-6" : "col-md-12"}>
                    <div className="card border-0 bg-light mb-15px" style={{minHeight:"400px"}}>
                        <div className="card-body">
                            <h4 className="card-title">Impressoras</h4>
                            <p className="card-text">Cadastre as impressoras que deseja usar para imprimir os pedidos do seu empreendimento em nossa plataforma. Depois de cadastrá-las, você poderá selecionar os setores de impressão para cada uma, garantindo que os pedidos sejam direcionados corretamente para produção.</p>

                            <div className="table-responsive mb-3" style={{minHeight: "200px"}}>
                                <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>Porta / Host</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {impressoras && impressoras.map((item) => ( 
                                            <tr key={"item_"+item.id}>
                                                <td>
                                                    <span className="btn btn-white" style={{marginRight:"5px"}}>
                                                        <i className='fa fa-print' style={{color:"#0043ff"}}></i>
                                                    </span>
                                                    {item.name}
                                                </td>
                                                <td>{item.host != null ? item.host : item.ports}</td>
                                                
                                                <td>
                                                {this.props.showSetor === true && (
                                                     <UncontrolledButtonDropdown>
                                                        <DropdownToggle caret color="white">Configurações</DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem  data-id={item.id} onClick={(e) => this.handlerTesteImpressao(e)} className="btn btn-default">Testar Impressão</DropdownItem>
                                                            {this.props.showSetor === true && (
                                                                <DropdownItem  data-id={item.id} onClick={(e) => this.deleteImpressora(e)} className="btn btn-default">Excluir</DropdownItem>
                                                            )}
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {this.props.showSetor === true && (
                                <>

                                    <button className='btn btn-link'  onClick={(e) => this.modalImpressoraRede(e)} style={{textDecoration:"none"}}>
                                        <i className='fa fa-print'></i>&nbsp;Adicionar nova impresora REDE
                                    </button>

                                    <button className='btn btn-link'  onClick={(e) => this.modalImpressora(e)} style={{textDecoration:"none"}}>
                                        <i className='fa fa-print'></i>&nbsp;Adicionar nova impresora USB
                                    </button>
                                    
                                </>
                            )}
                        </div>
                    </div>
                </div>

                {this.props.showSetor === true && (
                    <div className="col-md-6">
                        <div className="card border-0 bg-light mb-15px" style={{minHeight:"400px"}}>
                            <div className="card-body">
                                <h4 className="card-title">Setores de impressão</h4>
                                <p className="card-text">Quando um pedido é feito, os itens podem ser impressos em diferentes áreas para produção, como cozinha, bar e chapa. Você asocia o setor a uma categoria, indicando onde devem ser impressos. Todas as outras impressões, que não são para produção, são enviadas para a área Geral.</p>
                            
                                <div className="table-responsive mb-3" style={{minHeight: "200px"}}>
                                    <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                                        <thead>
                                            <tr>
                                                <th>Setor</th>
                                                <th>Id</th>
                                                <th>Impressora</th>
                                                <th>Notas e <br/> Comprovantes</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filas && filas.map((item) => ( 
                                                <tr key={"item_"+item.id}>
                                                    <td>
                                                        <span className="btn btn-white" style={{marginRight:"5px"}}>
                                                            <i className='fa fa-rectangle-list' style={{color:"#0043ff"}}></i>
                                                        </span>
                                                        {item.identificador}
                                                    </td>
                                                    <td>{item.id}</td>
                                                    <td>
                                                        {item.id_impressora != null && impressoras.length > 0 && this.getImpressoraData(item) }
                                                    </td>
                                                    <td>
                                                        {item.b_financeiro ? (
                                                            <div className="badge" style={{color:"green", border:"1px solid green"}}>
                                                                Habilitado
                                                            </div>
                                                        ) : (
                                                            <div className="badge" style={{color:"red", border:"1px solid red"}}>
                                                                Desabilitado
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <UncontrolledButtonDropdown>
                                                            <DropdownToggle caret color="white">Configurações</DropdownToggle>
                                                            <DropdownMenu>
                                                                <DropdownItem data-id={item.id} onClick={(e) => this.deleteItem(e)} className="btn btn-default">Excluir</DropdownItem>
                                                                <DropdownItem data-id={item.id} onClick={(e) => this.editaItem(e)} className="btn btn-default">Editar</DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledButtonDropdown>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <button className='btn btn-link'  onClick={(e) => this.modalImpressao(e)} style={{textDecoration:"none"}}>
                                    <i className='fa fa-rectangle-list'></i>&nbsp;Adicionar setor de impressão
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                <Modal isOpen={this.state.modalImpressao}>
                    <form onSubmit={this.handleSubmit}>
                        <ModalHeader>{this.state.OBJID ? "Editar fila:" : "Crie uma nova fila:"}<b>{this.state.OBJID}</b></ModalHeader>
                        <ModalBody style={{textAlign:"center"}}>
                            
                                <div className="form-floating mb-15px">
                                    <InputText
                                        nome="identificador"
                                        setFomrData={this.setModelData}
                                        dadosIniciais={this.state.data.items.identificador ? this.state.data.items.identificador : null}
                                    />
                                    <Label nome="identificador" label="identificador"/>
                                </div>

                                <div className="form-floating mb-15px">
                                    <DropDown 
                                        nome="id_impressora"
                                        setFomrData={this.setModelData}
                                        options={impressorasDrop}
                                        dadosIniciais={ this.state.data.items.id_impressora ? this.state.data.items.id_impressora : false }
                                        label="Selecione uma impressora:"
                                    />
                                </div>
                                
                                {this.state.data.items.b_financeiro || hasFinanceiro.length === 0 ?
                                <div className="form-floating mb-15px">
                                    <div className="form-check form-switch mb-15px">
                                        <input className="form-check-input form-check-input-pos" checked={this.state.data.items.b_financeiro ? this.state.data.items.b_financeiro : ""} name="b_financeiro" type="checkbox" onChange={this.setFilaFiscal} />
                                        <label className="form-check-label" htmlFor="ativo_cardapio">Fila para emissoes fiscais</label>
                                    </div>
                                </div>
                                : <></>}
                            
                        </ModalBody>
                        <ModalFooter>
                            <button className="btn btn-white" onClick={(e) => this.modalImpressao(e)}>CANCELAR</button>
                            <button type="submit" style={{float: 'right'}} className="btn btn-primary btn-block btn-lg">Salvar</button>
                        </ModalFooter>
                    </form>
				</Modal>

                <Modal isOpen={this.state.modalImpressoraRede}>
                    <form onSubmit={this.handleSubmitImpressoraRede}>
                        <ModalHeader>{this.state.OBJID ? "Editar Impressora:" : "Crie uma nova impressora:"}<b>{this.state.OBJID}</b></ModalHeader>
                        <ModalBody style={{textAlign:"center"}}>

                                <div className="form-floating mb-15px">
                                    <InputText
                                        nome="nome"
                                        setFomrData={this.setModelData}
                                        dadosIniciais={this.state.data.items.nome ? this.state.data.items.nome : null}
                                    />
                                    <Label nome="nome" label="Nome"/>
                                </div>
                            
                                <div className="form-floating mb-15px">
                                    <InputText
                                        nome="host"
                                        setFomrData={this.setModelData}
                                        dadosIniciais={this.state.data.items.host ? this.state.data.items.host : null}
                                    />
                                    <Label nome="host" label="Host"/>
                                </div>
                            
                        </ModalBody>
                        <ModalFooter>
                            <button className="btn btn-white" onClick={(e) => this.modalImpressoraRede(e)}>CANCELAR</button>
                            <button type="submit" style={{float: 'right'}} className="btn btn-primary btn-block btn-lg">Salvar</button>
                        </ModalFooter>
                    </form>
				</Modal>

                <Modal isOpen={this.state.modalImpressora}>
                    <form onSubmit={this.handleSubmitImpressora}>
                        <ModalHeader>{this.state.OBJID ? "Editar Impressora:" : "Crie uma nova impressora:"}<b>{this.state.OBJID}</b></ModalHeader>
                        <ModalBody style={{textAlign:"center"}}>

                                <div className="form-floating mb-15px">
                                    <InputText
                                        nome="nome"
                                        setFomrData={this.setModelData}
                                        dadosIniciais={this.state.data.items.nome ? this.state.data.items.nome : null}
                                    />
                                    <Label nome="nome" label="Nome"/>
                                </div>
                            
                                <div className="form-floating mb-15px">
                                    <DropDown 
                                        nome="ports"
                                        setFomrData={this.setModelData}
                                        options={this.state.ports}
                                        label="Selecione a impressora"
                                    />
                                </div>
                            
                        </ModalBody>
                        <ModalFooter>
                            <button className="btn btn-white" onClick={(e) => this.modalImpressora(e)}>CANCELAR</button>
                            <button type="submit" style={{float: 'right'}} className="btn btn-primary btn-block btn-lg">Salvar</button>
                        </ModalFooter>
                    </form>
				</Modal>
            </div>
        )
    }
}