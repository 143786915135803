import React  from 'react';
import { Link } from 'react-router-dom';

class Paginacao extends React.Component {
  
  paginas(){
    let total = this.props.lista.meta.total / this.props.lista.meta.per_page
    let rows = []
    let pageParam = new URLSearchParams(window.location.search).get('page')
    pageParam = parseInt(pageParam ? pageParam : 1)
    
    for(let i = 0; i <= total; i++) {
      let pageNumber = i+1
     
      rows.push(
        <li key={"key"+i} className={`paginate_button page-item ${pageNumber === pageParam ? 'active' : ''}`}>
          {pageNumber === pageParam ? (
            <span className="page-link">{pageNumber}</span>
          ) : (
            <Link to={'/list/'+this.props.model+'?page='+pageNumber} onClick={this.props.hadlerPagination} data-page={pageNumber} className="page-link">{pageNumber}</Link>
          )}
        </li>
      )
    }

    return ( <> {rows} </> )
  }

  info(){
    let inicial = (this.props.lista.meta.per_page * this.props.lista.meta.current_page) - this.props.lista.meta.per_page
    let final = this.props.lista.meta.per_page * this.props.lista.meta.current_page
    let total = this.props.lista.meta.total
    inicial = inicial === 0 ? 1 : inicial

    return(
      <div className="dataTables_info" style={{fontSize:'12px', fontWeight:'normal'}}>
        Listando {inicial} a {final} de {total} resultados
      </div>
    )
  }

	render() {
    let totalPages = this.props.lista.meta.total / this.props.lista.meta.per_page
    let pageParam = new URLSearchParams(window.location.search).get('page')
    pageParam = parseInt(pageParam ? pageParam : 1)

		return (
      <div className="row" style={{paddingTop: '15px'}}>
        <div className="col-md-4">
         {this.info()}
        </div>
        <div className="col-md-8">
          <div className="dataTables_paginate paging_simple_numbers">
            <ul className="pagination" style={{float: 'right'}}>
              <li key="key_back" className={`paginate_button page-item previous ${1 === pageParam ? 'disabled' : ''}`}>
                <Link to={'/list/'+this.props.model+'?page='+(pageParam-1)} data-page={(pageParam-1)} onClick={this.props.hadlerPagination} className="page-link">Anterior</Link>
              </li>
              { this.paginas() }
              <li key="key_forward" className={`paginate_button page-item next ${pageParam >= Math.ceil(totalPages) ? 'disabled' : ''}`}>
                <Link  to={'/list/'+this.props.model+'?page='+(pageParam+1)} data-page={(pageParam+1)} onClick={this.props.hadlerPagination} className="page-link">Proximo</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
		)

	}
}

export default Paginacao;
