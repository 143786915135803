import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { AppSettings } from './../../config/app-settings.js';
import SidebarProfile from './sidebar-profile.jsx';
import SidebarNav from './sidebar-nav.jsx';

class Sidebar extends React.Component {
	static contextType = AppSettings;
	
	render() {
		return (
			<AppSettings.Consumer>
				{({toggleAppSidebarMobile, appSidebarTransparent, appSidebarGrid}) => (
					<React.Fragment>
						<div id="sidebar"  style={{width:"260px"}} className={'app-sidebar ' + (appSidebarTransparent ? 'app-sidebar-transparent' : '') + (appSidebarGrid ? 'app-sidebar-grid' : '')}>
							<PerfectScrollbar className="app-sidebar-content" options={{suppressScrollX: true}}>
								<div className="menu">
									<SidebarProfile />
								</div>
								<SidebarNav />
							</PerfectScrollbar>
						</div>
						<div className="sidebar-bg"></div>
						<div className="sidebar-mobile-dismiss" onClick={toggleAppSidebarMobile}></div>
					</React.Fragment>
				)}
			</AppSettings.Consumer>
		)
	}
}

export default Sidebar;
