import React from 'react'

import { saveObj, getList, removeProdutoDestaque } from '../../store/api.js'

export default class Multiseletor extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            produtos: [],
            produtosVinculados:[],
            produtosBusca: [],
            produtosVinculadosBusca:[]
        }

        this.setModelData = this.setModelData.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.filtro = this.filtro.bind(this)
        this.clearFiltro = this.clearFiltro.bind(this)
    }

    async fetchData(){
        const produtos = await getList('Produto', {page:0})
        const destaques = await getList('Destaque')

        this.setState({ produtos: produtos })

        if(destaques && destaques.length > 0) {

            const destaque = destaques.filter( i => i.prioridade == this.props.prioridade )
            const prds = []
            
            if(destaque && destaque.length > 0){
                const vinculados = destaque[0].produtos

                produtos.forEach( p => {
                    const exist = vinculados.find( v => v.id === p.id)

                    if (!exist && p.b_delivery) prds.push(p)
                })

                this.setState({ produtos: prds,  produtosVinculados: vinculados})
            }
        }
	}

    filtro(e, vinculado = false){
        e.preventDefault()

        if (e.target && e.target.value.length > 0) {
            if (vinculado){
                this.setState({ produtosVinculadosBusca: this.state.produtosVinculados.filter(i => {return !i.nome.toLowerCase().search(e.target.value.toLowerCase())}) })
            } else {
                this.setState({ produtosBusca: this.state.produtos.filter(i => {return !i.nome.toLowerCase().search(e.target.value.toLowerCase())}) })
            }
        } else {
            this.setState({ produtosBusca: [], produtosVinculadosBusca: []})
        }
    }

    clearFiltro(vinculado = false){
        if (vinculado){
            this.setState({ produtosVinculadosBusca: [] })
        } else {
            this.setState({ produtosBusca: [] })
        }
    }

    async handleDelete(event){
        event.preventDefault()

        await removeProdutoDestaque(event.target.dataset.obj, this.props.prioridade)

        this.fetchData()
    }

    async handleAdd(event){
        event.preventDefault()

        const data = {items:{prioridade:this.props.prioridade, id_produto: event.target.dataset.obj}, relations:{}}
        await saveObj(data, 'destaque/produto')

        this.fetchData()
    }

    async componentDidMount() {
        await this.fetchData()
    }

    setModelData = (name, value) => {
        if (value !== undefined) {
            let data = this.state.data
            data.items[name] = value

            this.setState({ data })
        }
    }

    imagem(item){
		const imagem = item.imagem && item.imagem.imagem && item.imagem.imagem != 0 ? "https://grubupload.s3.us-east-1.amazonaws.com/"+item.imagem.imagem : "https://grubicons.s3.us-east-1.amazonaws.com/foto-padrao-grub-digital.jpg"
		return imagem
	}

    render() {
        const produtos = this.state.produtosBusca.length > 0 ? this.state.produtosBusca : this.state.produtos
        const produtosVinculados = this.state.produtosVinculadosBusca.length > 0 ? this.state.produtosVinculadosBusca : this.state.produtosVinculados

        return (
            <div className='row' style={{minHeight:'400px', position:'relative'}}>
                <div className="col-md-6"  style={{position:'relative'}}>
                    <div className='col-md-12 m-15px'>
                        <p> Escolha os produtos que deseja mostrar:</p>
                    </div>
                    <div style={{height:'350px', position: 'absolute', bottom: '0', width: '90%'}}>
                        <div className="input-group mb-3">
                            <div className="flex-fill position-relative">
                                <div className="input-group">
                                    <div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0" style={{zIndex: 10}}>
                                        <i className="fa fa-search opacity-5"></i>
                                    </div>
                                    <input type="text" style={{margin:'0'}} className="form-control px-35px bg-light" placeholder=""  onChange={(e) => this.filtro(e, false)}/>
                                    <div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 end-0" style={{zIndex: 10, cursor:'pointer'}} onClick={(e) => this.clearFiltro(false)}>
                                        <i className="fa fa-close opacity-5"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="widget-list rounded" style={{height:'250px',overflowY:'scroll'}}>
                            {
                                produtos.length > 0 && produtos.map(i =>  (
                                    <div className="widget-list-item">
                                        <div className="widget-list-media icon">
                                            <div className="w-50px h-50px bg-light d-flex align-items-center justify-content-center" style={{backgroundImage:"url("+this.imagem(i)+")", backgroundSize:"cover", borderRadius:"20%"}}> </div>
                                        </div>
                                        <div className="widget-list-content">
                                            <h4 className="widget-list-title">{i.nome}</h4>
                                        </div>
                                        <div className="widget-list-content">
                                            <h4 className="widget-list-title">{i.valor ? "R$ "+i.valor : "-"}</h4>
                                        </div>
                                        <div className="widget-list-action text-nowrap text-body text-opacity-50 fw-bold text-decoration-none">
                                            <a href="#" data-obj={i.id} onClick={(event) => this.handleAdd(event)} className="border border-primary text-primary px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center" style={{cursor:'pointer'}}>Incluir</a>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className="col-md-6" style={{position:'relative'}}>
                    <div className='col-md-12 m-15px'>
                        <p>Produtos selecionados:</p>
                    </div>
                    <div style={{height:'350px', position: 'absolute', bottom: '0', width: '90%'}}>
                        <div className="input-group mb-3">
                            <div className="flex-fill position-relative">
                                <div className="input-group">
                                    <div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0" style={{zIndex: 10}}>
                                        <i className="fa fa-search opacity-5"></i>
                                    </div>
                                    <input type="text" style={{margin:'0'}} className="form-control px-35px bg-light" placeholder=""  onChange={(e) => this.filtro(e, true)}/>
                                    <div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 end-0" style={{zIndex: 10, cursor:'pointer'}} onClick={(e) => this.clearFiltro(true)}>
                                        <i className="fa fa-close opacity-5"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="widget-list rounded" style={{height:'250px',overflowY:'scroll'}}>
                            {
                                produtosVinculados.length > 0 && produtosVinculados.map(i =>  (
                                    <div className="widget-list-item">
                                        <div className="widget-list-media icon">
                                            <div className="w-50px h-50px bg-light d-flex align-items-center justify-content-center" style={{backgroundImage:"url("+this.imagem(i)+")", backgroundSize:"cover", borderRadius:"20%"}}> </div>
                                        </div>
                                        <div className="widget-list-content">
                                            <h4 className="widget-list-title">{i.nome}</h4>
                                        </div>
                                        <div className="widget-list-content">
                                            <h4 className="widget-list-title">{i.valor ? "R$ "+i.valor : "-"}</h4>
                                        </div>
                                        <div className="widget-list-action text-nowrap text-body text-opacity-50 fw-bold text-decoration-none">
                                            <i data-obj={i.id} onClick={(event) => this.handleDelete(event)} className="fa fa-trash text-body text-opacity-30 fa-lg" style={{cursor:'pointer'}}></i>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}