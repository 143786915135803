import React from 'react';
import { Link } from 'react-router-dom';
import { getList } from './../../store/api.js';

class Gallery extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
			lista:null
		}
	}
	
    componentDidMount() {
		getList('Imagem').then(result =>
			this.setState({ lista: result })
		)
	}

    cradle(item) {
        return (
            <div className={'col-lg-3 col-md-2'}>
                <div className="image w-100">
                    <div className="image-inner" style={{height:"300px"}}>
                        <div style={{height: "100%", width: "100%", backgroundImage: 'url(https://grubupload.s3.us-east-1.amazonaws.com/'+item.imagem+')', backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center", borderRadius:"10px"}}></div>
                    </div>
                </div>
            </div>
        )
    }

	render() {
		return (
			<div>
				<ol className="breadcrumb float-xl-end">
					<li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
					<li className="breadcrumb-item active">Galeria de Fotos</li>
				</ol>
				<h1 className="page-header">Galeria de Fotos</h1>
				
				<div id="gallery" className="gallery row gx-0">
                    {this.state.lista && this.state.lista.data.length > 0 ? (
                        this.state.lista.data.map((item) => 
                            this.cradle(item)
                        )
                    ) : (
                        ""
                    )}
				</div>
			</div>
		)
	}
}

export default Gallery;