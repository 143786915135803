
import React from 'react'

import { saveObj } from '../../store/api.js'

import InputText    from '../_atoms/InputText'
import Label        from '../_atoms/Label'
import Helper       from '../../config/helper'

export default class FormSenha extends React.Component {
    constructor(props) {
        super(props)

        const user = JSON.parse(localStorage.getItem('user')).data
        this.state = {
            user: user,
            data:{},
            dataPDV:{id_loja: user && user.loja.id}
        };

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handlePDVSubmit = this.handlePDVSubmit.bind(this)
        this.setModelData = this.setModelData.bind(this)
        this.setModelDataPDV = this.setModelDataPDV.bind(this)
    }

    async componentDidMount() {
      
    }

    setModelData = (name, value) => {
        if (value !== undefined) {
            let data = this.state.data
            data[name] = value

            this.setState({ data })
        }
    }

    setModelDataPDV = (name, value) => {
        if (value !== undefined) {
            let data = this.state.dataPDV
            data[name] = value

            this.setState({ data })
        }
    }

    async handleSubmit(event) {
        event.preventDefault()
    
        try {
          await saveObj(this.state.data, "trocasenha")
          Helper.addNotification('success', 'Tudo certo por aqui :)', 'Mudanças feitas foram salvas com sucesso.', 'top-right')
        } catch (e) {
          Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'Não foi possivel salvar as informações enviadas.', 'top-right')
        }
    }

    async handlePDVSubmit(event) {
        event.preventDefault()
    
        try {
          await saveObj(this.state.dataPDV, "senhaPDV")
          Helper.addNotification('success', 'Tudo certo por aqui :)', 'Mudanças feitas foram salvas com sucesso.', 'top-right')
        } catch (e) {
            console.log(e)
          Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'Não foi possivel salvar as informações enviadas.', 'top-right')
        }
    }

    render() {
      
        return (
            <>
                <form onSubmit={this.handleSubmit} style={{margin: "0 30px"}}>
                    <h4 className="card-title">Segurança da conta</h4>
                    <p className="card-text">Ao atualizar sua senha regularmente, você protege suas informações pessoais contra possíveis ameaças. Certifique-se de escolher uma senha forte, combinando letras, números e caracteres especiais. Mantenha suas credenciais seguras e desfrute de uma experiência online tranquila.</p>

                    <div className='row'>
                        <div className="col-md-4" style={{float:"left"}}>       
                            <div className="form-floating mb-15px">
                                <InputText
                                    nome="email"
                                    required={true}
                                    setFomrData={this.setModelData}
                                />
                                <Label nome="email" label="Email"/>
                            </div>
                        </div>

                        <div className="col-md-4" style={{float:"left"}}>       
                            <div className="form-floating mb-15px">
                                <InputText
                                    nome="password"
                                    required={true}
                                    setFomrData={this.setModelData}
                                />
                                <Label nome="password" label="Senha atual"/>
                            </div>
                        </div>

                        <div className="col-md-4" style={{float:"right"}}>       
                            <div className="form-floating mb-15px">
                                <InputText
                                    nome="newpassword"
                                    required={true}
                                    setFomrData={this.setModelData}
                                />
                                <Label nome="newpassword" label="Nova senha"/>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-md-2 offset-md-10">
                            <button type="submit" style={{float: 'right'}} className="btn btn-success btn-block btn-lg">Salvar</button>
                        </div>
                    </div>
                </form>

                <form onSubmit={this.handlePDVSubmit} style={{margin: "0 30px"}}>
                    <h4 className="card-title">Segurança do PDV</h4>
                    <p className="card-text"></p>

                    <div className='row'>
                        <div className="col-md-4" style={{float:"right"}}>       
                            <div className="form-floating mb-15px">
                                <InputText
                                    nome="password"
                                    required={true}
                                    setFomrData={this.setModelDataPDV}
                                />
                                <Label nome="password" label="Senha"/>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-md-2 offset-md-10">
                            <button type="submit" style={{float: 'right'}} className="btn btn-success btn-block btn-lg">Salvar</button>
                        </div>
                    </div>
                </form>
            </>
        )
    }
}