import React from 'react'

export default class TextArea extends React.Component {
    constructor(props) {
        super(props)

        this.handleChangeData = this.handleChangeData.bind(this)
    }

    handleChangeData = (e) => {
        e.preventDefault()

        this.props.setFomrData(e.target.name, e.target.value)
    }

    render() {
        return (				
            <textarea
                id={"floatingInput_"+this.props.nome}
                name={this.props.nome}
                className="form-control" 
                onChange={(e) => this.handleChangeData(e)}
                style={{height: "100px"}}
                defaultValue={this.props.dadosIniciais ? this.props.dadosIniciais : ""}
            > 
            </textarea>
        )
    }
}
