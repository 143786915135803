import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { Link } from 'react-router-dom'

import { uploadFile, getList } from '../../store/api.js'
import Helper from '../../config/helper.js'
import Paginacao from '../paginacao/Paginacao.jsx'

export default class InputImage extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
			modalMediaGalery: null,
			modalMediaGaleryOpen:false,
		    imageSelected:null,
			page: null,
			uploadImage:false,
			loadPercent:0
		}

        this.toggleMediaGalery = this.toggleMediaGalery.bind(this)
		this.reloadMediaGalery = this.reloadMediaGalery.bind(this)
        this.handlerPagination = this.handlerPagination.bind(this)
        this.handleImageClick = this.handleImageClick.bind(this)
    }

    async toggleMediaGalery(open) {
		if (open) {
			const content = await getList("Imagem")

			this.setState({ modalMediaGaleryOpen:true, modalMediaGalery: content })
		} else {
			this.setState({ modalMediaGaleryOpen:false, modalMediaGalery: null })
		}
	}

    async reloadMediaGalery(e) {
		this.setState({ uploadImage: true, loadPercent: 10 })
		const formData = {}
		const file = e.target.files[0]
		const reader = new FileReader()
		reader.readAsDataURL(file)

		this.setState({ uploadImage: true, loadPercent: 20 })

		reader.onload = async () => {
			const baseURL = reader.result

            formData.items = {
                nome: file.name.replace(/ /g, '_'),
                imagem: baseURL
            }

			this.setState({ uploadImage: true, loadPercent: 30 })
			await uploadFile(formData)
			this.setState({ uploadImage: true, loadPercent: 90 })

			const content = await getList("Imagem")
			this.setState({ uploadImage: true, loadPercent: 100 })

			this.setState({ modalMediaGalery: content })
			this.setState({ uploadImage: false, loadPercent: 0 })
		}
	}

    handleImageClick = (e) => {
        e.preventDefault()

        if (e.target.dataset.objid) {
            this.props.setFomrData(e.target.dataset.nome, e.target.dataset.objid)
            this.setState({ imageSelected: e.target.dataset.url })
            this.toggleMediaGalery(false)
        }
    }

    async handlerPagination(e){
		e.preventDefault()

		const lista = await getList("Imagem",{page:e.target.dataset.page})

		this.setState({ modalMediaGalery: lista, page:e.target.dataset.page })
	}

    render() {
        const imagem = this.state.imageSelected ? this.state.imageSelected : this.props.dadosIniciais
        const urlImagem = imagem ? "https://grubupload.s3.us-east-1.amazonaws.com/"+imagem : "https://grubicons.s3.us-east-1.amazonaws.com/foto-padrao-grub-digital.jpg"
        
        return (
            <div className="input-group">

                <div
                    className="w-150px h-150px bg-light d-flex align-items-center justify-content-center"
                    style={{position:"relative", backgroundImage:"url("+urlImagem+")", backgroundSize:"cover", borderRadius:"20%"}}>
                
                
                    <button type="button" className="btn btn-primary no-caret" onClick={() => this.toggleMediaGalery(true)} 
                        style={{borderRadius:"50%", position:"absolute", bottom:"-5px", right:"-5px"}}
                    >
                        <i className="fa fa-solid fa-camera text-white"></i>
                    </button>
                </div>

                <input type="hidden" value={this.state.imageSelected ? this.state.imageSelected : this.props.dadosIniciais} className="form-control" required={this.props.required} readOnly />

                {this.state.modalMediaGalery && this.state.modalMediaGalery.data && (
                    <Modal style={{maxWidth:'800px'}} isOpen={this.state.modalMediaGaleryOpen} toggle={() => this.toggleMediaGalery(false)}>
                        <ModalHeader toggle={() => this.toggleMediaGalery(false)}>Galeria de Imagens</ModalHeader>
                        <ModalBody style={{width:'100%', height:'500px', overflowX:'auto'}}>
                            <div className="row mb-15px">
                                <div className="col-md-12 mb-15px">
                                    <input className="form-control" type="file" id="formFile" onChange={(e) => this.reloadMediaGalery(e)}/>
                                </div>
                                {this.state.uploadImage && (
                                    <div className="progress mb-2">
                                        <div className="progress-bar fs-10px fw-bold" style={{width: this.state.loadPercent+'%'}}>
                                            Subindo o arquivo para sua galeria de fotos. Aguarde...
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="row mb-15px">
                                {this.state.modalMediaGalery.data.map((item, i) =>
                                    <div key={i} className="col-2 me-5px" style={{display:"inline-block"}}>
                                        <Link to="/"  className="widget-card rounded square m-b-2">
                                            <div 
                                                data-objid={item.id} 
                                                data-nome={this.props.nome} 
                                                data-url={item.imagem} 
                                                onClick={this.handleImageClick} 
                                                className="widget-card-cover" 
                                                style={{backgroundImage: 'url('+Helper.getS3URL()+item.imagem.replace(/ /g, '%20')+')'}} 
                                            />
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </ModalBody>
                        <ModalHeader>
                            <Paginacao
                                model={this.state.model}
                                lista={this.state.modalMediaGalery}
                                page={this.state.page ? this.state.page : 1 }
                                hadlerPagination={this.handlerPagination} 
                            />
                        </ModalHeader>
                    </Modal>
                )}
            </div>
        )
    }
}