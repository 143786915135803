import React from 'react'

export default class Toggle extends React.Component {
    render() {
        return (
            <div className="form-check form-switch">
                <input className="form-check-input" checked={this.props.on} type="checkbox" onChange={this.props.handler} id={this.props.element_id} />
                <label className="form-check-label" htmlFor={this.props.element_id}>{this.props.text}</label>
            </div>
        )
    }
}