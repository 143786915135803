import React from 'react'

import { Modal, ModalBody } from 'reactstrap'

import { getList, gestaoEntregadorLoja } from '../../store/api.js'

import Lottie from "lottie-react"
import lottiejson from "../../assets/error-grub.json"
import lottieAlerta from "../../assets/cuidado-alerta.json"

class ListaMotoboy extends React.Component {

	constructor(props) {
		super(props)

		const user = JSON.parse(localStorage.getItem('user')).data
		this.state = {
			brand: user.brand ? user.brand : "",
			loja: user.loja,
			model:null,
			lista:[],
			mesa:null,
			modalAlert: false,
			OBJID: null
		}

		this.fetchData = this.fetchData.bind(this)
		this.deleteLoja = this.deleteLoja.bind(this)
		this.addLoja = this.addLoja.bind(this)
	}

	fetchData(){
		if (this.state.model !== this.props.model) {
			getList(this.props.model).then(result => {
				this.setState({ lista: result })
			})

			this.setState({model: this.props.model})
		}
	}

	async deleteLoja(e){
		e.preventDefault()
		const loja = this.state.loja.id

		await gestaoEntregadorLoja({lojaId:loja, entregadorId:this.state.OBJID, acao:"remove"})
		this.setState({ modalAlert: false, OBJID: null })

		getList(this.props.model).then(result => {
			this.setState({ lista: result })
		})
	}

	async addLoja(entregador){
		const loja = this.state.loja.id

		await gestaoEntregadorLoja({lojaId:loja, entregadorId:entregador, acao:"add"})

		getList(this.props.model).then(result => {
			this.setState({ lista: result })
		})
	}

	hasLoja(item){
		return item.lojas.find((i) => i.id == this.state.loja.id)
	}

	componentDidMount() {
		this.fetchData()
	}
	
	formatDate(valor){
		const dt = new Date(valor)
		const dia = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate()

		return dia+"/"+(dt.getMonth()+1)+"/"+dt.getFullYear()
	}

	render() {
		const lista = this.state.lista
		return (
			<div>
				{lista && lista.length > 0 ? (
					<div className="card border-0">
						
						<div className="tab-content p-3">
							
							<div className="tab-pane fade show active" id="allTab">
								
								<div className="table-responsive mb-3">
									<table className="table table-hover table-panel text-nowrap align-middle mb-0">
										<thead>
											<tr>
												<th></th>
												<th>Nome</th>
												<th>Status</th>
												<th>Veículo</th>
												<th>Cadastro desde</th>
												<th>E-mail</th>
												<th>Telefone</th>
												<th>Ações</th>
											</tr>
										</thead>
										<tbody>
											{lista.length > 0 && lista.map((item) => 
												<tr key={"item_"+item.id}>
													<td className="w-10px align-middle">
														<img src='https://grubicons.s3.us-east-1.amazonaws.com/icone-delivery-grub.png' style={{width: '45px', borderRadius: "5px"}} />
													</td>
													<td style={{textTransform:"uppercase", color:"#0043ff", cursor:"pointer"}}>
														<a href={"/detalhe/entregadores/"+item.id}><b>{item.nome}</b></a>
													</td>
													<td>
														<span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center" style={{marginRight:"5px"}}>
															<i className="fa fa-circle fs-9px fa-fw me-5px"></i>
															Ativo
														</span>
													</td>
													<td>
														<span className="px-2 pt-5px pb-5px fs-12px d-inline-flex align-items-center">
															<i className="fa fa-motorcycle fs-12px fa-fw me-5px text-primary"></i>
															Moto
														</span>
													</td>
													<td>
														{this.formatDate(item.created_at)}
													</td>
													<td>
														{item.email}
													</td>
													<td style={{textTransform:"uppercase", color:"#0043ff"}}>{item.telefone}</td>
													<td>
														{this.hasLoja(item) ? (
															<button className="btn btn-outline-primary" onClick={(e) => {e.preventDefault(); this.setState({ modalAlert: true, OBJID: item.id })}}>
																<i className="fa fa-trash fs-9px fa-fw me-5px"></i> Desvincular
															</button>
														):(
															<button className="btn btn-primary" onClick={(e) => {e.preventDefault(); this.addLoja(item.id)}}>
																<i className="fa fa-trash fs-9px fa-fw me-5px"></i> Vincular
															</button>
														)}
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				)
				:
				( 
					<div className="card border-0" style={{padding:"15px"}}>

						<Lottie animationData={lottiejson} loop={true} style={{width: "500px", margin:"auto"}} />
						<h1 style={{textAlign:"center"}}>Nenhum Entregador cadastrado por aqui! Cadastre um agora. </h1>
						<p  style={{textAlign:"center"}}></p>
					</div>
				)
				}

				<Modal isOpen={this.state.modalAlert} toggle={() => this.setState({ modalAlert: false })}>
					<ModalBody>
						<div className='text-center'>
							<Lottie animationData={lottieAlerta} loop={true} style={{width: "250px", margin:"auto"}} />
							<div className="alert mb-15">
								<h3>Atenção: esta ação é crítica e irreversível.</h3>
								<p>Apagar o MotoBoy pode acarretar em grandes transtornos. Após excluir o Motoboy, ele nao pode ser utilizado mais tarde.</p>
							</div>
							
							<button className="btn btn-white" style={{marginRight: '15px'}} onClick={() => this.setState({ modalAlert: false })}>Cancelar</button>
							<button className="btn btn-danger" onClick={(e) => this.deleteLoja(e)}>Sim, desejo eliminar este item</button>
						</div>
					</ModalBody>
				</Modal>
			</div>
		)
	}
}

export default ListaMotoboy;