import React from 'react';
import { Route } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';
import SidebarNavList from './sidebar-nav-list.jsx';
import { getMenu } from './../../store/api.js';


class SidebarNav extends React.Component {
	static contextType = AppSettings;

	constructor(props) {
		super(props);
		this.state = {
			active: -1,
			clicked: -1,
			menu:[]
		}

		this.fetchData = this.fetchData.bind(this)
	}

	fetchData() {
		
		getMenu().then(result => {
			this.setState({ menu: result })
		})
	
	}

	componentDidMount () {
		this.fetchData()
	}

	handleExpand(e, i, match) {
		e.preventDefault();

		if (this.state.clicked === -1 && match) {
			this.setState(state => ({
				active: -1,
				clicked: 1
			}));
		} else {
			this.setState(state => ({
				active: (this.state.active === i ? -1 : i),
				clicked: 1
			}));
		}
	}

	render() {
		return (
			<div className="menu">
				{this.state.menu && this.state.menu.map((menu, i) => (
					<Route path={menu.path} exact={menu.exact} key={i} children={({ match }) => (
						<SidebarNavList
							data={menu}
							key={i}
							expand={(e) => this.handleExpand(e, i, match)}
							active={i === this.state.active}
							clicked={this.state.clicked}
						/>
					)} />
				))}
			</div>
		);
	}
}

export default SidebarNav;
