import React from 'react'
import { Marker, GoogleMap, withScriptjs, withGoogleMap, Polygon } from "react-google-maps"
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { SketchPicker } from 'react-color'

import { saveObj, getList, deleteObj } from '../store/api.js'
import { Link } from 'react-router-dom'

import TopPage      from '../components/_organisms/TopPage'
import InputText    from '../components/_atoms/InputText'
import Label        from '../components/_atoms/Label'
import Helper       from '../config/helper'

const { DrawingManager } = require("react-google-maps/lib/components/drawing/DrawingManager");
const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");


const Map = withScriptjs(withGoogleMap(props =>
    <GoogleMap
        mapContainerStyle={{width: "100%"}}
        center={{ lat: props.lat, lng: props.lng }}
        zoom={13}
        options={{
            streetViewControl: false,
            scaleControl: false,
            mapTypeControl: false,
            zoomControl: false,
            fullScreen: false
        }}
        >
             <Marker
                icon={{url: props.icon,  scaledSize: new window.google.maps.Size(25, 25)}}
                position={{ lat: props.lat, lng: props.lng }}
                defaultImageSizes={5}
            ></Marker>

            {props.zonas && props.zonas.length > 0 && (
                props.zonas.map( (p) => 
                    <Polygon path={p.pontos} options={ {fillColor: p.hex} } />
                )
            )}

            

            <DrawingManager
                setMap={GoogleMap}
                onPolygonComplete={props.onComplete}
                defaultOptions={{
                    drawingControl: true,
                    drawingControlOptions: {
                        style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                        position: window.google.maps.ControlPosition.TOP_CENTER,
                        drawingModes: [window.google.maps.drawing.OverlayType.POLYGON]
                    },
                    polygonOptions: {
                        fillColor: "#199ee0",
                        fillOpacity: 0.2,
                        strokeWeight: 2,
                        strokeColor: "#113460",
                        clickable: true,
                        editable: true,
                        geodesic: false,
                        visible: true,
                        zIndex: 1,
                    }
                }}
            />
         
       
    </GoogleMap>
));



export default class ZonasEmtregas extends React.Component {

    constructor(props) {
		super(props)

		const user = JSON.parse(localStorage.getItem('user')).data
		this.state = {
			user: user,
			brand:user.brand ? user.brand : "",
			model:"ZonasEntregas",
            zonas: null,
            filtro: "nova",
            colorHex: false,
            data:{
                items:{ km: 0, pontos: null },
                relations:{}
              }
		}

        this.fetchData = this.fetchData.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.setModelData = this.setModelData.bind(this)
        this.aplicaFiltro = this.aplicaFiltro.bind(this)
        this.editaItem = this.editaItem.bind(this)
        this.handleSketchChangeComplete = this.handleSketchChangeComplete.bind(this)
        this.setHex = this.setHex.bind(this)
        this.hadleArea = this.hadleArea.bind(this)
	}

	fetchData(){
        getList('ZonasEntregas').then(result => {
            this.setState({ zonas: result.data })

            this.setState( {data:{
                items:{ },
                relations:{}
              }, filtro: "lista"})
        })
	}

    componentDidMount() {
		this.fetchData()
	}

    async deleteItem(e){
		e.preventDefault()

        const id = e.target.dataset.id
     
        await deleteObj('ZonasEntregas/'+id)
        this.fetchData()

        Helper.addNotification('success', 'Tudo certo por aqui :)', 'Mudanças feitas foram salvas com sucesso.', 'top-right')
	}

    async editaItem(e){
        e.preventDefault()

        const id = e.target.dataset.id
        const zona = this.state.zonas.filter( i => i.id == id)

        this.setState( {data:{
            items:{ 
                id: id,
                nome: zona[0].nome,
                valor: zona[0].valor, 
                tempo: zona[0].tempo, 
                km: zona[0].km,
                pontos: zona[0].pontos,
                hex: zona[0].hex  
            },
            relations:{}
          }, filtro: "nova"})
    }

    async handleSubmit(event) {
        event.preventDefault()
    
        try {
          await saveObj(this.state.data, this.state.model)
          this.fetchData()
          Helper.addNotification('success', 'Sucesso', 'Registro excluido com sucesso', 'top-right')
        } catch (e) {
          Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'Não foi possivel salvar as informações enviadas.', 'top-right')
        }
    }

    handleSketchChangeComplete = (color) => {
        this.setModelData('hex',color.hex)
    }

    setHex(e) {
        e.preventDefault()

        this.setState({colorHex: !this.state.colorHex})
    }

    setModelData = (name, value) => {
        if (value !== undefined) {
            let data = this.state.data

            data.items[name] = value

            this.setState({ data })
        }
    }

    aplicaFiltro(e){
		e.preventDefault()
		const t = e.target
        const filtro = t.dataset.filter

		this.setState({filtro: filtro})
	}

    hadleArea(o){
        const data = this.state.data
        const arrPos = []

        o.latLngs.Eg[0].Eg.forEach(i => {
            arrPos.push({lat:i.lat(), lng:i.lng()})
        })

        data.items["pontos"] = JSON.stringify(arrPos)
        this.setState({data})
    }

    render(){
        const atual = this.state.data.items
        const user = this.state.user
        const loja = user ? this.state.user.loja : null
        const zonas = this.state.zonas ? this.state.zonas : []
        //const path = atual && atual.pontos ? atual.pontos : []

        return (
            <div>
                <TopPage titulo={"Áreas de cobertura"}>
                    <p>Cadastre áreas de entrega e regiões excluídas, definindo preços específicos para cada uma. Controle seu alcance e custos de entrega de forma simples e eficaz. Otimize sua logística com a GRUB Delivery.</p>
                </TopPage>
                <div className="tab-content panel rounded-0 rounded-bottom">
                    <div className="row">
                        <div className="col-md-8">
                            {user && (
                                <Map
                                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDT4DEK1lYFBmdqBZZhZeU6Wo1bcE2D4k4&libraries=drawing"
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div style={{ height: `550px` }} />}
                                    mapElement={<div style={{ height: `100%` }} />}
                                    
                                    lat={user && user.endereco && user.endereco.lat ? parseFloat(user.endereco.lat) : 0}
                                    lng={user && user.endereco && user.endereco.lng ? parseFloat(user.endereco.lng) : 0}
                                    icon={loja && loja.imagem ? "https://grubupload.s3.amazonaws.com/"+loja.imagem.imagem : ""}
                                    onComplete={this.hadleArea}
                                    zonas={zonas && zonas.length > 0 ? zonas : null}
                                />
                            )}
                        </div>
                        <div className="col-md-4 p-4">
                            <ul className="nav nav-tabs nav-tabs-v2 mb-3" style={{border:"none"}}>
                                <li key="nova" className="nav-item me-2" >
                                    <Link to="nova" data-filter="nova" onClick={this.aplicaFiltro} className={this.state.filtro === "nova" ? "nav-link px-2 active" : "nav-link px-2"}>
                                        NOVA REGIÃO
                                    </Link>
                                </li>
                                <li key="lista" className="nav-item me-2" >
                                    <Link to="lista" data-filter="lista" onClick={this.aplicaFiltro} className={this.state.filtro === "lista" ? "nav-link px-2 active" : "nav-link px-2"}>
                                        REGIÕES
                                    </Link>
                                </li>
                            </ul>

                            {this.state.filtro === "nova" ? (
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-floating mb-15px">
                                        <InputText
                                            nome="nome"
                                            setFomrData={this.setModelData}
                                            dadosIniciais={atual.nome ? atual.nome : ""}
                                        />
                                        <Label nome="nome" label="Nome da região de entrega:"/>
                                    </div>
                                    <div className="form-floating mb-15px">
                                        <InputText
                                            nome="valor"
                                            setFomrData={this.setModelData}
                                            dadosIniciais={atual.valor ? atual.valor : ""}
                                            decimal={true}
                                        />
                                        <Label nome="valor" label="Valor de entrega:"/>
                                    </div>
                                    <div className="form-floating mb-15px">
                                        <InputText
                                            nome="tempo"
                                            setFomrData={this.setModelData}
                                            dadosIniciais={atual.tempo ? atual.tempo : ""}
                                        />
                                        <Label nome="tempo" label="Tempo previsto para entrega em minutos:"/>
                                    </div>
                                    <div className="form-floating mb-15px">
                                        <InputText
                                            nome="km"
                                            setFomrData={this.setModelData}
                                            dadosIniciais={atual.km ? atual.km : "1000"}
                                        />
                                        <Label nome="km" label="Raio de atuação em metros (1000m = 1Km):"/>
                                    </div>
                                    <div className="input-group mb-15px"  onClick={this.setHex}>
                                        <input type="text" className="form-control bg-white" readOnly value={atual.hex ? atual.hex : "#ff0000"} />
                                        <span className="input-group-text">
                                            <i onClick={this.setHex} style={{width: '16px', height: '16px', display: 'block', background: atual.hex ? atual.hex : "#ff0000"}}></i>
                                            {this.state.colorHex && (
                                                <div className="dropdown-menu" style={{display: "block", right: "50px"}}>
                                                    <SketchPicker color={ atual.hex ? atual.hex : "#ff0000" } onChangeComplete={ this.handleSketchChangeComplete } />
                                                </div>
                                            )}
                                        </span>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-2 offset-md-10">
                                            <button type="submit" style={{float: 'right'}} className="btn btn-success btn-block btn-lg">Salvar</button>
                                        </div>
                                    </div>
                                </form>
                            ):(
                                <div className="table-responsive" style={{height:'80%'}}>
                                    <table className="table table-hover table-panel text-nowrap align-middle mb-0" >
                                        <thead>
                                            <tr>
                                                <th>Região cadastrada</th>
                                                <th></th>
                                                <th>Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.zonas && this.state.zonas.map((item) => ( 
                                                <tr key={"item_"+item.id}>
                                                    <td>
                                                        <span className="btn btn-primary" style={{marginRight:"5px"}}>
                                                            <i className='fa fa-map'></i>
                                                        </span>
                                                       
                                                        {item.nome}
                                                    </td>
                                                    <td> <span> <i style={{width: '16px', height: '16px', display: 'block', background: item.hex ? item.hex : "#ff0000"}}></i></span></td>
                                                    <td>
                                                        <UncontrolledButtonDropdown>
                                                            <DropdownToggle caret color="white">Ação</DropdownToggle>
                                                            <DropdownMenu>
                                                                <DropdownItem data-id={item.id} onClick={(e) => this.editaItem(e)} className="btn btn-default">Editar</DropdownItem>
                                                                <DropdownItem data-id={item.id} onClick={(e) => this.deleteItem(e)} className="btn btn-default">Excluir</DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledButtonDropdown>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
}