import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom';
import ReactHtmlParser from "react-html-parser";
import { AppSettings } from './../../config/app-settings.js';
import Helper from './../../config/helper.js';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';

import { createLogin, getCep, validaBrand } from './../../store/api.js';

export default class RegisterV3 extends React.Component {
	static contextType = AppSettings;

	constructor(props) {
		super(props);

		this.handleSubmit = this.handleSubmit.bind(this);
		this.ran = Math.floor(Math.random() * 36)
		this.domain = Helper.getDominio()

		this.state = {
			endereco: '',
			nome: '',
			sobrenome:'',
			email: '',
			loja:'',
			brand:'',
			brandValida: false,
			logo: '',
			cep: '',
			numero: '',
			password: '',
			termos: false,
			recapcha: false
		}

		this.setImage = this.setImage.bind(this)
		this.onChange = this.onChange.bind(this)
		this.validaURL = this.validaURL.bind(this)
		this.recapcha = this.recapcha.bind(this)
	}

	recapcha() {
		const value = !this.state.recapcha
		this.setState({recapcha:value})
	}

	async validaURL(e){
		const nomeReal = e.target.value.replaceAll(" ", "-").replaceAll(".", "")
		const valida = await validaBrand(nomeReal)
		
		this.setState({ brandValida: valida })
	}

	async onChange(e){
		if (e.target.name === "cep" && e.target.value.length === 8) {
			this.setState({ endereco: await getCep(e.target.value)})
		}

		if (e.target.name === "loja") {
			const nomeReal = e.target.value.toLowerCase().replaceAll(" ", "-").replaceAll(".", "")
		
			this.setState({ brand: nomeReal })
		}

		this.setState({ [e.target.name]: e.target.value })
	}

	setImage(e) {
		const file = e.target.files[0]
		const reader = new FileReader()
		const t = this

		reader.onloadend = function () {
			t.setState({ logo: reader.result })
		}

		reader.readAsDataURL(file);
	}

	componentDidMount() {
		this.context.handleSetAppSidebarNone(true);
		this.context.handleSetAppHeaderNone(true);
		this.context.handleSetAppContentClass('p-0');
	}

	componentWillUnmount() {
		this.context.handleSetAppSidebarNone(false);
		this.context.handleSetAppHeaderNone(false);
		this.context.handleSetAppContentClass('');
	}

	async handleSubmit(event) {
		event.preventDefault();

		const ok = Object.values(this.state).every(o => o !== '');

		if (ok && this.state.recapcha) {
			const result = await createLogin(this.state)

			if (result.data.token && result.data.ativo){
				localStorage.setItem('user', JSON.stringify(result))
				this.props.info.history.push('/login')
			} else {
				Helper.addNotification('danger', 'Danger', result.data, 'top-right');
			}
		} else {
			Helper.addNotification('danger', 'Danger', 'Por favor verifique seus dados', 'top-right');
		}
  	}

	render() {
		const texts = [
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'Plataforma revolucionária que vai mudar a forma como você gerencia o seu restaurante. Conheça o Grub, uma gestão inteligente que vai otimizar o seu tempo e reduzir os custos operacionais do seu negócio.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'Grub é uma plataforma completa e inovadora, que vai facilitar o gerenciamento do seu restaurante, reduzir os custos operacionais e aumentar a lucratividade do seu negócio. Não perca tempo, experimente o Grub e veja os resultados acontecerem.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'O Grub utiliza inteligência artificial para criar postagens e textos para as redes sociais do seu restaurante. Com isso, você economiza tempo e recursos, enquanto mantém uma presença forte e eficiente nas redes sociais.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'Com a GRUB você poderá utilizar mapas de calor para verificar quais são os produtos mais usados pelos seus clientes, o que permitirá otimizar o seu cardápio e aumentar a lucratividade do seu negócio.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'GRUB possui cardápios digitais com QR code que vão agilizar o processo de atendimento e aumentar a satisfação dos seus clientes. Além disso, você poderá cadastrar suas contas a pagar e ter um controle efetivo das entradas e saídas do seu restaurante, tornando mais fácil a tomada de decisão e a gestão financeira do seu negócio.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'Você pode cadastrar suas contas a pagar, tendo um controle de entradas e saídas do seu restaurante. Dessa forma, você consegue ter uma visão mais clara e precisa sobre a saúde financeira do seu negócio.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'Plataforma revolucionária que vai mudar a forma como você gerencia o seu restaurante. Conheça o Grub, uma gestão inteligente que vai otimizar o seu tempo e reduzir os custos operacionais do seu negócio.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'Grub é uma plataforma completa e inovadora, que vai facilitar o gerenciamento do seu restaurante, reduzir os custos operacionais e aumentar a lucratividade do seu negócio. Não perca tempo, experimente o Grub e veja os resultados acontecerem.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'O Grub utiliza inteligência artificial para criar postagens e textos para as redes sociais do seu restaurante. Com isso, você economiza tempo e recursos, enquanto mantém uma presença forte e eficiente nas redes sociais.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'Com a GRUB você poderá utilizar mapas de calor para verificar quais são os produtos mais usados pelos seus clientes, o que permitirá otimizar o seu cardápio e aumentar a lucratividade do seu negócio.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'GRUB possui cardápios digitais com QR code que vão agilizar o processo de atendimento e aumentar a satisfação dos seus clientes. Além disso, você poderá cadastrar suas contas a pagar e ter um controle efetivo das entradas e saídas do seu restaurante, tornando mais fácil a tomada de decisão e a gestão financeira do seu negócio.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'Você pode cadastrar suas contas a pagar, tendo um controle de entradas e saídas do seu restaurante. Dessa forma, você consegue ter uma visão mais clara e precisa sobre a saúde financeira do seu negócio.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'Plataforma revolucionária que vai mudar a forma como você gerencia o seu restaurante. Conheça o Grub, uma gestão inteligente que vai otimizar o seu tempo e reduzir os custos operacionais do seu negócio.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'Grub é uma plataforma completa e inovadora, que vai facilitar o gerenciamento do seu restaurante, reduzir os custos operacionais e aumentar a lucratividade do seu negócio. Não perca tempo, experimente o Grub e veja os resultados acontecerem.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'O Grub utiliza inteligência artificial para criar postagens e textos para as redes sociais do seu restaurante. Com isso, você economiza tempo e recursos, enquanto mantém uma presença forte e eficiente nas redes sociais.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'Com a GRUB você poderá utilizar mapas de calor para verificar quais são os produtos mais usados pelos seus clientes, o que permitirá otimizar o seu cardápio e aumentar a lucratividade do seu negócio.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'GRUB possui cardápios digitais com QR code que vão agilizar o processo de atendimento e aumentar a satisfação dos seus clientes. Além disso, você poderá cadastrar suas contas a pagar e ter um controle efetivo das entradas e saídas do seu restaurante, tornando mais fácil a tomada de decisão e a gestão financeira do seu negócio.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'Você pode cadastrar suas contas a pagar, tendo um controle de entradas e saídas do seu restaurante. Dessa forma, você consegue ter uma visão mais clara e precisa sobre a saúde financeira do seu negócio.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'Plataforma revolucionária que vai mudar a forma como você gerencia o seu restaurante. Conheça o Grub, uma gestão inteligente que vai otimizar o seu tempo e reduzir os custos operacionais do seu negócio.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'Grub é uma plataforma completa e inovadora, que vai facilitar o gerenciamento do seu restaurante, reduzir os custos operacionais e aumentar a lucratividade do seu negócio. Não perca tempo, experimente o Grub e veja os resultados acontecerem.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'O Grub utiliza inteligência artificial para criar postagens e textos para as redes sociais do seu restaurante. Com isso, você economiza tempo e recursos, enquanto mantém uma presença forte e eficiente nas redes sociais.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'Com a GRUB você poderá utilizar mapas de calor para verificar quais são os produtos mais usados pelos seus clientes, o que permitirá otimizar o seu cardápio e aumentar a lucratividade do seu negócio.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'GRUB possui cardápios digitais com QR code que vão agilizar o processo de atendimento e aumentar a satisfação dos seus clientes. Além disso, você poderá cadastrar suas contas a pagar e ter um controle efetivo das entradas e saídas do seu restaurante, tornando mais fácil a tomada de decisão e a gestão financeira do seu negócio.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'Você pode cadastrar suas contas a pagar, tendo um controle de entradas e saídas do seu restaurante. Dessa forma, você consegue ter uma visão mais clara e precisa sobre a saúde financeira do seu negócio.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'Plataforma revolucionária que vai mudar a forma como você gerencia o seu restaurante. Conheça o Grub, uma gestão inteligente que vai otimizar o seu tempo e reduzir os custos operacionais do seu negócio.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'Grub é uma plataforma completa e inovadora, que vai facilitar o gerenciamento do seu restaurante, reduzir os custos operacionais e aumentar a lucratividade do seu negócio. Não perca tempo, experimente o Grub e veja os resultados acontecerem.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'O Grub utiliza inteligência artificial para criar postagens e textos para as redes sociais do seu restaurante. Com isso, você economiza tempo e recursos, enquanto mantém uma presença forte e eficiente nas redes sociais.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'Com a GRUB você poderá utilizar mapas de calor para verificar quais são os produtos mais usados pelos seus clientes, o que permitirá otimizar o seu cardápio e aumentar a lucratividade do seu negócio.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'GRUB possui cardápios digitais com QR code que vão agilizar o processo de atendimento e aumentar a satisfação dos seus clientes. Além disso, você poderá cadastrar suas contas a pagar e ter um controle efetivo das entradas e saídas do seu restaurante, tornando mais fácil a tomada de decisão e a gestão financeira do seu negócio.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'Você pode cadastrar suas contas a pagar, tendo um controle de entradas e saídas do seu restaurante. Dessa forma, você consegue ter uma visão mais clara e precisa sobre a saúde financeira do seu negócio.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'Plataforma revolucionária que vai mudar a forma como você gerencia o seu restaurante. Conheça o Grub, uma gestão inteligente que vai otimizar o seu tempo e reduzir os custos operacionais do seu negócio.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'Grub é uma plataforma completa e inovadora, que vai facilitar o gerenciamento do seu restaurante, reduzir os custos operacionais e aumentar a lucratividade do seu negócio. Não perca tempo, experimente o Grub e veja os resultados acontecerem.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'O Grub utiliza inteligência artificial para criar postagens e textos para as redes sociais do seu restaurante. Com isso, você economiza tempo e recursos, enquanto mantém uma presença forte e eficiente nas redes sociais.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'Com a GRUB você poderá utilizar mapas de calor para verificar quais são os produtos mais usados pelos seus clientes, o que permitirá otimizar o seu cardápio e aumentar a lucratividade do seu negócio.'
			},
			{
				title:'<b>Grub</b>, seu restaurante inteligente.',
				text: 'GRUB possui cardápios digitais com QR code que vão agilizar o processo de atendimento e aumentar a satisfação dos seus clientes. Além disso, você poderá cadastrar suas contas a pagar e ter um controle efetivo das entradas e saídas do seu restaurante, tornando mais fácil a tomada de decisão e a gestão financeira do seu negócio.'
			}
		]
		return (
			<div className="login login-with-news-feed">
				<div className="news-feed">
					<div className="news-image" style={{backgroundImage: 'url(https://grubicons.s3.amazonaws.com/bg' + this.ran +'.jpg)'}}></div>
					<div className="news-caption">
						<h4 className="caption-title">{ReactHtmlParser(texts[this.ran].title)}</h4>
						<p>
							{texts[this.ran].text}
						</p>
					</div>
				</div>
				<div className="login-container">
					<div className="login-header mb-30px">
						<div className="brand">
							<div className="d-flex align-items-center">
								<img alt="" src={`https://grubicons.s3.amazonaws.com/logo.svg`} style={{height:'67px'}} />
							</div>
						</div> 
						<div className="icon">
							<i className="fa fa-sign-in"></i>
						</div>
					</div>
					<div className="login-content">

						<h3>Dados do administrador:</h3>
						<form onSubmit={this.handleSubmit} className="fs-13px">
							<div className="mb-3">
								<label className="mb-2">Nome do administrador da conta: <span className="text-danger">*</span></label>
								<div className="row gx-3">
									<div className="col-md-6 mb-2 mb-md-0">
										<input type="text" className="form-control fs-13px" name="nome" placeholder="Nome" required onChange={this.onChange}/>
									</div>
									<div className="col-md-6">
										<input type="text" className="form-control fs-13px" name="sobrenome" placeholder="Sobre Nome" required onChange={this.onChange}/>
									</div>
								</div>
							</div>

							<div className="mb-3">
								<label className="mb-2">Email <span className="text-danger">*</span></label>
								<input type="text" className="form-control fs-13px" name="email" placeholder="Email" required onChange={this.onChange}/>
							</div>

							<div className="mb-4">
								<label className="mb-2">Senha <span className="text-danger">*</span></label>
								<input type="password" className="form-control fs-13px" name="password" placeholder="Senha" required onChange={this.onChange}/>
							</div>


							<hr/>

							<h3>Informações do seu negócio:</h3>
							<div className="mb-3">
								
								<label className="mb-2">Nome do seu negócio <span className="text-danger">*</span></label>
								<input type="text" className="form-control fs-13px" name="loja" value={this.state.loja} placeholder="Ex: Xis do Garcia" required onBlur={this.validaURL} onChange={this.onChange}/>
								
							</div>
							<div className="mb-3">
								
								<label className="mb-2">URL <span className="text-danger">*</span></label>
								<div className="input-group">
									<input type="text" className={this.state.brandValida ? "form-control fs-13px is-invalid" : "form-control fs-13px is-valid" } value={this.state.brand} placeholder="Ex: xis-do-garcia" readOnly/>
									<span className="input-group-text">.grub.digital</span>

									{this.state.brandValida ? (
										<div className="invalid-feedback">Que pena! Essa url já esta sendo usada por outro estabelecimento. Escolha outra</div>
									) : (
										<div className="valid-feedback">Tudo certo, url disponível para uso :)</div>
									)}
									
								</div>

							</div>
							<div className="mb-3">
								<label className="mb-2">Logo <span className="text-danger">*</span></label>
								<input type="file" accept="image/jpg" className="form-control fs-13px" name="logo" required onChange={this.setImage}/>
							</div>
							<div className="mb-3">
								<label className="mb-2">CEP <span className="text-danger">*</span></label>
								<input type="text" className="form-control fs-13px" name="cep" placeholder="Cep da Loja" required onChange={this.onChange}/>
							</div>

							<div className="mb-3">
								{this.state.endereco && (<p>{this.state.endereco.rua} - {this.state.endereco.bairro}</p>)}
							</div>

							<div className="mb-3">
								<label className="mb-2">Número <span className="text-danger">*</span></label>
								<input name="numero" placeholder="Numero" className="form-control fs-13px" required onChange={this.onChange} />
							</div>

							<div className="mb-3" id="recapcha">
								
							</div>
							
							<div className="form-check mb-4">
								<input className="form-check-input" type="checkbox" name="termos" id="agreementCheckbox" required onChange={this.onChange}/>
								<label className="form-check-label" htmlFor="agreementCheckbox">
									Está de acordo com nossos termos e condições? <Link to="#" onClick={() => this.setState({termos:true})}>Termos</Link>.
								</label>
							</div>
							<div className="mb-4">
								<button type="submit" className="btn btn-primary d-block w-100 btn-lg h-45px fs-13px">CRIAR MEU NEGÓCIO NA GRUB</button>
							</div>
						</form>
						<div className="mb-4 pb-5">
							Ja tem uma conta? Click <Link to="/login">aqui</Link>.
						</div>
						
					</div>
				</div>

				<Modal isOpen={this.state.termos} toggle={() => this.setState({termos:false})}>
					<ModalHeader toggle={() => this.setState({termos:false})} close={<button className="btn-close" onClick={() => this.setState({termos:false})}></button>}>Modal Dialog</ModalHeader>
						<ModalBody>
						<p>Termos e Condições e Política de Privacidade</p>

						<p>Bem-vindo ao GRUB - Seu restaurante inteligente!</p>

						<p>O GRUB é uma plataforma online que conecta usuários a restaurantes e estabelecimentos de alimentação, permitindo a realização de pedidos e reservas de mesas de forma mais eficiente e conveniente. Leia atentamente os Termos e Condições e a Política de Privacidade abaixo antes de utilizar nossos serviços.</p>

						<p>Ao utilizar nossos serviços, você concorda com os Termos e Condições e com a Política de Privacidade do GRUB. Caso você não concorde com estes termos, por favor, não utilize nossos serviços.</p>

						<p>Dados Pessoais</p>
						<p>O GRUB valoriza a privacidade dos usuários e se compromete a proteger suas informações pessoais. Os dados pessoais coletados pelo GRUB serão utilizados exclusivamente para melhorar a experiência do usuário e permitir a utilização dos nossos serviços.</p>
						<p>Os dados pessoais coletados pelo GRUB incluem informações como nome, endereço, e-mail, número de telefone e dados de pagamento. Esses dados são necessários para a realização de pedidos e reservas de mesas, e serão armazenados em um ambiente seguro e protegido.</p>

						<p>O GRUB se compromete a não compartilhar seus dados pessoais com terceiros sem o seu consentimento expresso, exceto nos casos em que a lei exige ou autoriza.</p>

						<p>Ao utilizar os serviços do GRUB, você consente que seus dados pessoais sejam coletados, armazenados e utilizados pelo GRUB para os fins descritos acima.</p>

						<p>Propriedade Intelectual</p>
						<p>Todos os direitos autorais e de propriedade intelectual do GRUB e de seus serviços são de propriedade exclusiva do GRUB. Nenhum conteúdo ou informação disponível no GRUB poderá ser copiado, reproduzido, distribuído, transmitido, exibido, vendido, licenciado ou explorado comercialmente de qualquer outra forma sem a autorização expressa do GRUB.</p>

						<p>Utilização dos Serviços do GRUB</p>
						<p>Ao utilizar os serviços do GRUB, você concorda em:</p>

						<p>a) Fornecer informações precisas, atualizadas e completas ao se cadastrar no GRUB;</p>
						<p>b) Manter a segurança de sua conta e não compartilhar sua senha com terceiros;</p>
						<p>c) Ser responsável por todas as atividades que ocorrerem em sua conta;</p>
						<p>d) Cumprir todas as leis aplicáveis ao utilizar os serviços do GRUB.</p>

						<p>O GRUB se reserva o direito de suspender ou cancelar sua conta caso seja constatado o descumprimento de qualquer uma das cláusulas dos Termos e Condições.</p>

						<p>O GRUB também se reserva o direito de modificar, suspender ou encerrar seus serviços a qualquer momento, sem aviso prévio, por razões técnicas, de segurança ou por qualquer outra razão que considere necessária.</p>

						<p>Responsabilidade</p>
						<p>O GRUB não se responsabiliza por quaisquer prejuízos ou danos decorrentes da utilização dos seus serviços, incluindo perda de lucro, perda de dados ou interrupção de negócios.</p>

						<p>O GRUB também não se responsabiliza por quaisquer problemas relacionados aos restaurantes ou estabelecimentos de alimentação cadastrados na plataforma, incluindo a qualidade dos alimentos e serviços prestados.</p>

						<p>O GRUB não garante que seus serviços serão livres de erros, interrupções, vírus ou outros componentes prejudiciais.</p>

						<p>Disposições Gerais</p>
						<p>Os Termos e Condições e a Política de Privacidade do GRUB constituem o acordo integral entre o usuário e o GRUB, substituindo todos os acordos e entendimentos anteriores ou contemporâneos, verbais ou escritos, em relação ao assunto aqui tratado.</p>

						<p>Caso qualquer cláusula dos Termos e Condições seja considerada inválida ou inexequível, as demais cláusulas permanecerão em pleno vigor e efeito.</p>

						<p>Os Termos e Condições e a Política de Privacidade do GRUB serão regidos e interpretados de acordo com as leis brasileiras, e eventuais disputas serão resolvidas pelo foro da Comarca de São Paulo, com renúncia expressa a qualquer outro, por mais privilegiado que seja.</p>

						<p>Contato</p>
						<p>Em caso de dúvidas, sugestões ou reclamações, entre em contato conosco pelo e-mail contato@grub.digital. Teremos prazer em atendê-lo.</p>
						</ModalBody>
					<ModalFooter>
						<button className="btn btn-white" onClick={() => this.setState({termos:false})}>Close</button>
					</ModalFooter>
				</Modal>
				
				<GoogleReCaptchaProvider reCaptchaKey="6LdtMIEpAAAAAO2m07jS4AkLM-NBI1_Hg8ddJzGx" container={{element:"recapcha"}}>
					<GoogleReCaptcha onVerify={this.recapcha} />
				</GoogleReCaptchaProvider>	
			</div>
		)
	}
}



