import React from 'react'
import { Link } from 'react-router-dom'
import { AppSettings } from '../../config/app-settings.js'
import Moment from 'moment'

import Lottie from "lottie-react"
import lottiejson from "../../assets/pedidos-vazios-cozinha.json"
import lottiewating from "../../assets/94030-pizza-process.json"

import { getItemsToKichen, saveObj } from '../../store/api.js'

class Kitchen extends React.Component {
	static contextType = AppSettings;

	constructor(props) {
		super(props)
		const ls = localStorage.getItem('user')
		
		this.state = {
			login:ls ? JSON.parse(localStorage.getItem('user')).data : null,
			pedidos: [],
			produtosConcluidos:Array(),
			produtosPorPedido:Array(),
			waiting: true
		}

		this.time = '00:00';
	}
  
	handleStartTime() {
		var today = new Date();
		var h = today.getHours();
		var m = today.getMinutes();
		var a;
		m = (m < 10) ? "0" + m : m;
		a = (h > 11) ? 'pm' : 'am';
		h = (h > 12) ? h - 12 : h;
		
		this.time = h + ":" + m + a;
		setTimeout(this.handleStartTime, 500);
	}

	fetchData() {
		setInterval(() => {
			getItemsToKichen().then(result => {
				this.setState({ pedidos: result, waiting:false })
			})
		}, 2000)
	}
  
	componentDidMount() {
		this.context.handleSetAppSidebarNone(true);
		this.context.handleSetAppHeaderNone(true);
		this.context.handleSetAppContentFullHeight(true);
		this.context.handleSetAppContentClass('p-0');
		this.handleStartTime();
		this.fetchData()
	}

	componentWillUnmount() {
		this.context.handleSetAppSidebarNone(false);
		this.context.handleSetAppHeaderNone(false);
		this.context.handleSetAppContentFullHeight(false);
		this.context.handleSetAppContentClass('');
	}

	handleFechaProduto(e, produto){
		e.preventDefault()
		saveObj({pivot:produto.pivot, status: "true"}, "pedido/produto/entregue")
	}

	getProdutosConcluidos(pedido){
		const result = pedido.produtos.filter( p => p.entregue )

		return result
	}
  
	render() {
		return (
			<div className="pos pos-kitchen" id="pos-kitchen">
				<div className="pos-kitchen-header">
					<div className="logo">
						<Link to="/">
							<div className="logo-img"><img alt="" src={this.state.login.imagem_perfil && this.state.login.imagem_perfil && ("https://grubupload.s3.amazonaws.com/"+this.state.login.imagem_perfil.imagem)} /></div>
							<div className="logo-text">{this.state.login.loja.nome}</div>
						</Link>
					</div>
					<div className="time" id="time">{this.time}</div>
				</div>
				<div className="pos-kitchen-body">
					{this.state.waiting && (
						<div className="card border-0" style={{padding:"15px"}}>
							<Lottie animationData={lottiewating} loop={true} style={{width: "880px", margin:"auto"}} />
							<h1 style={{textAlign:"center"}}>Carregando os pedidos do seu ponto de venda. </h1>
							<p  style={{textAlign:"center"}}> Aguarde uns segundos...</p>
						</div>
					)}

					{!this.state.waiting && this.state.pedidos && this.state.pedidos.length ? (
						<div className="pos-task-row">
							{this.state.pedidos.map( (p) => 
								<div key={p.mesa+"_"+p.id} className="pos-task">
									<div className="pos-task-info">
											{p.takeaway === 1 && (
												<div className="table-no">
													Comanda: <b>A{p.comanda}</b>
												</div>
											)}

											{p.delivery === 1 && (
												<div className="table-no">
													Delivery: <b>{p.comanda}</b>
												</div>
											)}

											{p.takeaway === 0 && p.delivery === 0 && (
												<div className="table-no">
													Mesa: <b>{p.mesa}</b>
												</div>
											)}

											{p.b_delivery === 0 && p.b_takeaway === 0 && p.mesa_comanda === 1 && (
												<div className="table-no">
													Comanda: <b>{p.comanda}</b>
												</div>
											)}
										<div className="order-no">
											Pedido: #{p.id}
										</div>
										<div className="order-type">
											<span className="label label-success">Hora do pedido: {Moment(p.criacao).format('h:mm')}</span>
										</div>
										
										<div className="time-pass" data-start-time="3">
											Tempo transcorrido: {Moment().diff(p.criacao, 'minutes')} minutos
										</div>

										{p.takeaway === 1 && (
											<span className="badge border border-primary text-primary px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
												<i className="fa fa-utensils fs-9px fa-fw me-5px"></i> Comanda
											</span>
										)}

										{p.takeaway === 0 && p.delivery === 0 && p.mesa_comanda === 0 && (
											<span className="badge border border-warning text-warning px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
												<i className="fa fa-utensils fs-9px fa-fw me-5px"></i> Mesa
											</span>
										)}
										
										{p.delivery === 1 && (
											<span className="badge border border-indigo text-indigo px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
												<i className="fa fa-truck-fast fs-9px fa-fw me-5px"></i> Delivery 
											</span>
										)}

										{p.b_delivery === 0 && p.b_takeaway === 0 && p.mesa_comanda === 1 &&
											<span className="badge border border-primary text-primary px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
												<i className="fa fa-bag-shopping fs-9px fa-fw me-5px"></i> Comanda
											</span>
										}
									</div>
									<div className="pos-task-body">
										<div className="pos-task-completed">
											Produtos já finalizados e entregues: <b>({this.getProdutosConcluidos(p).length} /{p.produtos.length})</b>
										</div>
										<div className="pos-task-product-row">
											{p.produtos.map( (pp, i) =>
												<div key={"item_"+i} className="pos-task-product completed">
													<div className="pos-task-product-img">
														<div className="cover" style={{backgroundImage: 'url(https://grubupload.s3.amazonaws.com/'+pp.imagem+')'}}></div>
													</div>
													<div className="pos-task-product-info">
														<div className="info">
															<div className="title">{pp.nome}</div>

															{pp.variacao && (
																<div className="row">
																	<hr style={{margin:"10px 0"}} />
																	<b>Variação</b>
																	<div className="desc">
																		{pp.variacao}<br />
																	</div>
																</div>
															)}

															{(pp.opcionais && pp.opcionais.length > 0) || (pp.adicionais && pp.adicionais.length > 0) ? (
																<div className='row'>
																	<hr style={{margin:"10px 0"}}/>
																	<div className='col-md-6'>
																		<b style={{margin:"5px 0"}}>Precisa Retirar</b>
																		<div className="desc" style={{color:"red"}}>
																			{pp.opcionais && pp.opcionais.length > 0 && (
																				pp.opcionais.map(i => (
																					<div>{i.nome}</div>
																				))
																			)}
																		</div>
																	</div>

																	<div className='col-md-6'>
																		<b style={{margin:"5px 0"}}>Precisa Incluir</b>
																		<div className="desc" style={{color:"#0043ff"}}>
																			{pp.adicionais && pp.adicionais.length > 0 && (
																				pp.adicionais.map(i => (
																					<div>+ {i.nome}</div>
																				))
																			)}
																		</div>
																	</div>
																</div>
															) : (<></>)}

															{pp.complemento && (
																<div className="row">
																	<hr style={{margin:"10px 0"}} />
																	<b>Comentário</b>
																	<div className="desc">
																		{pp.complemento}<br />
																	</div>
																</div>
															)}
														</div>
														<div className="qty">
															x{pp.quantidade}
														</div>
													</div>
													<div className="pos-task-product-action">
														{this.state.produtosConcluidos.includes("pedido_"+p.id+"_pos_"+i) || pp.entregue ? 
															<span className="btn btn-success"><i className="fa fa-check"></i></span>
														:
															<Link to="/pos/kitchen-order" onClick={(e) => this.handleFechaProduto(e, pp)} className="btn btn-outline-inverse" >Marcar como entregue</Link>
														}
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
							)}
						</div>
					) : null}
					
					{!this.state.waiting && (!this.state.pedidos || this.state.pedidos.length == 0) ? (
						<div className="card border-0" style={{padding:"15px"}}>
							<Lottie animationData={lottiejson} loop={true} style={{width: "500px", margin:"auto"}} />
							<h1 style={{textAlign:"center"}}>Nenhum pedido por aqui! Aproveite seu dia... </h1>
							<p  style={{textAlign:"center"}}>De momento não tem nenhum pedido no seu estabelecimento.</p>
						</div>
					) : null}
				</div>
			</div>
		)
	}
}

export default Kitchen;