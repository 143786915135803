import React from 'react'
import Helper from './../../config/helper.js'


class Profile extends React.Component {
    constructor(props) {
        super(props)
  
        this.domain = Helper.getDominio()
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    render() {
        return (
			<div></div>
        )
    }
}

export default Profile