
import React from 'react'

import { saveObj } from '../../store/api.js'

import Helper       from '../../config/helper'

export default class FormSenha extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            data:{
              items:{},
              relations:{}
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    async componentDidMount() {
      
    }

    async handleSubmit(event) {
        event.preventDefault()
    
        try {
          await saveObj(this.state.data, this.state.model)
          Helper.addNotification('success', 'Tudo certo por aqui :)', 'Mudanças feitas foram salvas com sucesso.', 'top-right')
        } catch (e) {
          Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'Não foi possivel salvar as informações enviadas.', 'top-right')
        }
    }

    render() {
      
        return (
            <form onSubmit={this.handleSubmit} style={{margin: "0 30px"}}>
                <h4 className="card-title">Plano</h4>
                <p className="card-text">Ao atualizar sua senha regularmente, você protege suas informações pessoais contra possíveis ameaças. Certifique-se de escolher uma senha forte, combinando letras, números e caracteres especiais. Mantenha suas credenciais seguras e desfrute de uma experiência online tranquila.</p>

                <div className='row'>
                    <div className="col-md-2 offset-md-10">
                        <button type="submit" style={{float: 'right'}} className="btn btn-success btn-block btn-lg">Salvar</button>
                    </div>
                </div>
            </form>
        )
    }
}