import React from 'react'

import InputText    from '../_atoms/InputTextMask'
import Label        from '../_atoms/Label'
import Toggle        from '../_atoms/Toggle'

export default class DiasFuncionamento extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            showDays:false,
			diasFuncionamento: 0,
            showToggle:true
		}

        this.handleOnChangeDiasFuncionamento = this.handleOnChangeDiasFuncionamento.bind(this)
        this.handlerDisplay = this.handlerDisplay.bind(this)
    }

    componentDidUpdate () {
        if (!this.state.hasInitial) {
            const atual = this.props.dadosIniciais
            const showDays = this.props.showDays

            if(!showDays && atual && atual.dias) {
                this.setState({diasFuncionamento: atual.dias})
                this.setState({showDays: true})
                this.setState({hasInitial:true})
            }

            if (showDays){
                this.setState({hasInitial:true, showDays: true, showToggle:false})
            }
        }
      }

    async handleOnChangeDiasFuncionamento(e){
		const diasFuncionamento = parseInt(this.state.diasFuncionamento)
        let dias = null

		if (e.target.checked) {
			dias = diasFuncionamento + parseInt(e.target.value)
		} else {
			dias = diasFuncionamento - parseInt(e.target.value)
		}

        this.setState({diasFuncionamento: dias})
        this.props.setFomrData(this.props.nome, dias)
	}

    handlerDisplay() {
        this.setState({showDays: !this.state.showDays})
        this.setState({diasFuncionamento: this.props.dadosIniciais.dias ? this.props.dadosIniciais.dias : 0})
    }


    render() {
        const atual = this.props.dadosIniciais

        const dias = this.state.diasFuncionamento
        const binario = '0x'+(dias >>> 0).toString(2).padStart(7, 0)

        const data = {
            dom:!!('0x'+(1 >>> 0).toString(2).padStart(7, 0) & binario),
            seg:!!('0x'+(2 >>> 0).toString(2).padStart(7, 0) & binario),
            ter:!!('0x'+(4 >>> 0).toString(2).padStart(7, 0) & binario),
            qua:!!('0x'+(8 >>> 0).toString(2).padStart(7, 0) & binario),
            qui:!!('0x'+(16 >>> 0).toString(2).padStart(7, 0) & binario),
            sex:!!('0x'+(32 >>> 0).toString(2).padStart(7, 0) & binario),
            sab:!!('0x'+(64 >>> 0).toString(2).padStart(7, 0) & binario)
		}

        return (
            <>
                {this.state.showToggle && (
                    <div className='row' style={{padding:'0 10px', margin:"10px 0"}}>
                        <Toggle 
                            handler={this.handlerDisplay}
                            text={this.props.labelSeletor}
                            id="ativa_dias"
                            on={atual? atual.dias : false}
                        />
                    </div>
                )}
                {this.state.showDays && (
                    <>
                        <div className='row'>
                            <div style={{padding:'0 10px 10px 10px'}}>
                                <div className="form-check" style={{display:"inline-block", marginRight:"5px", width:'60px'}}>
                                    <input name="dom" checked={data.dom} value="1" className="form-check-input" type="checkbox" onChange={this.handleOnChangeDiasFuncionamento} id="checkbox1" />
                                    <label className="form-check-label" htmlFor="checkbox1">DOM</label>
                                </div>
                                <div className="form-check" style={{display:"inline-block", marginRight:"5px", width:'60px'}}>
                                    <input name="seg" checked={data.seg} value="2" className="form-check-input" type="checkbox" onChange={this.handleOnChangeDiasFuncionamento} id="checkbox2" />
                                    <label className="form-check-label" htmlFor="checkbox2">SEG</label>
                                </div>
                                <div className="form-check" style={{display:"inline-block", marginRight:"5px", width:'60px'}}>
                                    <input name="ter" checked={data.ter} value="4" className="form-check-input" type="checkbox" onChange={this.handleOnChangeDiasFuncionamento} id="checkbox3" />
                                    <label className="form-check-label" htmlFor="checkbox3">TER</label>
                                </div>
                                <div className="form-check" style={{display:"inline-block", marginRight:"5px", width:'60px'}}>
                                    <input name="qua" checked={data.qua} value="8" className="form-check-input" type="checkbox" onChange={this.handleOnChangeDiasFuncionamento} id="checkbox4" />
                                    <label className="form-check-label" htmlFor="checkbox4">QUA</label>
                                </div>
                                <div className="form-check" style={{display:"inline-block", marginRight:"5px", width:'60px'}}>
                                    <input name="qui" checked={data.qui} value="16" className="form-check-input" type="checkbox" onChange={this.handleOnChangeDiasFuncionamento} id="checkbox5" />
                                    <label className="form-check-label" htmlFor="checkbox5">QUI</label>
                                </div>
                                <div className="form-check" style={{display:"inline-block", marginRight:"5px", width:'60px'}}>
                                    <input name="sex" checked={data.sex} value="32" className="form-check-input" type="checkbox" onChange={this.handleOnChangeDiasFuncionamento} id="checkbox6" />
                                    <label className="form-check-label" htmlFor="checkbox6">SEX</label>
                                </div>
                                <div className="form-check" style={{display:"inline-block", marginRight:"5px", width:'60px'}}>
                                    <input name="sab" value="64" checked={data.sab} className="form-check-input" type="checkbox" onChange={this.handleOnChangeDiasFuncionamento} id="checkbox7" />
                                    <label className="form-check-label" htmlFor="checkbox7">SAB</label>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-6'>
                                <div className="form-floating mb-15px">
                                    <InputText
                                        nome="horario_inicial"
                                        setFomrData={this.props.setFomrData}
                                        mask={"99:99"}
                                        dadosIniciais={atual? atual.horario_inicial : ""}
                                    />
                                    <Label nome="horario_inicial" label="Horario Inicio"/>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-floating mb-15px">
                                    <InputText
                                            nome="horario_final"
                                            setFomrData={this.props.setFomrData}
                                            mask={"99:99"}
                                            dadosIniciais={atual? atual.horario_final : ""}
                                        />
                                    <Label nome="horario_final" label="Horario Fim"/>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </>
        )
    }
}