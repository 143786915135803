import React from 'react'
import { Link } from 'react-router-dom'

import { getItem } from './../../store/api.js'
import TopPage from '../../components/_organisms/TopPage'

import DateRangePicker from 'react-bootstrap-daterangepicker'
import Moment from 'moment'

import Offcanvas from 'react-bootstrap/Offcanvas'

import Detalhe from './../../pages/detalhe.js'

import Lottie from "lottie-react"
import lottiejson from "../../assets/error-grub.json"

export default class Banco extends React.Component {
    constructor(props) {
		super(props)
        const lsUser = localStorage.getItem('user')
		const user = lsUser ? JSON.parse(localStorage.getItem('user')).data : null

        this.state = {
            user: user,
			brand:user ? user.brand : null,
            detalheId:null,
            showCanvas:false,
            dateRange: null,
            entregador:null,
            tabs: [{chave: 'historico', valor: 'HISTÓRICO DE ENTREGAS'}],
            filtro: 'extrato',
            filtrodt:{dtInicio: Moment(new Date().setDate(new Date().getDate() - 30)), dtFim: Moment()},
            filterDates: "filtro3",
            list:[]
		}
		
        this.fetchData = this.fetchData.bind(this)
        this.handleDateApplyEvent = this.handleDateApplyEvent.bind(this)
        this.aplicaFiltro = this.aplicaFiltro.bind(this)
	}

	async fetchData(startDate, endDate, aba = 'asaas/extrato'){
        const entregador = await getItem("Motoboys", this.props.info.match.params.id)

        this.setState({entregador:entregador.data, list:entregador.data.entregas})
	}

    async aplicaFiltro(e){
		e.preventDefault()

		const t = e.target
		const id = t.dataset.filter 
        let aba = 'asaas/extrato'
        const startDate = this.state.filtrodt.dtInicio
        const endDate = this.state.filtrodt.dtFim

        this.setState({list:[],filtro: id})

        if(id === 'recebiveis') aba = 'asaas/consultaCobranca'
        if(id === 'transferencia') aba = 'asaas/tranferencia'

        await this.fetchData(startDate, endDate, aba)
	}
    

    componentDidMount() {
        const startDate = Moment(new Date().setDate(new Date().getDate() - 30))
        const endDate = Moment()
		const gap = endDate.diff(startDate, 'days')
		
		const currentWeek = startDate.format('D MMMM YYYY') + ' - ' + endDate.format('D MMMM YYYY')
		const prevWeek = Moment(startDate).subtract('days', gap).format('D MMMM') + ' - ' + Moment(startDate).subtract('days', 1).format('D MMMM YYYY')
		
		this.setState({dateRange: {
			currentWeek: currentWeek,
			prevWeek: prevWeek,
			gap:gap
		}, filtrodt:{dtInicio: startDate, dtFim: endDate }})

		this.fetchData(startDate, endDate)
	}

    formatDate(date){
        const dt = new Date(date)

        return dt.getDate()+"/"+(dt.getMonth()+1)+"/"+dt.getFullYear()+" "+dt.getHours()+":"+dt.getMinutes()
    }

    handleDateApplyEvent(e, picker) {
		var startDate = picker.startDate
		var endDate = picker.endDate
		var gap = endDate.diff(startDate, 'days')
		
		var currentWeek = startDate.format('D MMMM YYYY') + ' - ' + endDate.format('D MMMM YYYY')
		var prevWeek = Moment(startDate).subtract('days', gap).format('D MMMM') + ' - ' + Moment(startDate).subtract('days', 1).format('D MMMM YYYY')
		
		this.setState({dateRange: {
			currentWeek: currentWeek,
			prevWeek: prevWeek,
			gap:gap
		}})

		this.fetchData(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
	}

    hadleFilterDate(e, filter){
		e.preventDefault()

		const today = Moment()
		let priorDate = Moment(new Date().setDate(new Date().getDate() - 7))

		this.setState({filterDates: filter})

		if (filter === "filtro2") priorDate = Moment(new Date().setDate(new Date().getDate() - 15))
		if (filter === "filtro3") priorDate = Moment(new Date().setDate(new Date().getDate() - 30))

        this.setState({filtrodt:{dtInicio: priorDate, dtFim: today}})

        this.handleDateApplyEvent(null, {startDate:priorDate, endDate:today})
	}

    handleDetalhe(e, item){
		e.preventDefault()
		this.setState({detalheId:item.comanda, showCanvas:!this.state.showCanvas})
	}

    render(){
        var initialDatePicker = {
            startDate: Moment().subtract(7, 'days'),
            endDate: Moment(),
            ranges: {
              'Hoje': [Moment().toDate(), Moment().toDate()],
              'Ontem': [
                Moment().subtract(1, 'days').toDate(),
                Moment().subtract(1, 'days').toDate(),
              ],
              'Últimos 7 dias': [
                Moment().subtract(6, 'days').toDate(),
                Moment().toDate(),
              ],
              'Últimos 30 Dias': [
                Moment().subtract(29, 'days').toDate(),
                Moment().toDate(),
              ],
              'Mês atual': [
                Moment().startOf('month').toDate(),
                Moment().endOf('month').toDate(),
              ],
              'Mês anterior': [
                Moment().subtract(1, 'month').startOf('month').toDate(),
                Moment().subtract(1, 'month').endOf('month').toDate(),
              ],
            },
        }

        return (
			<div>
                <TopPage titulo="Extrato de entregas"></TopPage>

                <div className='row mb-3'>
                    <div className='col-12'>
                        <div className="btn-group"  style={{float:"left"}}>
                            <button className={this.state.filterDates === "filtro1" ? "btn btn-white active" : "btn btn-white"} onClick={(e) => this.hadleFilterDate(e, "filtro1")}>Últimos 7 dias</button>
                            <button className={this.state.filterDates === "filtro2" ? "btn btn-white active" : "btn btn-white"} onClick={(e) => this.hadleFilterDate(e, "filtro2")}>Últimos 15 dias</button>
                            <button className={this.state.filterDates === "filtro3" ? "btn btn-white active" : "btn btn-white"} onClick={(e) => this.hadleFilterDate(e, "filtro3")}>Últimos 30 dias</button>
                        </div>

                        <div className="d-sm-flex align-items-center" style={{float:"right"}}>
                            <DateRangePicker initialSettings={initialDatePicker} onApply={this.handleDateApplyEvent}>
                                <button className="btn btn-white me-2 text-truncate">
                                    <i className="fa fa-calendar fa-fw text-opacity-50 ms-n1 me-1"></i> 
                                    <span>{this.state.dateRange && (this.state.dateRange.currentWeek)}</span>
                                    <b className="caret ms-1 opacity-5"></b>
                                </button>
                            </DateRangePicker>
                        </div>
                    </div>
                </div>
                
                {this.state.list && this.state.list.length > 0 && (
                    <div className="row">
                        <div className="col-xl-3 col-md-6">
                            <div className="widget widget-stats ">
                                <div className="stats-content">
                                    <div className="stats-title">Entregas do periodo</div>
                                    <div class="stats-number text-primary"> {this.state.list.length} </div>
                                    <div className="stats-desc mb-1">Total de entregas realizadas pelo entregador no periodo selecionado.</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-md-6">
                            <div className="widget widget-stats ">
                                <div className="stats-content">
                                    <div className="stats-title">Distância do periodo</div>
                                    <div class="stats-number text-warning">{this.state.list.reduce( (a,i) => parseInt(a) + parseInt(i.distancia), 0) / 10000}km </div>
                                    <div className="stats-desc mb-1">Distância percorrida aproximada nas entregas realizadas no periodo selecionado.</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-md-6">
                            <div className="widget widget-stats ">
                                <div className="stats-content">
                                    <div className="stats-title">Valor de entregas do periodo</div>
                                    <div class="stats-number text-success">{this.state.list.reduce( (a,i) => parseFloat(a) + parseFloat(i.zona_valor), 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</div>
                                    <div className="stats-desc mb-1">Total consolidado das entregas realizadas no período selecionado.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="card border-0">
                    <ul className="nav nav-tabs nav-tabs-v2 px-3">
                        {this.state.tabs.map( (item) =>
                            <li key={item.chave} className="nav-item me-2" >
                                <Link to={item.chave} data-filter={item.chave} onClick={this.aplicaFiltro} className={this.state.filtro === item.chave ? "nav-link px-2 active" : "nav-link px-2"}>
                                    {item.valor}
                                </Link>
                            </li>
                        )}
                    </ul>

                    <div className="card-body">
                        <div className="table-responsive mb-3">
                            {this.state.list && this.state.list.length > 0 ? (
                              
                                <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                                    <thead>
                                        <tr>
                                            <th style={{textAlign:"center"}}><b></b></th>
                                            <th style={{textAlign:"center"}}><b>Entregador</b></th>
                                            <th style={{textAlign:"center"}}><b>Pedido</b></th>
                                            <th style={{textAlign:"center"}}><b>Data</b></th>
                                            <th style={{textAlign:"center"}}><b>Distância</b></th>
                                            <th style={{textAlign:"center"}}><b>Valor de entrega</b></th>
                                            <th style={{textAlign:"center"}}><b>Repasse</b></th>
                                            <th style={{textAlign:"center"}}><b></b></th>
                                        </tr>
                                    </thead>
                                    <tbody>       
                                        {this.state.list && this.state.list.length > 0 && this.state.list.map((item, i, arr) => 
                                            <tr>
                                                <td className="w-10px align-middle">
                                                    <img src='https://grubicons.s3.us-east-1.amazonaws.com/icone-delivery-grub.png' style={{width: '45px', borderRadius: "5px"}} />
                                                </td>
                                                <td style={{textAlign:"center", textTransform:"uppercase", color:"#0043ff", cursor:"pointer"}}>
                                                    {this.state.entregador.nome}
                                                </td>
                                                <td style={{textAlign:"center", color:"#0043ff"}}>
                                                    <Link to="#" onClick={(e) => this.handleDetalhe(e, item)} className="fw-bold">
                                                        <b>#{item.comanda}</b>
                                                    </Link>
                                                </td>
                                                <td style={{textAlign:"center"}}>{this.formatDate(item.updated_at)}</td>
                                                <td className="text-warning" style={{textAlign:"center"}}><b>{(item.distancia/10000)+"Km"}</b></td>
                                                <td className="text-success" style={{textAlign:"center"}}><b>{parseFloat(item.zona_valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></td>
                                                <td style={{textAlign:"center"}}>
                                                    <span className="badge border border-warning text-warning px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center" style={{marginRight:"5px"}}>
                                                        <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                                        Pendente
                                                    </span>
                                                </td>
                                                <td style={{textAlign:"center"}}></td>       
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                    
                            ) : (
                                <div className="card border-0" style={{padding:"15px"}}>

                                    <Lottie animationData={lottiejson} loop={true} style={{width: "500px", margin:"auto"}} />
                                    <h3 style={{textAlign:"center"}}>Nenhuma transação encontrada no periodo selecionado. </h3>
								
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <Offcanvas show={this.state.showCanvas} onHide={() => this.setState({showCanvas: false})}  placement="end" style={{width:"40%"}}>
					<Offcanvas.Header closeButton>
						<Offcanvas.Title>
							<h3>Pedido: #{this.state.detalheId}</h3>
						</Offcanvas.Title>
					</Offcanvas.Header>
					<Offcanvas.Body>
						<Detalhe id={this.state.detalheId}/>
					</Offcanvas.Body>
				</Offcanvas>

            </div>
        )
    }

}