import React from 'react';
import { Route, Link } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';

class SidebarNavList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			active: -1,
			clicked: -1
		};
	}

	handleExpand(e, i, match) {
		e.preventDefault();

		this.setState(state => ({
			active: (this.state.active === i ? -1 : i),
			clicked: 1
		}));
	}
  
	render() {
		var icon = this.props.data.icon && <div className="menu-icon" style={{color:"#41597cb5"}}><i className={this.props.data.icon}></i></div>
		var title = this.props.data.title && <div className="menu-text" style={this.props.data.children ? {color:"#8d8d8d"} : {color: "#1f2835"} }>{this.props.data.title}</div>
		
		return (
			<AppSettings.Consumer>
				{() => (
					<Route path={this.props.data.path} exact={this.props.data.exact} children={({ match }) => (
						<div className="menu-item has-sub">
							{this.props.data.children ? (
								<div className="menu-link">
										{ title }
								</div>
							):(
								<Link to={this.props.data.path} className="menu-link"  >
									{ icon } { title }
									{!this.props.data.path && (
										<span className="badge bg-primary">EM BREVE</span>
									)}
								</Link>
							)}
							{this.props.data.children && (
								<div className="menu-submenu d-block" style={{paddingLeft:"0"}}>
									{this.props.data.children && this.props.data.children.map((submenu, i) => (
										<SidebarNavList
											data={submenu} 
											key={i} 
											active={i === this.state.active}
											clicked={this.state.clicked}
										/>
									))}
								</div>
							)}
						</div>
					)} />
				)}
			</AppSettings.Consumer>
		);
	}
}

export default SidebarNavList;