import Dashboard      from './../pages/Dashboard.js'
import LoginV3        from './../pages/user/login.js'
import RegisterV3     from './../pages/user/register.js'
import EditarNegocio  from './../pages/modulo/editarNegocio.js'
import EditarProduto  from './../pages/modulo/editarProduto.js'
import Profile        from './../pages/modulo/profile.js'
import ListarModulo   from './../pages/modulo/listar.js'
import MediaGalery    from './../pages/modulo/midagalery.js'
import Landing        from './../pages/modulo/landing.js'
import Loja           from './../pages/pos/loja.js'
import Kitchen        from './../pages/pos/kitchen.js'
import Detalhe        from './../pages/detalhe.js'
import DetalheMotoboy from './../pages/detalhes/Motoboy.js'
import ZonasEntregas  from './../pages/zonasEntrega.js'
import DeliveryPedidos from './../pages/deliveryPedidos.js' 
import Metas           from './../pages/metas.js'
import Clientes        from './../pages/modulo/listar.js'
import Banco           from './../pages/banco.js'
import CardapioCampeao from './../pages/cardapioCampeao.js'

const routes = [
  {
    path: '/login',
    title: 'Login',
    component: (info) => <LoginV3 info={info} />,
  },
  {
    path: '/registro',
    title: 'Registro',
    component: (info) => <RegisterV3 info={info} />,
  },
  {
    path: '/',
    exact: true,
    title: 'dashboard',
    component: (info) => <Dashboard  info={info}/>
  },
  {
    path: '/dashboard',
    title: 'dashboard',
    component: (info) => <Dashboard  info={info}/>
  },
  {
    path: '/meu-perfil',
    title: 'Meu Perfil',
    component: (info) => <Profile  info={info}/>,
  },
  {
    path: '/cardapio/edit/:model/:id?',
    title: 'Editar',
    component: (info) => <EditarProduto info={info}/>,
  },
  {
    path: '/negocio/edit/:model',
    title: 'Editar',
    component: (info) => <EditarNegocio info={info}/>,
  },
  {
    path: '/delivery/ZonasEntregas',
    title: 'Editar',
    component: (info) => <ZonasEntregas info={info}/>,
  },
  {
    path: '/delivery/Pedidos',
    title: 'Editar',
    component: (info) => <DeliveryPedidos info={info}/>,
  },
  {
    path: '/list/:model',
    title: 'Listar modulo',
    component: (info) => <ListarModulo info={info} />,
  },
  {
    path: '/media-galery',
    title: 'lista de fotos',
    component: (info) => <MediaGalery info={info}/>,
  },
  {
    path: '/landing',
    title: 'Criacao da landing',
    component: (info) => <Landing info={info}/>,
  },
  {
    path: '/pos/loja',
    title: 'POS - loja',
    component: (info) => <Loja info={info}/>,
  },
  {
    path: '/pos/kitchen',
    title: 'POS - kitchen',
    component: (info) => <Kitchen info={info}/>,
  },
  {
    path: '/detalhe/comanda/:id',
    title: 'Detalhe - Comanda',
    component: (info) => <Detalhe info={info}/>,
  },
  {
    path: '/detalhe/entregadores/:id',
    title: 'Detalhe - Entregador',
    component: (info) => <DetalheMotoboy info={info}/>,
  },
  {
    path: '/metas-resultados',
    title: 'Metas e Resultados',
    component: (info) => <Metas info={info}/>,
  },
  {
    path: '/marketing/clientes',
    title: 'Meus Clientes',
    component: (info) => <Clientes info={info}/>,
  },
  {
    path: '/meudinheiro',
    title: 'Meu Dinheiro',
    component: (info) => <Banco info={info}/>,
  },{
    path: '/cardapio_campeao',
    title: 'Cardápio Campeão',
    component: (info) => <CardapioCampeao info={info}/>,
  }
]

export default routes
