import React from 'react'
import { Link } from 'react-router-dom'

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { uploadFile, getList, saveObj, deleteObj, getItem } from './../../store/api.js'
import Paginacao from '../../components/paginacao/Paginacao.jsx'
import Helper from './../../config/helper.js'
import TopPage from '../../components/_organisms/TopPage'

class Landing extends React.Component {
    constructor(props) {
        super(props)

        const user = JSON.parse(localStorage.getItem('user')).data
		this.state = {
			user: user,
			brand:user.brand ? user.brand : "",
            brandData: user.loja,
            imagem: user.imagem_perfil ? user.imagem_perfil.imagem : "",
            modalMediaGalery: null,
			modalMediaGaleryOpen:false,
            uploadImage:false,
            loadPercent:0,
            posicao:0,
            ativado: user.loja? user.loja.b_destaques : false,
            positionMouseOver: null,
            banners:[{imagem:null},{imagem:null},{imagem:null},{imagem:null},{imagem:null},{imagem:null},{imagem:null},{imagem:null}]
		}

        this.toggleMediaGalery = this.toggleMediaGalery.bind(this)
		this.reloadMediaGalery = this.reloadMediaGalery.bind(this)
        this.handleImageClick = this.handleImageClick.bind(this)
        this.handleOnChange = this.handleOnChange.bind(this)
        this.handlerMouseOver = this.handlerMouseOver.bind(this)
        this.resetPosition = this.resetPosition.bind(this)
        this.deleteImage = this.deleteImage.bind(this)
    }

    async handleImageClick(e) {
        e.preventDefault()
        const id = e.target.dataset.objid
        if (id) {
            const banners = this.state.banners

            for( let i = 0; i < banners.length; i++ ) {
                if(parseInt(this.state.posicao) === i) { 
                    
                    const obj = await saveObj({items:{
                        id_gb_imagem:id,
                        posicao:i,
                        b_ativo:1
                    }}, 'Banner')

                    banners[i] = obj.data

                    const img = await getItem('Imagem', id)
                    banners[i].imagem = img.data

                    Helper.addNotification('success', 'Tudo certo por aqui :)', 'Mudanças feitas foram salvas com sucesso.', 'top-right')
                }
            }

            console.log(banners)
    
            this.setState({ banners: banners })
            this.toggleMediaGalery(false)
    
        }
    }

    async deleteImage(e){
        e.preventDefault()
        const obj = e.target
        const id = obj.dataset.id
        const pos = obj.dataset.pos
        const banners = this.state.banners

        await deleteObj('Banner/'+id)
        Helper.addNotification('success', 'Tudo certo por aqui :)', 'Mudanças feitas foram salvas com sucesso.', 'top-right')

        for( let i = 0; i < banners.length; i++ ) {
            if(parseInt(pos) === i) banners[i] = null
        }

        this.setState({ banners: banners })
    }

    async handleOnChange(e){
        e.preventDefault()

        const controle = !this.state.ativado

        await saveObj({items:{
            id:this.state.brandData.id,
            b_destaques: controle
        }}, 'Loja')

        this.setState({ativado: controle})

        Helper.addNotification('success', 'Tudo certo por aqui :)', 'Mudanças feitas foram salvas com sucesso.', 'top-right')
    }

    componentDidMount(){
        getList('Banner').then((items) => {    
            const banners = this.state.banners
            
            items.forEach(async (item) => {
                if(item) {
                    banners[item.posicao] = item
                }
            })

            this.setState({ banners: banners })
        })

        getItem('Loja', this.state.brandData.id).then((item) => {
            this.setState({ativado: item.data.b_destaques})
        })
        
    }

    async toggleMediaGalery(open, e = null) {
		if (open) {
            const obj = e.target
            const pos = obj.dataset.pos
			const content = await getList("Imagem")

			this.setState({ modalMediaGaleryOpen:true, modalMediaGalery: content, posicao: pos })
		} else {    
			this.setState({ modalMediaGaleryOpen:false, modalMediaGalery: null })
		}
	}

    async reloadMediaGalery(e) {
		this.setState({ uploadImage: true, loadPercent: 10 })
		const formData = new FormData()
        const file = e.target.files[0]
		const reader = new FileReader()		
		reader.readAsDataURL(file)

		this.setState({ uploadImage: true, loadPercent: 20 })

		formData.append("items.nome",file.name.replace(/ /g, '_'))

		reader.onload = async () => {
			const baseURL = reader.result

			formData.append("items.imagem",baseURL)

			this.setState({ uploadImage: true, loadPercent: 30 })
			await uploadFile(formData)
			this.setState({ uploadImage: true, loadPercent: 90 })

			const content = await getList("Imagem")
			this.setState({ uploadImage: true, loadPercent: 100 })

			this.setState({ modalMediaGalery: content })
			this.setState({ uploadImage: false, loadPercent: 0 })
		}
	}

    handlerMouseOver(pos){
        this.setState({positionMouseOver: pos})
    }
    
    resetPosition(){
        this.setState({positionMouseOver: null})
    }

    imagePlaceHolder(pos) {
        const obj = this.state.banners[pos]
        const id = obj ? obj.id : null
        const img = obj && obj.imagem ? obj.imagem.imagem : null
        const path = img ? 'https://grubupload.s3.amazonaws.com/'+img : 'https://grubicons.s3.amazonaws.com/FOTO-LANDING.png'
        
        return (
            <div style={{padding:"0", width:"24%", height:"400px", margin: "10px 5px", overflow:"hidden", borderRadius: "10px", position:"relative"}} onMouseLeave={() => this.resetPosition()} onMouseEnter={() => this.handlerMouseOver(pos)}>
                <div style={{width: "100%", height: "100%", backgroundImage: "url("+path+")", backgroundPosition: "center", backgroundSize:"cover", backgroundRepeat:"no-repeat"}}></div>

                { this.state.positionMouseOver === pos && (
                    <>
                        <div style={{width: "100%", height: "100%", background: "#000", opacity: ".6", position:"absolute", top:"0"}}></div>
                        <div style={{width: "100%", position:"absolute", bottom:"20px", display:"block", padding:"0 10%"}}>
                            <button className="btn btn-primary d-block mb-3" style={{width:"100%"}} data-pos={pos} onClick={(e) => this.toggleMediaGalery(true, e)}>SUBSTITUIR IMAGEM</button>
                            <button className="btn btn-danger d-block" style={{width:"100%"}} data-id={id} data-pos={pos} onClick={(e) => this.deleteImage(e)}>EXCLUIR IMAGEM</button>
                        </div>
                    </>
                )}
            </div>
        )
    }

    render() {
        return (
            <div>
				<ol className="breadcrumb">
					<li className="breadcrumb-item">Home</li>
					<li className="breadcrumb-item active">Minha Landing</li>
				</ol>

                <div className="row">
                    <TopPage titulo="Meus destaques" new={this.state.id}>
                        
                    </TopPage>
                    <div className='row'>
                        <div className='col-md-7'>
                            <p className="lead">
                                A funcionalidade "Meus Destaques" permite que você adicione imagens que serão exibidas quando seus clientes escanearem o QR code com seus celulares ou tablets. Este é um ótimo momento para comunicar promoções, eventos especiais ou qualquer anúncio relevante para seus clientes. Através dessa ferramenta, você pode criar uma experiência personalizada e impactante, garantindo que suas mensagens sejam vistas de forma eficaz e aumentando suas vendas.
                            </p>

                            <div className="widget" style={{padding: "10px", borderRadius: "10px", fontSize: "15px", textAlign: "center", margin:" 30px 0"}}>
                                <div className="stats-content">
                                    <div className="stats-title">
                                        Link do cardapio:&nbsp;
                                        <Link to={{pathname:"https://" + this.state.brand + ".grub.digital/destaques"}} target="_blank">
                                            {"https://" + this.state.brand + ".grub.digital/destaques"}
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <p>Deseja utilizar a pagina ”Meus destaques” como a página principal do seu cardápio?</p>

                            <div className="form-check form-switch" style={{fontSize:"17px"}}>
                                <input className="form-check-input" type="checkbox" checked={this.state.ativado} id="ativarLanding" onChange={(e) => this.handleOnChange(e)} />
                                <label className="form-check-label" htmlFor="ativarLanding">Habilitar e usar como página principal</label>
                            </div>
                        </div>
                        <div className='col-md-5'>
                            <img src="https://grubicons.s3.us-east-1.amazonaws.com/bg_detalhe.png"/>
                        </div>
                    </div>
                </div>

                <div className="col-xl-12 col-md-12">

                    <div className="hljs-wrapper" style={{padding: "20px 10px"}}>
                        <div className='row'>
                            {this.state.banners.map((item, i) => {
                                return this.imagePlaceHolder(i)
                            })}
                        </div>
                    </div>

                    {this.state.modalMediaGalery && this.state.modalMediaGalery.data && (
                        <Modal isOpen={this.state.modalMediaGaleryOpen} toggle={() => this.toggleMediaGalery(false)}>
                            <ModalHeader toggle={() => this.toggleMediaGalery(false)}>Galeria de Imagens</ModalHeader>
                            <ModalBody>
                                <div className="row mb-15px">
                                    <div className="col-md-12 mb-15px">
                                        <input className="form-control" type="file" id="formFile" onChange={(e) => this.reloadMediaGalery(e)}/>
                                    </div>
                                    {this.state.uploadImage && (
                                        <div className="progress mb-2">
                                            <div className="progress-bar fs-10px fw-bold" style={{width: this.state.loadPercent+'%'}}>Subindo o arquivo para sua galeria de fotos. Aguarde....</div>
                                        </div>
                                    )}
                                </div>

                                <div className="row mb-15px">
                                    {this.state.modalMediaGalery.data.map((item, i) =>
                                        <div key={i} className="col-3 me-5px" style={{display:"inline-block"}}>
                                            <Link to="/"  className="widget-card rounded square m-b-2">
                                                <div data-objid={item.id} data-nome={this.state.posicao} data-url={item.imagem} onClick={this.handleImageClick} className="widget-card-cover" style={{backgroundImage: 'url('+Helper.getS3URL()+item.imagem.replace(/ /g, '%20')+')'}}></div>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Paginacao
                                    model={this.state.model}
                                    lista={this.state.modalMediaGalery}
                                    page={this.state.page ? this.state.page : 1 }
                                    hadlerPagination={this.handlerPagination} />
                            </ModalFooter>
                        </Modal>
                    )}
                </div>
            </div>
        )
    }
}

export default Landing