
import React from 'react'

import { saveObj, getCep } from '../../store/api.js'
import { AppSettings } from '../../config/app-settings.js'

import InputText    from '../_atoms/InputText'
import Label        from '../_atoms/Label'
import Helper       from '../../config/helper'

export default class FormSenha extends React.Component {
    static contextType = AppSettings

    constructor(props) {
        super(props)

        this.state = {
            endereco: null,
            user: null,
            data:{
              items:{ 'id': this.props.id }
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this)
        this.setModelData = this.setModelData.bind(this)
    }

    async setModelData(name, value) {
        if (value !== undefined) {

            let data = this.state.data

            if (name === "cep" && value.length > 8){
                const endereco = await getCep(value)

                data.items["cep"] = endereco.cep
                data.items["rua"] = endereco.logradouro
                data.items["bairro"] = endereco.bairro
                data.items["cidade"] = endereco.localidade
                data.items["uf"] = endereco.uf
                data.items["lat"] = endereco.latitude
                data.items["lng"] = endereco.longitude

                this.setState({ endereco: endereco})
            } else {
                data.items[name] = value
            }

            this.setState({ data })
        }
    }

    async handleSubmit(event) {
        event.preventDefault()
    
        try {
          await saveObj(this.state.data, "atualizaendereco")
          Helper.addNotification('success', 'Tudo certo por aqui :)', 'Mudanças feitas foram salvas com sucesso.', 'top-right')
        } catch (e) {
          Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'Não foi possivel salvar as informações enviadas.', 'top-right')
        }
    }

    async componentDidMount() {
        const user = this.context.stateUserdata
        this.setState({user: user})

        if (user && user.endereco){
            this.setState({endereco: {logradouro: user.endereco.rua, bairro:user.endereco.bairro}})
            this.setState({data:{
                items:{ 'id': this.props.id, cep: user.endereco.cep, numero: user.endereco.numero }
            }})
        }
    }

    

    render() {
        return (
            <form onSubmit={this.handleSubmit} style={{margin: "0 30px"}}>
                <h4 className="card-title">Delivery</h4>
                <p className="card-text">Para começar a vender no delivery, você precisa preencher o CEP e o endereço completo da sua loja na seção abaixo. Essas informações são essenciais para calcular as taxas de entrega e para que os clientes saibam exatamente onde sua loja está localizada.</p>

                <div className='row'>

                    <div className="col-md-3">
                    
                        <div className="form-floating mb-15px">
                            <InputText
                                nome="cep"
                                setFomrData={this.setModelData}
                                dadosIniciais={this.state.data.items.cep && this.state.data.items.cep}
                            />
                            <Label nome="cep" label="Cep do estabelecimento"/>
                        </div>

                    </div>

                    <div className="col-md-6">
                        <div className="form-floating mb-15px">
                            <InputText
                                nome="logradouro"
                                setFomrData={this.setModelData}
                                dadosIniciais={this.state.endereco && this.state.endereco.logradouro+"-"+this.state.endereco.bairro}
                            />
                            <Label nome="logradouro" label="Rua"/>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="form-floating mb-15px">
                            <InputText
                                nome="numero"
                                setFomrData={this.setModelData}
                                dadosIniciais={this.state.data.items.numero && this.state.data.items.numero}
                            />
                            <Label nome="numero" label="Numero"/>
                        </div>
                    </div>

                </div>

                <div className='row'>
                    <div className="col-md-2 offset-md-10">
                        <button type="submit" style={{float: 'right'}} className="btn btn-success btn-block btn-lg">Salvar</button>
                    </div>
                </div>
            </form>
        )
    }
}