import React from 'react'

import { saveObj, getList } from './../store/api.js'
import { Link } from 'react-router-dom'
import TopPage from '../components/_organisms/TopPage'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import Moment from 'moment'

import Offcanvas from 'react-bootstrap/Offcanvas'

import Detalhe from './../pages/detalhe.js'

import Lottie from "lottie-react"
import lottiejson from "../assets/error-grub.json"

export default class Banco extends React.Component {
    constructor(props) {
		super(props)
        const lsUser = localStorage.getItem('user')
		const user = lsUser ? JSON.parse(localStorage.getItem('user')).data : null

        this.state = {
            user: user,
			brand:user ? user.brand : null,
			metas: null,
            dateRange: null,
            dadosDaConta: null,
            tabs: [{chave: 'extrato', valor: 'EXTRATO DA CONTA'},{chave: 'recebiveis', valor: 'RECEBÍVEIS'},{chave: 'transferencia', valor: 'TRANSFERÊNCIA'}],
            filtro: 'extrato',
            filtrodt:{dtInicio: Moment(new Date().setDate(new Date().getDate() - 30)), dtFim: Moment()},
            filterDates: "filtro3",
            list:[],
            resumo: null,
		}
		
        this.fetchData = this.fetchData.bind(this)
        this.handleDateApplyEvent = this.handleDateApplyEvent.bind(this)
        this.aplicaFiltro = this.aplicaFiltro.bind(this)
	}

	async fetchData(startDate, endDate, aba = 'asaas/extrato'){
        const list = await saveObj( {loja_id: this.state.user.loja.id, di:startDate, df:endDate}, aba )
        const resumo = await saveObj( {loja_id: this.state.user.loja.id}, 'asaas/resumo' )
        const dadosDaConta = await getList("AsaasAcount")

        this.setState({list:list.data, resumo:resumo.data, dadosDaConta:dadosDaConta})
	}

    async aplicaFiltro(e){
		e.preventDefault()

		const t = e.target
		const id = t.dataset.filter 
        let aba = 'asaas/extrato'
        const startDate = this.state.filtrodt.dtInicio
        const endDate = this.state.filtrodt.dtFim

        this.setState({list:[],filtro: id})

        if(id === 'recebiveis') aba = 'asaas/consultaCobranca'
        if(id === 'transferencia') aba = 'asaas/tranferencia'

        await this.fetchData(startDate, endDate, aba)
	}
    

    componentDidMount() {
        const startDate = Moment(new Date().setDate(new Date().getDate() - 30))
        const endDate = Moment()
		const gap = endDate.diff(startDate, 'days')
		
		const currentWeek = startDate.format('D MMMM YYYY') + ' - ' + endDate.format('D MMMM YYYY')
		const prevWeek = Moment(startDate).subtract('days', gap).format('D MMMM') + ' - ' + Moment(startDate).subtract('days', 1).format('D MMMM YYYY')
		
		this.setState({dateRange: {
			currentWeek: currentWeek,
			prevWeek: prevWeek,
			gap:gap
		}, filtrodt:{dtInicio: startDate, dtFim: endDate }})

		this.fetchData(startDate, endDate)
	}

    formatDate(date){
        const dt = new Date(date)

        return dt.getDate()+"/"+(dt.getMonth()+1)+"/"+dt.getFullYear()
    }

    handleDateApplyEvent(e, picker) {
		var startDate = picker.startDate
		var endDate = picker.endDate
		var gap = endDate.diff(startDate, 'days')
		
		var currentWeek = startDate.format('D MMMM YYYY') + ' - ' + endDate.format('D MMMM YYYY')
		var prevWeek = Moment(startDate).subtract('days', gap).format('D MMMM') + ' - ' + Moment(startDate).subtract('days', 1).format('D MMMM YYYY')
		
		this.setState({dateRange: {
			currentWeek: currentWeek,
			prevWeek: prevWeek,
			gap:gap
		}})

		this.fetchData(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
	}

    hadleFilterDate(e, filter){
		e.preventDefault()

		const today = Moment()
		let priorDate = Moment(new Date().setDate(new Date().getDate() - 7))

		this.setState({filterDates: filter})

		if (filter === "filtro2") priorDate = Moment(new Date().setDate(new Date().getDate() - 15))
		if (filter === "filtro3") priorDate = Moment(new Date().setDate(new Date().getDate() - 30))

        this.setState({filtrodt:{dtInicio: priorDate, dtFim: today}})

        this.handleDateApplyEvent(null, {startDate:priorDate, endDate:today})
	}

    calcula(item, i, arr){
        return parseFloat(item.value) - parseFloat(arr[i+1].value)
    }

    handleDetalhe(e, item){
		e.preventDefault()
		this.setState({detalheId:item.externalReference, showCanvas:!this.state.showCanvas})
	}

    render(){
        var initialDatePicker = {
            startDate: Moment().subtract(7, 'days'),
            endDate: Moment(),
            ranges: {
              'Hoje': [Moment().toDate(), Moment().toDate()],
              'Ontem': [
                Moment().subtract(1, 'days').toDate(),
                Moment().subtract(1, 'days').toDate(),
              ],
              'Últimos 7 dias': [
                Moment().subtract(6, 'days').toDate(),
                Moment().toDate(),
              ],
              'Últimos 30 Dias': [
                Moment().subtract(29, 'days').toDate(),
                Moment().toDate(),
              ],
              'Mês atual': [
                Moment().startOf('month').toDate(),
                Moment().endOf('month').toDate(),
              ],
              'Mês anterior': [
                Moment().subtract(1, 'month').startOf('month').toDate(),
                Moment().subtract(1, 'month').endOf('month').toDate(),
              ],
            },
          }

        return (
			<div>
                <TopPage titulo="Meu dinheiro">
                    <p className="lead">
                        Domine seu ambiente operacional! Em nossas configurações avançadas, você personaliza cada detalhe do seu espaço. Estabeleça políticas que se alinham à sua visão operacional. Acesse ações globais instantaneamente: aceite pedidos, habilite/desabilite o delivery e muito mais. Sua operação, suas regras. Simplificamos o controle para que você direcione sua operação com eficiência e agilidade.
                    </p>
                </TopPage>
                
                <div className="row">
                    {this.state.resumo && (
                        <>
                            <div className="col-xl-3 col-md-6">
                                <div className="widget widget-stats ">
                                    <div className="stats-content">
                                        <div className="stats-title">Minha conta</div>

                                        {this.state.dadosDaConta && (
                                            <div className="stats-number" style={{fontSize:"20px", margin:"0"}}>
                                                <b style={{color:"#0043ff"}}>Conta:</b> {this.state.dadosDaConta[0].account} - {this.state.dadosDaConta[0].account_digit}
                                            </div>
                                        )}

                                        {this.state.dadosDaConta && (
                                            <div className="stats-number" style={{fontSize:"20px"}}>
                                                <b style={{color:"#0043ff"}}>Agência:</b> {this.state.dadosDaConta[0].agency} 
                                            </div>
                                        )}

                                        <div className="stats-desc mb-1">Banco: 461 - Asaas I.P S.A</div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6">
                                <div className="widget widget-stats ">
                                    <div className="stats-content">
                                        <div className="stats-title">Saldo Total</div>
                                        <div className="stats-number" style={{color: this.state.resumo.balanceData.balance >= 0 ? 'green' : 'red'}}>{this.state.resumo.balanceData.balance.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} </div>

                                        <div className="stats-desc mb-1">Pagamentos realizados e recebidos.</div>

                                        <button class="btn btn-xs btn-default">Solicitar transferência</button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-6">
                                <div className="widget widget-stats ">
                                    <div className="stats-content">
                                        <div className="stats-title">Saldo a receber</div>
                                        <div className="stats-number" style={{color:"#f59c1a"}}>{this.state.resumo.statisticsData.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} </div>

                                        <div className="stats-desc">Valores que serão creditados em sua conta no período selecionado</div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>

                <div className='row mb-3'>
                    <div className='col-12'>
                        <div className="btn-group"  style={{float:"left"}}>
                            <button className={this.state.filterDates === "filtro1" ? "btn btn-white active" : "btn btn-white"} onClick={(e) => this.hadleFilterDate(e, "filtro1")}>Últimos 7 dias</button>
                            <button className={this.state.filterDates === "filtro2" ? "btn btn-white active" : "btn btn-white"} onClick={(e) => this.hadleFilterDate(e, "filtro2")}>Últimos 15 dias</button>
                            <button className={this.state.filterDates === "filtro3" ? "btn btn-white active" : "btn btn-white"} onClick={(e) => this.hadleFilterDate(e, "filtro3")}>Últimos 30 dias</button>
                        </div>

                        <div className="d-sm-flex align-items-center" style={{float:"right"}}>
                            <DateRangePicker initialSettings={initialDatePicker} onApply={this.handleDateApplyEvent}>
                                <button className="btn btn-white me-2 text-truncate">
                                    <i className="fa fa-calendar fa-fw text-opacity-50 ms-n1 me-1"></i> 
                                    <span>{this.state.dateRange && (this.state.dateRange.currentWeek)}</span>
                                    <b className="caret ms-1 opacity-5"></b>
                                </button>
                            </DateRangePicker>
                        </div>
                    </div>
                </div>

                <div className="card border-0">
                    <ul className="nav nav-tabs nav-tabs-v2 px-3">
                        {this.state.tabs.map( (item) =>
                            <li key={item.chave} className="nav-item me-2" >
                                <Link to={item.chave} data-filter={item.chave} onClick={this.aplicaFiltro} className={this.state.filtro === item.chave ? "nav-link px-2 active" : "nav-link px-2"}>
                                    {item.valor}
                                </Link>
                            </li>
                        )}
                    </ul>

                    <div className="card-body">
                        <div className="table-responsive mb-3">
                            {this.state.list && this.state.list.data && this.state.list.data.length > 0 ? (
                                <>
                                    {this.state.filtro === 'extrato' && (
                                        <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                                            <thead>
                                                <tr>
                                                    <th style={{textAlign:"center"}}><b>Id Transação</b></th>
                                                    <th style={{textAlign:"center"}}><b>data</b></th>
                                                    <th style={{textAlign:"center"}}><b></b></th>
                                                    <th style={{textAlign:"center"}}><b>Descrição</b></th>
                                                    <th style={{textAlign:"center"}}><b>Valor</b></th>
                                                    <th style={{textAlign:"center"}}><b>Saldo</b></th>
                                                    <th style={{textAlign:"center"}}><b></b></th>
                                                </tr>
                                            </thead>
                                            <tbody>       
                                                {this.state.list.data && this.state.list.data.length > 0 && this.state.list.data.map((item, i, arr) => 
                                                    <>
                                                        {item.type !== "PAYMENT_FEE" && i < arr.length-1 && (
                                                            <tr>
                                                                <td style={{textAlign:"center"}}>{item.id}</td>
                                                                <td style={{textAlign:"center"}}>{this.formatDate(item.date)}</td>
                                                                <td style={{textAlign:"center"}}> <i style={{color:"#0043ff"}} class="fa-solid fa-arrow-right-long"></i> </td>
                                                                <td style={{textAlign:"center"}}>{item.description}</td>
                                                                <td style={{textAlign:"center", color: '#0043ff'}}>{this.calcula(item, i, arr).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                                                                <td style={{textAlign:"center", color: '#0043ff'}}>
                                                                    {i === 1 ? (
                                                                        (parseFloat(item.balance) + parseFloat(arr[0].value)).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                                                    ) : (
                                                                        item.balance.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                                                    )}
                                                                </td>
                                                                <td style={{textAlign:"center"}}></td>       
                                                            </tr>
                                                        )}
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                    )}

                                    {this.state.filtro === 'recebiveis' && (
                                        <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                                            <thead>
                                                <tr>
                                                    <th style={{textAlign:"center"}}><b>ID Pedido</b></th>
                                                    <th style={{textAlign:"center"}}><b>Valor</b></th>
                                                    <th style={{textAlign:"center"}}><b>Tipo do Pagamento</b></th>
                                                    <th style={{textAlign:"center"}}><b>Data de Efetivaçao</b></th>
                                                    <th style={{textAlign:"center"}}><b>Origem</b></th>
                                                    <th style={{textAlign:"center"}}><b>Cliente</b></th>
                                                    <th style={{textAlign:"center"}}></th>
                                                </tr>
                                            </thead>
                                            <tbody>       
                                                {this.state.list.data && this.state.list.data.length > 0 && this.state.list.data.map((item) => 
                                                    <tr>
                                                        <td style={{textAlign:"center"}}>
                                                            <b style={{color:"#0043ff", cursor:"pointer"}} onClick={(e) => this.handleDetalhe(e, item)}>#{item.externalReference}</b>
                                                        </td>
                                                        <td style={{textAlign:"center"}}><b style={{color:"green"}}>{item.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></td>
                                                        <td style={{textAlign:"center"}}><i style={{color:"#0043ff"}} className='fa-solid fa-credit-card'></i> {item.billingType === "CREDIT_CARD" ? "Cartão de credito" : "Pix"}</td>
                                                        <td style={{textAlign:"center"}}>{this.formatDate(item.estimatedCreditDate)}</td>
                                                        <td style={{textAlign:"center"}}>
                                                            <span className="badge bg-primary text-white px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
																<i className="fa-solid fa-motorcycle fs-9px fa-fw me-5px"></i> Delivery
															</span>
                                                        </td>
                                                        <td style={{textAlign:"center"}}>{item.pedido.entrega.nome}</td>
                                                        <td style={{textAlign:"center"}}></td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    )}
                                </>
                            ) : (
                                <div className="card border-0" style={{padding:"15px"}}>

                                    <Lottie animationData={lottiejson} loop={true} style={{width: "500px", margin:"auto"}} />
                                    <h3 style={{textAlign:"center"}}>Nenhuma transação encontrada no periodo selecionado. </h3>
								
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <Offcanvas show={this.state.showCanvas} onHide={() => this.setState({showCanvas: false})}  placement="end" style={{width:"40%"}}>
					<Offcanvas.Header closeButton>
						<Offcanvas.Title>
							<h3>Pedido: #{this.state.detalheId}</h3>
						</Offcanvas.Title>
					</Offcanvas.Header>
					<Offcanvas.Body>
						<Detalhe id={this.state.detalheId}/>
					</Offcanvas.Body>
				</Offcanvas>
              
            </div>
        )
    }

}