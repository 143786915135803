import React from 'react'

import { Link } from 'react-router-dom'

import { getItem } from '../../store/api.js'

import Moment from 'moment'

export default class Detalhe extends React.Component {
    constructor(props) {
		super(props)

		this.state = {
            cliente: this.props.cliente,
            lista: []
		}

        this.fetchData = this.fetchData.bind(this)
	}

	async fetchData(){
        const comandas = this.props.cliente.comandas.reverse()
        const result = []
        let comanda = null

        for (let i in comandas) {
            comanda = await getItem('Comanda', comandas[i].id)
            result.push( comanda.data  )
        }

        this.setState({ lista: result })
	}


    componentDidMount() {
		this.fetchData()
    }

    handlerPagination(e){
	
	}

	getTotal(comandas){
		let total = parseFloat(0,0)

		if(comandas && comandas.length > 0){
			comandas.forEach(c => {
				if (c.meta.pivot_total) {
					total = parseFloat(total) + parseFloat(c.meta.pivot_total)
				}
			})
		} 
		
		return total.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
	}

	ticketMedio(comandas){
		let total = parseFloat(0,0)
		let quantidade = 0

		if(comandas && comandas.length > 0){
			comandas.forEach(c => {
				if (c.meta.pivot_total) {
					total = parseFloat(total) + parseFloat(c.meta.pivot_total)
					quantidade++
				}
			})
		} 
		
		return total > 0 ? (total/quantidade).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) : parseFloat(0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
	}

    quantProdutos(pedidos){
        let produtos = 0

        if (pedidos) {
            pedidos.forEach((p) => {
                p.produtos.forEach((pp) => {
                    produtos++
                })
            })
        }
        
        return produtos
    }

	ultimoPedido(comandas){
		let result = "-"

		if(comandas && comandas.length > 0){
			if(comandas[comandas.length-1].meta.pivot_ultimo_pedido) {
				const dt = new Date(comandas[comandas.length-1].meta.pivot_ultimo_pedido)
				result = parseInt((new Date() - dt) / (1000 * 60 * 60 * 24))
			}
		} 
		
		return result
	}

    primeiroPedido(comandas){
        if (comandas && comandas.length > 0) {
            const dt = new Date(comandas[0].created_at)
            return dt.getDate()+"/"+dt.getMonth()+"/"+dt.getFullYear()
        }
    }

    render(){
        return (
            <div>
                <div className='row mb-3'>
                    <div className='col-1'>
                        <img src='/imagem-perfil.png' style={{width: '45px', borderRadius: "5px"}} />
                    </div>
                    <div className='col-7' style={{padding:'0 20px'}}>
                        <div className='mb-1' style={{textTransform:"uppercase"}}> <b>{this.state.cliente.nome}</b> </div>
                        <div className='mb-2'>{this.state.cliente.telefone}</div>
                    </div>

                    <div className='col-4' style={{textAlign:'right'}}>
                        <Link to={{pathname:"whatsapp://send?text= &phone=+55"+this.state.cliente.telefone.replace("(","").replace(")","").replace("-","").replace(" ","")}} className="btn btn-outline-success"  target="_blank"> 
                            <i className="fa-brands fa-whatsapp"></i> Enviar mensagem
                        </Link>
                    </div>
                </div>

                <div className='row  mb-3'>
                    <div className='col-12'>
                        {this.state.cliente.enderecos[0].rua} {this.state.cliente.enderecos[0].numero}, {this.state.cliente.enderecos[0].bairro} - {this.state.cliente.enderecos[0].cep}
                    </div>
                </div>

                <div className='row' style={{background:"#F4F4F4", margin:'10px', padding:'5px'}}>
                    <div className='col-12'>
                        <span className="col-3" style={{color:"green", display:"inline-block", textAlign:"center"}}>
                            <b>Receita</b><br/>
                            <b>{ this.getTotal(this.state.cliente.comandas) }</b>
                        </span>
                        <span className="col-3"  style={{display:"inline-block", textAlign:"center"}}>
                            <b>Ticket Médio</b><br/>
                            <b>{ this.ticketMedio(this.state.cliente.comandas) }</b>
                        </span>
                        <span className="col-3" style={{display:"inline-block", textAlign:"center"}}>
                            <b>Primeiro pedido</b><br/>
                            <b>{ this.primeiroPedido(this.state.cliente.comandas) }</b>
                        </span>
                        <span className="col-3" style={{display:"inline-block", textAlign:"center"}}>
                            <b>Último pedido</b><br/>
                            Faz <b>{ this.ultimoPedido(this.state.cliente.comandas) } dias</b>
                        </span>
                    </div>
                </div>

                {this.state.lista && this.state.lista.length > 0 && (
                    <div className="tab-pane fade show active" id="allTab">
                        
                        <div className="table-responsive mb-3">
                            <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                                <thead style={{textAlign: "center"}}>
                                    <tr>
                                        <th>Pedido</th>
                                        <th>Data</th>
                                        <th>Tipo</th>
                                        <th>Itens</th>
                                        <th>Receita</th>
                                    </tr>
                                </thead>
                                <tbody style={{textAlign: "center"}}>
                                    {this.state.lista.length > 0 && this.state.lista.map((item) => 
                                        <tr key={"item_"+item.id}>
                                            <td className="align-middle">
                                                {item.id}
                                            </td>
                                            <td>
                                                {Moment(item.created_at).format('DD/MM/YYYY - HH:mm')}</td>
                                            <td>
                                                {item.b_delivery === 1 &&
                                                    <span className="badge border border-primary text-primary px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                                        <i className="fa fa-truck-fast fs-9px fa-fw me-5px"></i> Delivery 
                                                    </span>
                                                }

                                                {item.b_takeaway === 1 &&
                                                    <span className="badge border border-purple text-purple px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                                        <i className="fa fa-bag-shopping fs-9px fa-fw me-5px"></i> Comanda 
                                                    </span>
                                                }

                                                {item.b_delivery === 0 && item.b_takeaway === 0 && item.mesa && item.mesa.b_comanda === 0 &&
                                                    <span className="badge border border-pink text-pink px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                                        <i className="fa fa-utensils fs-9px fa-fw me-5px"></i> Mesa
                                                    </span>
                                                }

                                                {item.b_delivery === 0 && item.b_takeaway === 0 && item.mesa && item.mesa.b_comanda === 1 &&
                                                    <span className="badge border border-purple text-purple px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                                        <i className="fa fa-bag-shopping fs-9px fa-fw me-5px"></i> Comanda
                                                    </span>
                                                }
                                            </td>
                                            <td className="align-middle">
                                                {this.quantProdutos(item.pedidos)}
                                            </td>
                                            <td className="align-middle">
                                                {item.pagamentos && item.pagamentos.length > 0 && (parseFloat(item.pagamentos[0].valor) + parseFloat(item.pagamentos[0].valor_taxa) + parseFloat(item.pagamentos[0].valor_taxa_entrega)).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>

                    </div>
                )}

            </div>
        )
    }

}