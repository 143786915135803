import React from 'react'
import { Link } from 'react-router-dom'
import { Modal, ModalBody } from 'reactstrap'

import Paginacao from '../paginacao/Paginacao.jsx'
import Helper    from '../../config/helper'

import { getList, saveObj, getStructList, getBusca, deleteObj } from '../../store/api.js'

import Lottie from "lottie-react"
import lottiejson from "../../assets/error-grub.json"
import lottieAlerta from "../../assets/cuidado-alerta.json"

class Lista extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			model:null,
			lista:[],
			struct:[],
			filtro: "todos",
			subfiltro: "",
			modalAlert: false,
			OBJID: null,
			subtabs: [],
			tabs: [{chave: 'todos', valor: 'TODOS'}]
		}

		this.aplicaFiltro = this.aplicaFiltro.bind(this)
		this.fetchData = this.fetchData.bind(this)
		this.handlerPagination = this.handlerPagination.bind(this)
		this.handleBusca = this.handleBusca.bind(this)
		this.deleteItem = this.deleteItem.bind(this)
		this.aplicaFiltroSub = this.aplicaFiltroSub.bind(this)
	}

	fetchData(){
		if (this.state.model !== this.props.model) {
			getStructList(this.props.model).then(result => {
				this.setState({ struct: result })
			})

			getList(this.props.model).then(result => {
				this.setState({ lista: result })
			})

			this.setState({model: this.props.model})

			getList('Categoria').then(result => {
				const tabs = this.state.tabs
				result.data.forEach(item => {
					tabs.push({chave:item.id, valor:item.nome})
				})

				this.setState({ tabs: tabs })
			})
		}
	}

	async deleteItem(e){
		e.preventDefault()

		await deleteObj('Produto/'+this.state.OBJID)
		this.setState({ modalAlert: false, OBJID: null })
		getList(this.props.model).then(result => {
			this.setState({ lista: result })
		})
	}
	async handleChangeProduct(data){
		try {
			await saveObj({items:data}, this.state.model)
			Helper.addNotification('success', 'Tudo certo por aqui :)', 'Mudanças feitas no produto salvas com sucesso.', 'top-right')

			if (this.state.filtro != 'todos') {
				getList(this.state.model, {filtro: this.state.filtro}).then(result => {
					this.setState({ lista: result })
				})
			} else {
				getList(this.state.model).then(result => {
					this.setState({ lista: result })
				})
			}

		  } catch (e) {
			Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'Não foi possivel salvar as informações enviadas.', 'top-right')
		  }
	}

	componentDidMount() {
		this.fetchData()
	}

	

	aplicaFiltro(e){
		e.preventDefault()
		const t = e.target
		const id = t.dataset.filter != 'todos' ? t.dataset.filter : ''

		getList(this.state.model, {filtro: id}).then(result => {
			this.setState({ lista: result })
		})

		if (t.dataset.filter != 'todos') {
			getList('Categoria', {filtro:id}).then(result => {
			
				const subtabs = []
				result.data.forEach(item => {
					subtabs.push({chave:item.id, valor:item.nome})
				})
	
				this.setState({subtabs:subtabs})
			})
		} else {
			this.setState({subtabs:[]})
		}
	
		this.setState({filtro: id})
	}

	aplicaFiltroSub(e){
		e.preventDefault()
		const t = e.target
		const id = t.dataset.filter

		getList(this.state.model, {filtro: id}).then(result => {
			this.setState({ lista: result })
		})

		this.setState({subfiltro: id})
	}

	handleBusca(e){
		getBusca(this.state.model, {filtro:e.target.value}).then(result => {
			this.setState({ lista: result })
		})
	}

	handlerPagination(e){
		getList(this.state.model, {page:e.target.dataset.page}).then(result =>
			this.setState({ lista: result })
		)
	}

	paginacao(){
		return <Paginacao 
					model={this.state.model} 
					lista={this.state.lista}
					hadlerPagination={this.handlerPagination} />
	}

	busca(event) {
		event.preventDefault()

		this.handleBusca(event)
	}
	
	imagem(item){
		const imagem = item.imagem && item.imagem.imagem && item.imagem.imagem != 0 ? "https://grubupload.s3.us-east-1.amazonaws.com/"+item.imagem.imagem : "https://grubicons.s3.us-east-1.amazonaws.com/foto-padrao-grub-digital.jpg"
		return imagem
	}

	render() {
		const lista = this.state.lista
		return (
			<div>
				<div className="card border-0">

					<ul className="nav nav-tabs nav-tabs-v2 px-3">
						{this.state.tabs.map( (item) =>
							<li key={item.chave} className="nav-item me-2" >
								<Link to={item.chave} data-filter={item.chave} onClick={this.aplicaFiltro} className={this.state.filtro === item.chave ? "nav-link px-2 active" : "nav-link px-2"}>
									{item.valor}
								</Link>
							</li>
						)}
					</ul>

					{this.state.subtabs.length > 0 && (
						<ul className="nav nav-tabs nav-tabs-v2 px-3" style={{backgroundColor:"#d1e9ffcc"}}>
							{this.state.subtabs.map( (item) =>
								<li key={item.chave} className="nav-item me-2" >
									<Link to={item.chave} data-filter={item.chave} onClick={this.aplicaFiltroSub} className={this.state.subfiltro === item.chave ? "nav-link px-2 active" : "nav-link px-2"}>
										{item.valor}
									</Link>
								</li>
							)}
						</ul>
					)}
					
					<div className="tab-content p-3">
						<div className="tab-pane fade show active" id="allTab">
							<div className="input-group mb-3">
								<button className="btn btn-white" type="button" data-bs-toggle="dropdown">
									<span className="d-none d-md-inline">Buscar produtos</span>
								</button>
								
								<div className="flex-fill position-relative">
									<div className="input-group">
										<div className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0" style={{zIndex: 10}}>
											<i className="fa fa-search opacity-5"></i>
										</div>
										<input type="text" className="form-control px-35px bg-light" placeholder=""  onChange={(e) => this.busca(e)}/>
									</div>
								</div>
							</div>

							{lista.data && lista.data.length > 0 ? (
								<>
									<div className="table-responsive mb-3">
										<table className="table table-hover table-panel text-nowrap align-middle mb-0">
											<thead>
												<tr>
													<th></th>
													<th>Produto</th>
													<th>Categoria</th>
													<th>Valor</th>
													<th>Disponibilidade</th>
													<th>Promoção</th>
													<th>Visibilidade</th>
													<th>Ações</th>
												</tr>
											</thead>
											<tbody>
												{lista.data.length > 0 && lista.data.map((item) => 
													<tr key={"item_"+item.id}>
														<td className="w-10px align-middle">
															<div className="form-check">
																<input type="checkbox" className="form-check-input" id="product1" />
																<label className="form-check-label" for="product1"></label>
															</div>
														</td>
														<td>
															<Link to={"/cardapio/edit/Produto/"+item.id} style={{ textDecoration: 'none' }} >
																<div className="d-flex align-items-center">
																	<div className="w-50px h-50px bg-light d-flex align-items-center justify-content-center" style={{backgroundImage:"url("+this.imagem(item)+")", backgroundSize:"cover", borderRadius:"20%"}}>
																		
																	</div>
																	<div className="ms-3" style={{textTransform:"uppercase", fontWeight:"bold"}}>{item.nome}</div>
																</div>
															</Link>
														</td>
														<td>{item.categoria.nome}</td>
														<td>
															{item.b_personalizado === 0 ?
																<b>
																	{"R$ "+item.valor}
																</b>
															: 
																<b style={{color:"#0043ff"}}>
																	Valor personalizado
																</b>
															}
														</td>
														<td>
															{!item.dias ? 
																<span className="badge border border-primary text-primary px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
																	<i className="fa fa-circle fs-9px fa-fw me-5px"></i>
																	Total
																</span>
															:
																<span className="badge border border-danger text-danger px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
																	<i className="fa-solid fa-circle fs-9px fa-fw me-5px"></i>
																	Restrita
																</span>
															}
														</td>
														<td>
															{item.b_promocao ? 
																<span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
																	<i className="fa fa-circle fs-9px fa-fw me-5px"></i>
																	Ativa
																</span>
															:
																<span className="badge border border-secondary text-secondary px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
																	<i className="fa-solid fa-circle fs-9px fa-fw me-5px"></i>
																	Inativa
																</span>
															}
														</td>
														<td>
															<div className="form-check form-switch col-6">
																<input className="form-check-input" name="b_ativo" type="checkbox" checked={item.b_ativo}  onChange={() => {this.handleChangeProduct({b_ativo: !item['b_ativo'], id: item.id})}} id="ativo_cardapio" />
																<label className="form-check-label" htmlFor="ativo_cardapio">Mesa/Comanda</label>
															</div>
													
															<div className="form-check form-switch col-6">
																<input className="form-check-input" name="b_delivery" type="checkbox" checked={item.b_delivery}  onChange={() => {this.handleChangeProduct({b_delivery:!item['b_delivery'], id:item.id})}} id="ativo_delivery" />
																<label className="form-check-label" htmlFor="ativo_delivery">Delivery</label>
															</div>
														</td>
														<td>
															<button className="btn btn-white" data-mesa={item.id} onClick={(e) => {e.preventDefault(); this.setState({ modalAlert: true, OBJID: e.target.dataset.mesa })}}>
																<i className="fa fa-trash fs-9px fa-fw me-5px"></i> Excluir
															</button>
														</td>
													</tr>
												)}
											</tbody>
										</table>
									</div>
								
									{this.props.paginacao && (this.paginacao())}
								</>
							)
							:
							( 
								<div className="card border-0" style={{padding:"15px"}}>
									<Lottie animationData={lottiejson} loop={true} style={{width: "500px", margin:"auto"}} />
									<h1 style={{textAlign:"center"}}>Nenhum produto por aqui!</h1>
									<p  style={{textAlign:"center"}}>Revise se não cometeu um erro no momento de escrever o nome do produto.</p>
								</div>
							)}
							
						</div>
					</div>
					
				</div>

				<Modal isOpen={this.state.modalAlert} toggle={() => this.setState({ modalAlert: false })}>
					<ModalBody>
						<div className='text-center'>
							<Lottie animationData={lottieAlerta} loop={true} style={{width: "250px", margin:"auto"}} />
							<div className="alert mb-15">
								<h3>Atenção: esta ação é crítica e irreversível.</h3>
								<p>O produto será excluido.</p>
							</div>
							
							<button className="btn btn-white" style={{marginRight: '15px'}} onClick={() => this.setState({ modalAlert: false })}>Cancelar</button>
							<button className="btn btn-danger" onClick={(e) => this.deleteItem(e)}>Sim, desejo eliminar este item</button>
						</div>
					</ModalBody>
				</Modal>	
			</div>
		)
	}
}

export default Lista;