import React from 'react'
import { Link } from 'react-router-dom'
import { Modal, ModalBody, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

import Paginacao from '../paginacao/Paginacao.jsx'

import { getList, deleteObj, changeOrder } from '../../store/api.js'

import Lottie from "lottie-react"
import lottiejson from "../../assets/error-grub.json"
import lottieAlerta from "../../assets/cuidado-alerta.json"

class ListaCategorias extends React.Component {

	constructor(props) {
		super(props)

		this.state = {
			model:null,
			lista:[],
			mesa:null,
			modalAlert: false,
			OBJID: null,
			subtabs: [],
			tabs: [{chave: 'todos', valor: 'TODOS'}]
		}

		this.aplicaFiltro = this.aplicaFiltro.bind(this)
		this.fetchData = this.fetchData.bind(this)
		this.handlerPagination = this.handlerPagination.bind(this)
		this.deleteItem = this.deleteItem.bind(this)
		this.handlerActions = this.handlerActions.bind(this)
	}

	fetchData(){
		if (this.state.model !== this.props.model) {
			getList(this.props.model).then(result => {
				const tabs = this.state.tabs
				const tabsAdd = result.data.filter(item => {return item.meta.categorias_count > 0})

				tabsAdd.forEach(item => {
					tabs.push({chave:item.id, valor:item.nome})
				})

				this.setState({ lista: result, tabs: tabs })
			})

			this.setState({model: this.props.model})
		}
	}

	async deleteItem(e){
		e.preventDefault()

		await deleteObj(this.state.model+'/'+this.state.OBJID)
		this.setState({ modalAlert: false, OBJID: null })
		getList(this.props.model).then(result => {
			this.setState({ lista: result.data })
		})
	}

	aplicaFiltro(e){
		e.preventDefault()
		const t = e.target
		const id = t.dataset.filter != 'todos' ? t.dataset.filter : ''

		getList(this.state.model, {filtro: id}).then(result => {
			this.setState({ lista: result})
		})
	
		this.setState({filtro: id})
	}

	componentDidMount() {
		this.fetchData()
	}

	handlerPagination(e){
		getList(this.state.model, {page:e.target.dataset.page}).then(result =>
			this.setState({ lista: result })
		)
	}

	paginacao(){
		return <Paginacao 
					model={this.state.model} 
					lista={this.state.lista}
					hadlerPagination={this.handlerPagination} />
	}

	async handlerActions(e){
		let action = e.currentTarget.dataset.action
		let ref = e.currentTarget.dataset.ref

		if (action === 'editar') {
			this.props.history.push("/cardapio/edit/"+ref)
		} else if (action === 'order') {
			let direcao = e.currentTarget.dataset.direcao
			await changeOrder(ref, direcao)
			getList(this.props.model).then(result => {
				this.setState({ lista: result })
			})
		} else if (action === 'excluir') {
			this.setState({ modalAlert: true, OBJID: ref })
		}
	}

	render() {
		const lista = this.state.lista.data
		return (
			<div>
			
				<div className="card border-0">

					<ul className="nav nav-tabs nav-tabs-v2 px-3">
						{this.state.tabs.map( (item) =>
							<li key={item.chave} className="nav-item me-2" >
								<Link to={item.chave} data-filter={item.chave} onClick={this.aplicaFiltro} className={this.state.filtro === item.chave ? "nav-link px-2 active" : "nav-link px-2"}>
									{item.valor}
								</Link>
							</li>
						)}
					</ul>
					
					<div className="tab-content p-3">

						{lista && lista.length > 0 ? (
							<div className="tab-pane fade show active" id="allTab" >
								
								<div className="table-responsive mb-3" style={{minHeight:"200px"}}>
									<table className="table table-hover table-panel text-nowrap align-middle mb-0">
										<thead>
											<tr>
												<th>Categoria Mãe</th>
												<th>Icone</th>
												<th>Nome</th>
												<th>Total de Produtos</th>
												<th>Ordem</th>
												<th>Setor de impressão</th>
												<th>Ações</th>
											</tr>
										</thead>
										<tbody>
											{lista.map((item) => 
												<tr key={"item_"+item.id}>
													<td>{item.categoria ? item.categoria.nome : "-"}</td>
													<td className="with-img">
														<img alt="" className="img-rounded height-30" style={{height: "30px", borderRadius: "10px"}} src={"https://grubicons.s3.us-east-1.amazonaws.com/"+item.icon}/>
													</td>
													<td>{item.nome}</td>
													<td>{item.meta.produto_count}</td>
													<td>
														<span data-direcao="cima" data-action="order" data-ref={this.state.model+"/"+item.id} data-objid={item.id} onClick={(e) => this.handlerActions(e)} className='btn btn-white'><i className="fa-solid fa-arrow-up"></i></span>
														<span className='btn btn-white'>{item.order}</span>
														<span data-direcao="baixo" data-action="order" data-ref={this.state.model+"/"+item.id} data-objid={item.id} onClick={(e) => this.handlerActions(e)} className='btn btn-white'><i className="fa-solid fa-arrow-down"></i></span>
													</td>
													<td>{item.fila ? item.fila.identificador : "-"}</td>
													<td>
														<UncontrolledButtonDropdown>
															<DropdownToggle caret color="primary">AÇÕES</DropdownToggle>
															<DropdownMenu>
																<DropdownItem data-action="editar" data-ref={this.state.model+"/"+item.id} data-objid={item.id} onClick={(e) => this.handlerActions(e)} className="btn btn-default">Editar</DropdownItem>
																{item.meta.produto_count === 0 && (
																	<DropdownItem data-action="excluir" data-ref={item.id} data-objid={item.id} onClick={(e) => this.handlerActions(e)} className="btn btn-default">Excluir</DropdownItem>
																)}
															</DropdownMenu>
														</UncontrolledButtonDropdown>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
								{this.props.paginacao && (this.paginacao())}
							</div>
						)
						:
						( 
							<div className="card border-0" style={{padding:"15px"}}>
								<div className="row" style={{marginBottom:'15px'}}>
									<div className="col-sm-12 col-md-4 offset-md-8">
										<Link to={"/cardapio/edit/"+this.props.model}>
											<button type="button" className="btn btn-primary me-1 mb-1 float-end">
												+ Adicionar nova {this.props.model}
											</button> 
										</Link>
									</div>
								</div>

								<Lottie animationData={lottiejson} loop={true} style={{width: "500px", margin:"auto"}} />
								<h3 style={{textAlign:"center"}}>Nenhuma categoria cadastrado por aqui! Cadastre um agora. </h3>
								<p  style={{textAlign:"center"}}></p>
							</div>
						)}

					</div>
				</div>
				
				<Modal isOpen={this.state.modalAlert} toggle={() => this.setState({ modalAlert: false })}>
					<ModalBody>
						<div className='text-center'>
							<Lottie animationData={lottieAlerta} loop={true} style={{width: "250px", margin:"auto"}} />
							<div className="alert mb-15">
								<h3>Atenção: esta ação é crítica e irreversível.</h3>
								<p>Apagar o categoria pode acarretar em grandes transtornos. Após excluir o categoria, ele nao pode ser utilizado mais tarde.</p>
							</div>
							
							<button className="btn btn-white" style={{marginRight: '15px'}} onClick={() => this.setState({ modalAlert: false })}>Cancelar</button>
							<button className="btn btn-danger" onClick={(e) => this.deleteItem(e)}>Sim, desejo eliminar este item</button>
						</div>
					</ModalBody>
				</Modal>
			</div>
		)
	}
}

export default ListaCategorias;