import React from 'react'
import { Link } from 'react-router-dom'

export default class TopPage extends React.Component {
    constructor(props) {
        super(props)

        const user = JSON.parse(localStorage.getItem('user')).data
        this.state = {
            user: user,
            brand: user.brand ? user.brand : ""
        }
    }

    render() {
        const url = this.props.model === 'Entregadores' ? 'Motoboys' : this.props.model
        return (
            <>
                <div className='row'>
                    
                    <h1 className="page-header" style={{paddingLeft: "10px"}}>
                        {this.props.lista && this.props.model !== "Voucher" && (
                             this.props.model
                        )}

                        {!this.props.lista &&  this.props.model && this.props.model === "Loja" && (
                            "Configurações gerais"
                        )}

                        {this.props.lista &&  this.props.model && this.props.model === "Voucher" && (
                            "Vouchers de desconto"
                        )}

                        {!this.props.lista && this.props.model && this.props.model !== "Loja" && (
                            this.props.new ? "Editar " + this.props.model : "Cadastrar " + this.props.model 
                        )}

                        {!this.props.lista && !this.props.model && this.props.titulo && (this.props.titulo)}

                        {this.props.model !== 'Entregadores' && this.props.model !== "Clientes" && this.props.model !== "Loja" && this.props.model !== "Pedidos" && this.props.model !== "Mesa" && this.props.lista && (
                            <div style={{padding: "10px", borderRadius: "10px", fontSize: "15px", float: "right"}}>
                                <div className="stats-content">
                                    <div className="stats-title">
                                        <Link to={"/cardapio/edit/"+url }>
                                            <button type="button" className="btn btn-primary me-1 mb-1 float-end">
                                                + Adicionar novo/a {this.props.model}
                                            </button> 
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        )}
                    </h1>
                </div>

                <div className='row mb-15px'>
                    {this.props.children}
                </div>
            </>
        )
    }
}