import React from 'react';
import { Link } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import { trocaloja } from './../../store/api.js'

class SidebarProfile extends React.Component {

	constructor(props) {
		super(props);
		const ls = localStorage.getItem('user')

		this.state = {
			profileActive: 1,
			login:ls ? JSON.parse(localStorage.getItem('user')).data : null,
			modalTroca:false
		};
		this.handleProfileExpand = this.handleProfileExpand.bind(this)
		this.handlerChangeLoja = this.handlerChangeLoja.bind(this)
	}

	handleProfileExpand(e) {
		e.preventDefault();
		this.setState(state => ({
			profileActive: !this.state.profileActive,
		}));
	}

	async handlerChangeLoja(obj){
		await trocaloja({ brand: obj.brand })

		this.setState({
			modalTroca: !this.state.modalTroca,
		})

		window.location.href = "/"
	}

	render() {
		const login = this.state.login
		const img = login && login.loja.imagem && login.loja.imagem.imagem ? login.loja.imagem.imagem : login && login.imagem_perfil ? login.imagem_perfil.imagem : "" 
		return (
			<AppSettings.Consumer>
				{({appSidebarMinify}) => (
					<div>
						{login && (
							<>
								<div className={"menu-profile " + (this.state.profileActive ? "expand " : "")}>
									<Link to="/" onClick={this.handleProfileExpand} className="menu-profile-link">
										<div className="menu-profile-cover with-shadow"></div>
										<div className="menu-profile-image">
											<img alt="" src={"https://grubupload.s3.amazonaws.com/"+img}/>
										</div>
										<div className="menu-profile-info">
											<div className="d-flex align-items-center">
												<div className="flex-grow-1">
													{login.nome_fantasia && (login.nome_fantasia)}
												</div>
											</div>
											{login && login.lojas.length > 1 && (
												<Link to="#" onClick={(e) => { e.preventDefault(); this.setState({modalTroca: !this.state.modalTroca}) }} className="btn btn-default btn-xs me-1 mb-1">Trocar Loja</Link>
											)}
										</div>
									</Link>
								</div>
								
							</>
						)}

						{login && login.lojas.length > 0 && (
							<Modal isOpen={this.state.modalTroca}>
								<ModalBody style={{textAlign:'center', padding: '30px 20px'}}>
									<div style={{fontSize:'20px', marginBottom:'20px'}}><b style={{marginBottom:'15px', display: 'block'}}>Deseja trocar a filial do seu estabelecimento?</b> <p>Escolha uma unidade para gerenciar.</p> </div>
									<table className="table table-hover table-panel text-nowrap align-middle mb-0">
										<tbody>
											{login.lojas.map((item) => ( 
												<tr key={"item_"+item.id}>
													<td>
														{item && item.imagem && (	
															<img alt="" src={"https://grubupload.s3.amazonaws.com/"+item.imagem.imagem} style={{width:'50px', height:"50px", borderRadius:'5px'}}/>
														)}
													</td>
													<td><b style={{textTransform: 'uppercase'}}>{item.nome}</b></td>
													<td>
														<button className="btn btn-primary" style={{textTransform: 'uppercase', float:'right'}} onClick={() => {this.handlerChangeLoja(item)}} >APLICAR</button>
													</td>
												</tr>
											))}
										</tbody>
									</table>
									<div style={{marginTop:'20px'}}>
										<button className="btn btn-primary" style={{textTransform: 'uppercase', float:'right'}} onClick={(e) => {e.preventDefault(); this.setState({modalTroca: !this.state.modalTroca})}}>Cancelar</button>
									</div>
								</ModalBody>
							</Modal>
						)}
					
					</div>
				)}
			</AppSettings.Consumer>
		)
	}
}

export default SidebarProfile;
